/**
 * todo
 * 1. 增加参数控制是否分页，默认分页
 * 2. 样式优化
 */

import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import { TranslateService } from '@ngx-translate/core';
import { GridApi } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { DwModalService } from 'ng-quicksilver';
import { FxConfirmModalComponent } from '../fx-confirm-modal.component';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { a } from '@angular/core/src/render3';
// import { DwRoutingMessageService } from '@webdpt/framework';



/**
 * 设计要求
 * icon 22px
 * title 16px
 * content 14px
 */
@Component({
  selector: 'fx-options-modal-component',
  templateUrl: './fx-options-modal.component.html',
  styleUrls: [
    './fx-options-modal.component.less'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FxOptionsModalComponent implements OnInit {
  @Input() title: string;
  // @Input() dataSet: Array<any>;
  @Input() selected: Array<any>;
  @Input() mutilSelected: boolean;
  @Input() postParam: any;
  @Input() dataInfo: any;
  @Input() isCreateAllBtnShow: boolean;// 是否显示全部产生按钮
  @Input() isDisabledCheckChrExsit: boolean;// 是否开启重复检查
  // ag-grid 栏位配置
  @Input() columnDefs: any;

  public selectedItem: Array<any> = [];
  // public isHeight = '325px';
  public isHeight = 'calc(100vh - 185px)';
  public isParm = true;
  public isFull = false;
  public optionTypeList: Array<any> = [];    // 商品特征类型
  public optionCombineList: Array<any> = []; // 商品特征类型组合
  public isReady = false;
  inBounds = true;
  public selectedChr: any = null;// 选中的组合
  public optionCombineResultList = [];// 存在的组合可能性
  public c_E028: any = '';// 特征连接符
  public c_E099: any = '';// 特征占位符

  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  @ViewChild('layout') layout: ElementRef;



  constructor(private modal: DwModalRef,
    private translateService: TranslateService,
    public dwModalService: DwModalService,
    private fxUserService: FxUserInfoService,
    // public dwMessage: DwRoutingMessageService,
    private http: HttpClient) {
  }

  ngOnInit(): void {
    this.c_E028 = this.fxUserService.getEntParamById('c_E028') ? this.fxUserService.getEntParamById('c_E028') : '';
    this.c_E099 = this.fxUserService.getEntParamById('c_E099') ? this.fxUserService.getEntParamById('c_E099') : '';
    this.getOptionsList();
  }


  // 获取商品特征组
  getOptionsList(): void {   
    this.http.post('C/IFxci003Service/getCacInfo', { 'param': this.postParam }).subscribe(response => {
      this.isReady = true;
      if (response['success']) {
        // 特征值
        if (response['data'] && response['data'].cad_f_list && response['data'].cad_f_list.length) {
          this.optionTypeList = response['data'].cad_f_list;
        } else {
          this.optionTypeList = [];
        }
        this.getUnique();
        // console.log( this.optionTypeList);
        // 特征组合
        if (response['data'] && response['data'].cah_f_list && response['data'].cah_f_list.length) {
          this.optionCombineList = response['data'].cah_f_list;
        }
        // this.getAllCombineList();
      } else {
        this.optionTypeList = [];
        this.optionCombineList = [];
        const modal = this.dwModalService.create({
          dwTitle: null,
          dwStyle: { top: '20px' },
          dwMaskClosable: false, // 點擊遮罩是否允許關閉
          dwContent: FxConfirmModalComponent,
          dwWrapClassName: 'fx_md_modal',
          dwClosable: false,
          dwFooter: [
            {
              label: '取消',
              shape: 'default',
              onClick: (data: any): any => {
                modal.destroy();
              }
            },
            {
              label: '确定',
              type: 'primary',
              onClick: (data: any): any => {
                modal.close({});
              }
            }
          ],
          dwComponentParams: {
            title: "",
            message: response['message'],
            moreInfo: "",
            program_name: "",
            program_no: "",
            isRemind: ""
          }
        });
      }
    });
  }

  // 获取去重的数组
  getUnique() {
    this.optionTypeList.forEach(x => {
      x.cae_f_list.forEach(element => {
        if (x.cae_f_list_unique) {
          const idx = x.cae_f_list_unique.findIndex(y => {
            return y.cae003 === element.cae003;
          });
          if (idx === -1) {
            x.cae_f_list_unique.push(element);
          }
        } else {
          x.cae_f_list_unique = [];
          x.cae_f_list_unique.push(element);
        }
      });
    })
  }
  // 获取所有特征值所对应的所有特征组
  setCombins(originArr, resultArr) {
    originArr.forEach(origin => {
      origin.cae_f_list.forEach(originItem => {
        originItem.result = resultArr.filter(result => {

          var idx = result.type_value_list.findIndex(resultItem => {
            return resultItem.char_type == origin.char_type && resultItem.cae_f_list[0].cae003 === originItem.cae003;
          })
          if (idx !== -1) {
            return true;
          } else {
            return false;
          }
        });
      });
    });
    // console.log(originArr);
  }

  // 更新特征值状态
  setDisabledChr(outIndex, inIndex, originOutIndex): void {
    if (outIndex < (this.optionTypeList.length - 1)) {
      this.optionTypeList[outIndex + 1].cae_f_list_unique.forEach(uniqueItem => {
        uniqueItem.isJudge = false;
        this.optionTypeList[outIndex + 1].cae_f_list.forEach(element => {
          // console.log(outIndex, this.optionTypeList[outIndex + 1].cae_f_list, this.optionTypeList[outIndex + 1].cae_f_list_unique);
          let flag = false;// 判断是否有特征需要禁用
          this.optionTypeList.forEach((y, yIndex) => {
            if (yIndex < (originOutIndex + 1)) {
              const idx = y.cae_f_list_unique.findIndex(z => {
                return z.selected === true;
              });
              if (idx !== -1) {
                if (element.cae004 == y.cae_f_list_unique[idx].cae003 || element.cae004 == element.cae003) {
                  flag = true;
                }
              }
            }
          });
 
          if (uniqueItem.cae003 === element.cae003 && uniqueItem.isJudge == false) {
            if (flag === false) {         
              uniqueItem.disabled = true;
            } else {
              uniqueItem.disabled = false;
              uniqueItem.isJudge = true;
            } 
          }
            
        });
      })
    }
    if ((outIndex + 1) < (this.optionTypeList.length - 1)) {
      this.setDisabledChr(outIndex + 1, inIndex, originOutIndex);
      // this.setDisabledChr(0, inIndex, originOutIndex);
    }
  }


  // 选中商品特征值
  selectOptionAndFixing(outIndex, inIndex) {
    // 判断当前层级的上一个层级是否有选中，按层级选不可跳级选择
    let emptyFlag = false;// 记录是否有上层东西没有选中
    this.optionTypeList.forEach((x, xIndex) => {
      if (xIndex < outIndex) {
        const unDislist = this.optionTypeList[xIndex].cae_f_list_unique.filter(item => {
          return item.disabled !== true;
        });
        const selIndex = unDislist.findIndex(item => {
          return item.selected == true;
        });
        if (unDislist.length && selIndex === -1) {
          emptyFlag = true;
        }
      } else {
        if (outIndex < xIndex) {
          this.optionTypeList[xIndex].cae_f_list_unique.forEach(element => {
            element.selected = false;
          });
        }
      }
    });
    if (emptyFlag) {
      const modal = this.dwModalService.create({
        dwTitle: null,
        dwStyle: { top: '20px' },
        //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
        dwMaskClosable: false, // 點擊遮罩是否允許關閉
        dwContent: FxConfirmModalComponent,
        dwWrapClassName: 'fx_md_modal',
        dwClosable: false,
        dwFooter: [
          {
            label: '确定',
            type: 'primary',
            onClick: (data: any): any => {
              modal.close({});
            }
          }
        ],
        dwComponentParams: {
          title: "",
          message: this.translateService.instant('comm-请先选择上级特征'),
          moreInfo: "",
          program_name: "",
          program_no: "",
          isRemind: ""
        }
      });
      return;
    }
    if (this.optionTypeList[outIndex].cae_f_list_unique[inIndex].disabled == true) {
      return;
    }
    // 判断特征是否有选中的特征值
    this.optionTypeList[outIndex].cae_f_list_unique.forEach(element => {
      element.selected = false;
    });
    this.optionTypeList[outIndex].cae_f_list_unique[inIndex].selected = true;
    this.setDisabledChr(outIndex, inIndex, outIndex);
  }


  // 判断是否所有可选特征都已选中
  checkSelectedChr(): Boolean {
    this.selectedChr = '';
    this.selectedItem[0].character_no = '';
    this.selectedItem[0].character_n1 = '';
    // this.selectedItem = [{character_no: '', character_n1: ''}];
    let count = 0; 1
    this.optionTypeList.forEach((item, index) => {
      let selList = [], disList = [];
      item.cae_f_list_unique.forEach((element) => {
        if (element.selected === true) {
          selList.push(element);
        } else {
          if (element.disabled) {
            disList.push(element);
          }
        }
      });
      if (selList.length) {
        if (index < (this.optionTypeList.length - 1)) {
          this.selectedChr += selList[0].cae003 + this.c_E028;
          this.selectedItem[0].character_no += selList[0].cae003 + this.c_E028;
          this.selectedItem[0].character_n1 += selList[0].cae003_n1 + this.c_E028;
        } else {
          this.selectedChr += selList[0].cae003;
          this.selectedItem[0].character_no += selList[0].cae003;
          this.selectedItem[0].character_n1 += selList[0].cae003_n1;
        }
      }
      if (disList.length === item.cae_f_list_unique.length) {
        if (index < (this.optionTypeList.length - 1)) {
          this.selectedChr += this.c_E099 + this.c_E028;
          this.selectedItem[0].character_no += this.c_E099 + this.c_E028;
          this.selectedItem[0].character_n1 += this.c_E099 + this.c_E028;
        } else {
          this.selectedChr += this.c_E099;
          this.selectedItem[0].character_no += this.c_E099;
          this.selectedItem[0].character_n1 += this.c_E099;
        }
      }
      if (selList.length || (disList.length === item.cae_f_list_unique.length)) {
        count += 1;
      }
    });
    if (count === this.optionTypeList.length) {
      return true;
    } else {
      return false;
    }
  };

  // 产生全部的组合
  getAllCombineList() {
    let resultCombineList = [], preCombineList = [], currCombineList = [], preCombine = null;
    for (var i = 0; i <= this.optionTypeList.length - 1; i++) {
      preCombineList = JSON.parse(JSON.stringify(resultCombineList));
      resultCombineList = [];
      this.optionTypeList[i].cae_f_list_unique.forEach((pre, preIdx) => {
        currCombineList = JSON.parse(JSON.stringify(preCombineList));
        if (currCombineList.length) {
          currCombineList.forEach(y => {
            y.push(pre.cae003);
          })
        } else {
          currCombineList.push([pre.cae003]);
        }
        resultCombineList.push(...currCombineList);
      });
    }
    // console.log(resultCombineList);
    for (var i = 0; i <= resultCombineList.length - 1; i++) {
      for (var j = 0; j <= resultCombineList[i].length - 1; j++) {

        let cae004Arr = [];
        this.optionTypeList[j].cae_f_list.forEach(element => {
          if (element.cae004 !== element.cae003) {
            cae004Arr.push(element.cae004);
          }
        });

        let splitArr = [];
        if (j > 0) {
          resultCombineList[i].forEach((element, index) => {
            if (index <= (j - 1) && element !== this.c_E099) {
              splitArr.push(element);
            }
          });
        }
        // 获取当前特征值的上级特征值的组合和当前组合之前的特征值的组合交集
        let intersection = cae004Arr.filter(val => { return (val !== this.c_E099 && splitArr.indexOf(val) > -1) });
        if (cae004Arr.length && splitArr.length && intersection.length == 0) {
          resultCombineList[i][j] = this.c_E099;
        }
      }
    }
    // console.log(resultCombineList);
    this.optionCombineResultList = [];
    resultCombineList.forEach(x => {
      this.optionCombineResultList.push(x.join(this.c_E028));
    });
    // console.log(this.optionCombineResultList);
    // 数组去重
    var temp = {}, len = this.optionCombineResultList.length;
    for (var i = 0; i < len; i++) {
      var tmp = this.optionCombineResultList[i];
      if (!temp.hasOwnProperty(tmp)) {
        temp[this.optionCombineResultList[i]] = "null";
      }
    }

    len = 0;
    var tempArr = [];
    for (var key in temp) {
      tempArr[len++] = key;
    }
    this.optionCombineResultList = tempArr;
    // console.log(this.optionCombineResultList);
  }

  onClickOK(type?: any): void {
    this.getAllCombineList();
    this.selectedItem[0] = {
      optionTypeList: this.optionTypeList,
      optionCombineList: this.optionCombineResultList,
    };
    if (type === 'all') { 
      this.selectedItem[0].isCreateAllBtnClick = true;
      this.modal.triggerOk();
      return;
    }
    if (this.checkSelectedChr() === false && this.isReady) {
      this.selectedItem = [];
      const modal = this.dwModalService.create({
        dwTitle: null,
        dwStyle: { top: '20px' },
        //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
        dwMaskClosable: false, // 點擊遮罩是否允許關閉
        dwContent: FxConfirmModalComponent,
        dwWrapClassName: 'fx_md_modal',
        dwClosable: false,
        dwFooter: [
          {
            label: '取消',
            shape: 'default',
            onClick: (data: any): any => {
              modal.destroy();
            }
          },
          {
            label: '确定',
            type: 'primary',
            onClick: (data: any): any => {
              modal.close({});
            }
          }
        ],
        // dwGetContainer: () => {
        //   let a = document.createElement('div');
        //   a.className = 'aaa';
        //   return a;
        // },
        dwComponentParams: {
          title: "",
          message: this.translateService.instant('no-feature-data'),
          moreInfo: "",
          program_name: "",
          program_no: "",
          isRemind: ""
        }
      });
    } else {
      const idx = this.optionCombineResultList.findIndex(x => {
        return x === this.selectedItem[0].character_no;
      });
      if (idx === -1 && this.isDisabledCheckChrExsit === false) {
        const modal = this.dwModalService.create({
          dwTitle: null,
          dwStyle: { top: '20px' },
          //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
          dwMaskClosable: false, // 點擊遮罩是否允許關閉
          dwContent: FxConfirmModalComponent,
          dwWrapClassName: 'fx_md_modal',
          dwClosable: false,
          dwFooter: [
            {
              label: '取消',
              shape: 'default',
              onClick: (data: any): any => {
                modal.destroy();
              }
            },
            {
              label: '确定',
              type: 'primary',
              onClick: (data: any): any => {
                modal.close({});
              }
            }
          ],
          // dwGetContainer: () => {
          //   let a = document.createElement('div');
          //   a.className = 'aaa';
          //   return a;
          // },
          dwComponentParams: {
            title: "",
            message: this.translateService.instant('com-no-exsit'),
            moreInfo: "",
            program_name: "",
            program_no: "",
            isRemind: ""
          }
        });
      } else {
        this.modal.triggerOk();
      }
    }

  }

  onClickCancel(): void {
    this.modal.triggerCancel();
  }
  fxCloseModal(): void {
    this.modal.triggerCancel();
  }

  // 最大化/还原
  fullModal(parm): void {
    //获取浏览器可见宽高
    let docWidth = window.outerWidth;
    let docHeight = window.outerHeight;
    let newModal = document.querySelector('.fx_modal_container');

    if (parm) {
      //存储第一次dwWidth值
      let oldW = this.modal['dwWidth'];
      // let oldH = this.modal;
      localStorage.setItem('width', oldW);
      let oldWidth = localStorage.getItem('width');

      //赋予新值
      this.layout.nativeElement.style.width = docWidth + 'px';
      this.isHeight = 'calc(100vh - 85px)';
      this.isFull = true;
      this.modal['dwStyle'].top = '0';
      //改写click传参值
      this.isParm = false;
    } else {
      this.layout.nativeElement.style.width = localStorage.getItem('width') + 'px';
      // this.isHeight = '325px';
      this.isHeight = 'calc(100vh - 185px)';
      this.isFull = false;
      this.modal['dwStyle'].top = '20px';

      //改写click传参值
      this.isParm = true;
    }
  }

  checkEdge(event) {
    this.edge = event;
    // console.log('edge:', event);
  }

}

