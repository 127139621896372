import { Injectable } from '@angular/core';
import { Model, ModelFactory } from '@angular-extensions/model';
import { Observable } from 'rxjs';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';
import { FxGridRowEditService } from 'app/implementation/shared/fx-grid/fx-grid-row-edit.service';
import { calDate } from 'app/implementation/shared/services/common-method';

//-- ====详情页数据结构 --begin-- ====
export class MasterObj {
  dcgent: number = 99;//	企业编号
  searchvalue: string = '';//	申请人员
  searchvalue_n1: string = '';//		申请人
  dcgmper: string = '';//		修改人员
  dcgcdate: Date = new Date();//	制定日期
  dcgcorg: string = '';//		归属组织
  dcgcorg_n1: string = '';//		归属组织
  dcgcdept: string = '';//		申请部门
  dcgcdept_n1: string = '';//		申请部门
  dcglock: number = 1;//	乐观锁
  dcgstus: string = 'N';//	状态码
  dcgdocno: string = '';//	活动编号
  dcgedate: any = calDate('day', 5, new Date());//	截止日期
  dcgbdate: any = calDate('day', 5, new Date());//	开始日期
  dcgnote: string = '';//	备注
  dcgdocno_n1: string = '';//	活动名称
  dcgver: number = 1;//	版本
  dcg001: number = 1;//	优先级
  dcg002: string = '';//	活动计划
  dcg002_n1: string = '';//	活动计划
  dcg003: string = '';//	活动等级
  dcg003_n1: string = '';//	活动等级
  dcg004: string = '';//	活动描述
  dcg008: string = '';//	目标销售维度
  dcg010: string = '';//	费用预测维度
  dcgtype: string = '';//	活动类型
  dcg005: string = '';//	活动场次
  dcg006: string = '';//	活动地点
  isexistedgac: string = 'N';// 是否存在费用单
  dch_f_list: Array<DetailDchmaster> = [];           // mastergrid单身
  dci_f_list: Array<DetailDcimaster> = [];           // mastergrid单身
  dcj_f_list: Array<DetailDcjmaster> = [];           // mastergrid单身
  constructor(values: any) {
    Object.assign(this, values);
  }
}

export class DetailDchmaster {
  dchent: number = 99;//	企业编号
  dchlock: number = 1;//	乐观锁
  dchstus: string = 'T';//	有效否
  dchdocno: string = '';//	活动编号
  dchseq: number = 1;//	项次
  dchtype: string = '';//	范围类型
  dchcust: string = '';//	范围
  dchcust_n1: string = '';//	名称
  dchver: number = 1;// 版本
  constructor(values?: any) {
    Object.assign(this, values);
  }
}
export class DetailDcimaster {
  dcient: number = 99;//	企业编号
  dcilock: number = 1;//	乐观锁
  dcistus: string = 'T';//	有效否
  dciseq: number = 1;//	项次
  dcilorg: string = '';//	归属组织(包含销售/采购等等)
  dcilorg_n1: string = '';//	组织
  dcicust: string = '';//	范围
  dcicust_n1: string = '';//	名称
  dciclass: string = '';//	品类
  dciclass_n1: string = '';//	品类
  dcigoods: string = '';//	商品
  dcigoods_n1: string = '';//	商品
  dciamt: any = null;//	目标金额
  dcidocno: string = '';//	活动编号
  dcibra: string = '';//	品牌
  dcibra_n1: string = '';//	品牌
  dciver: number = 1;//	版本
  dcitamt: any = null;//		实际金额
  constructor(values?: any) {
    Object.assign(this, values);
  }
}
export class DetailDcjmaster {
  dcjent: number = 99;//	企业编号
  dcjlock: number = 1;//	乐观锁
  dcjstus: string = 'T';//	有效否
  dcjseq: number = 1;//	项次
  dcjdocno: string = '';//	活动编号
  dcjlorg: string = '';//	归属组织(包含销售/采购等等)
  dcjlorg_n1: string = '';//	组织
  dcjcust: string = '';//	范围
  dcjcust_n1: string = '';//	名称
  dcjstore: string = '';//	门店
  dcjstore_n1: string = '';//	门店
  dcjtype: string = '';//	费用分类
  dcjtype_n1: string = '';//	费用分类名称
  dcjamt: any = null;//	预测费用金额
  dcjbra: string = '';//	品牌
  dcjbra_n1: string = '';//	品牌
  dcj001: string = '1';//	费用计划方式
  dcjver: number = 1;//	版本
  dcjtamt: any = null;//	实际费用金额
  constructor(values?: any) {
    Object.assign(this, values);
  }
}

//-- ====详情页数据结构 --end-- ====

export interface Fxdd012MasterModel {
  id: number;
  dcgent: number;//	企业编号
  searchvalue: string;//	申请人员
  searchvalue_n1: string;//		申请人
  dcgmper: string;//		修改人员
  dcgcdate: Date;//	制定日期
  dcgcorg: string;//		归属组织
  dcgcorg_n1: string;
  dcgcdept: string;//		申请部门
  dcgcdept_n1: string;//		申请部门
  dcglock: number;//	乐观锁
  dcgstus: string;//	状态码
  dcgdocno: string;//	活动编号
  dcgedate: Date;//	截止日期
  dcgbdate: Date;//	开始日期
  dcgnote: string;//	备注
  dcgdocno_n1: string;//	活动名称
  dcgver: number;//	版本
  dcg001: number;//	优先级
  dcg002: string;//	活动计划
  dcg002_n1: string;
  dcg003: string;//	活动等级
  dcg003_n1: string;
  dcg004: string;//	活动描述
  dcg008: string;//	目标销售维度
  dcg010: string;//	费用预测维度
  dcgtype: string;//	活动类型
  dcg005: string;//	活动场次
  dcg006: string;//	活动地点
  isexistedgac: string;// 是否存在费用单
}
let ID = 0;  // id 值
const initialData: Fxdd012MasterModel[] = []; // 初始化表格为空

@Injectable()
export class Fxdd012MasterService extends FxGridRowEditService { //FxGridRowEditService 
  private model: Model<Fxdd012MasterModel[]>;
  masterDataSet$: Observable<Fxdd012MasterModel[]>;
  constructor(private fb: FormBuilder, private modelFactory: ModelFactory<Fxdd012MasterModel[]>,
    public http: FxDapHttpClient, private translateService: TranslateService,) {
    super(http);
    this.model = this.modelFactory.create(initialData);
    this.masterDataSet$ = this.model.data$;
  }
  public getDchtypeptions(): Observable<any> {
    return Observable.create(observer => {
      this.http.post<any>('A/IACommonService/pub_get_aaa', { param: { 'is_enum': 'Y', 'aaa001': '307' } }).subscribe(response => {
        if (response.success && response.data.length > 0) {
          const resultdata = response.data.filter(x => {
            return x.aaa004 == 'Y';
          }).map(item => {
            return {
              label: item.aaa002_n1,
              value: item.aaa002
            };
          });
          observer.next(resultdata);
        } else {
          observer.next([]);
        }
        observer.complete();
      }, error => {
        observer.next([]);
        observer.complete();
      });
    });
  }
  //  == grid优化 ==  begin ==
  // 建立group 
  initDchRowFromGroup(data: any): any {
    const formGroup = this.fb.group({});
    Object.keys(data).forEach(key => {
      //字段同步验证器
      if (['dchtype', 'dchcust'].find(element => element === key)) {
        formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
      } else {
        formGroup.setControl(key, new FormControl(data[key]));
      }

    });
    formGroup.updateValueAndValidity();
    return formGroup;
  }

  //赋值对象初始值，并建立group
  public createDchRowFromGroup(customRowData: any): FormGroup {
    // 设置新增的默认值
    let _rowData = {
      id: this.getNextId()
    };
    _rowData = Object.assign(new DetailDchmaster(), _rowData);
    if (customRowData) {
      _rowData = Object.assign(_rowData, customRowData);
    }
    return this.initDchRowFromGroup(_rowData);
  }

  public createDchFormArray(datas: any): FormArray {
    const formArray: FormArray = this.fb.array(datas.map((item, index) => this.createDchRowFromGroup(Object.assign({...item}, {id: index}))));
    this.maxId = formArray.length;
    this.initIndex(formArray.value);
    return formArray;
  }
  // ==  grid优化  ==  end  ==
  // 从后端查询到数据，调用此initMaster给MasterGrid赋值
  initMaster(datas: any): void {
    ID = 0;  // 翻页或从后端查重询之后ID从0开始
    if (Array.isArray(datas)) {
      let masterDataSet = this.model.get();
      masterDataSet = [];
      if (datas !== undefined || datas !== null) {  // 参数检查
        datas.forEach((data, idx, array) => {
          ID++;         // 自动设定ID值
          data.id = ID;
          masterDataSet.push(data);
        });
      }
      this.model.set(masterDataSet);
    }
  }

  // 页面刷新多笔记录状态
  auditRe(datas: Fxdd012MasterModel[]): void {
    const masterDataSet = this.model.get();
    masterDataSet.forEach((item, index) => {
      if (datas) {
        // 更新Model并刷新Grid
        const data = datas.find(master => master.dcgdocno === item.dcgdocno && master.dcgver === item.dcgver);
        if (data !== undefined) {
          data.id = item.id;
          masterDataSet.splice(index, 1, data);  // 替换对应的行
        }
      }
    });
    this.model.set(masterDataSet);
  }

  // 删除
  delete(selectedMap: any, datas: Fxdd012MasterModel[]): void {
    let masterDataSet = this.model.get();
    masterDataSet = masterDataSet.filter(item => {
      if (selectedMap[item.id] !== undefined && datas !== null) {
        const data = datas.find(master => master.dcgdocno === item.dcgdocno && master.dcgver === item.dcgver);
        if (data === undefined) {
          return true;  // 元素通过测试,保留该元素
        } else {
          return false;  // 不保留
        }
      } else {
        return true; // 元素通过测试,保留所有元素
      }
    });
    this.model.set(masterDataSet);
  }
  //-- ====列表grid数据 页面刷新函数 --end-- =====

  //-- ====后端交互服务方法 --begin-- ====
  // 1-列表查询
  public getList(searchParam: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/getList', { queryInfo: searchParam });
  }

  // 5-删除【批量】
  public deleteData(data: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/deleteData', { 'param': data });
  }
  // 变更
  public changeData(data: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/changeData', { 'param': data });
  }
  // 6-审核【批量】
  public auditData(data: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/auditData', { 'param': data });
  }
  // 7-取消审核【批量】
  public unauditData(data: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/disAuditData', { 'param': data });
  }
  // 6-提交【批量】
  public submitData(data: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/submitData', { 'param': data });
  }
  // 7-取消提交【批量】
  public unsubmitData(data: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/disSubmitData', { 'param': data });
  }
  // 7-费用核销
  public verificationData(data: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/verificationData', { 'param': data });
  }

  // 8-单身之前关联查询
  public getDetailInfo(detailParam: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/getDetailInfo', { 'param': detailParam });
  }

  // 9-新增保存
  public addData(Param: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/addData', { 'param': Param });
  }

  // 10-修改保存
  public updateData(Param: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/updateData', { 'param': Param });
  }
  // 变更承办人
  public sqrChangeData(data: any): Observable<any> {
    return this.http.post('D/IFxdd012Service/sqrChangeData', { 'param': data });
  }

  //-- ====后端交互服务方法 --end-- ====
}

@Injectable()
export class Fxdd012DciService extends FxGridRowEditService { //FxGridRowEditService 
  constructor(private fb: FormBuilder,
    public http: FxDapHttpClient) {
    super(http);
  }

  // 建立group 
  initDciRowFromGroup(data: any): any {
    const formGroup = this.fb.group({});
    Object.keys(data).forEach(key => {
      //字段同步验证器
      const dcg008 = this.componentParent.myform.get('dcg008').value;
      if (['dcilorg', 'dcicust', 'dciamt'].find(element => element === key)) {
        formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
      } else if (['dcibra'].find(element => element === key)) {
        if (dcg008 == '5') {
          formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
        } else {
          formGroup.setControl(key, new FormControl(data[key]));
        }
      } else if (['dciclass'].find(element => element === key)) {
        if (dcg008 == '3') {
          formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
        } else {
          formGroup.setControl(key, new FormControl(data[key]));
        }
      } else if (['dcigoods'].find(element => element === key)) {
        if (dcg008 == '4') {
          formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
        } else {
          formGroup.setControl(key, new FormControl(data[key]));
        }
      } else {
        formGroup.setControl(key, new FormControl(data[key]));
      }

    });
    formGroup.updateValueAndValidity();
    return formGroup;
  }

  //赋值对象初始值，并建立group
  public createDciRowFromGroup(customRowData: any): FormGroup {
    // 设置新增的默认值
    let _rowData = {
      id: this.getNextId()
    };
    _rowData = Object.assign(new DetailDcimaster(), _rowData);
    if (customRowData) {
      _rowData = Object.assign(_rowData, customRowData);
    }
    return this.initDciRowFromGroup(_rowData);
  }

  public createDciFormArray(datas: any): FormArray {
    const formArray: FormArray = this.fb.array(datas.map((item, index) => this.createDciRowFromGroup(Object.assign({...item}, {id: index}))));
    this.maxId = formArray.length;
    this.initIndex(formArray.value);
    return formArray;
  }

}

@Injectable()
export class Fxdd012DcjService extends FxGridRowEditService { //FxGridRowEditService 
  constructor(private fb: FormBuilder,
    public http: FxDapHttpClient) {
    super(http);
  }

  // 建立group 
  initDcjRowFromGroup(data: any): any {
    const formGroup = this.fb.group({});
    Object.keys(data).forEach(key => {
      //字段同步验证器
      const dcg010 = this.componentParent.myform.get('dcg010').value;
      if (['dcjlorg', 'dcjcust', 'dcjtype', 'dcj001', 'dcjamt'].find(element => element === key)) {
        formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
      } else if (['dcjbra'].find(element => element === key)) {
        if (dcg010 == '4' || dcg010 == '5' || dcg010 == '6') {
          formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
        } else {
          formGroup.setControl(key, new FormControl(data[key]));
        }
      } else if (['dcjstore'].find(element => element === key)) {
        if (dcg010 == '3' || dcg010 == '6') {
          formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
        } else {
          formGroup.setControl(key, new FormControl(data[key]));
        }
      } else {
        formGroup.setControl(key, new FormControl(data[key]));
      }

    });
    formGroup.updateValueAndValidity();
    return formGroup;
  }

  //赋值对象初始值，并建立group
  public createDcjRowFromGroup(customRowData: any): FormGroup {
    // 设置新增的默认值
    let _rowData = {
      id: this.getNextId()
    };
    _rowData = Object.assign(new DetailDcjmaster(), _rowData);
    if (customRowData) {
      _rowData = Object.assign(_rowData, customRowData);
    }
    return this.initDcjRowFromGroup(_rowData);
  }

  public createDcjFormArray(datas: any): FormArray {
    const formArray: FormArray = this.fb.array(datas.map((item, index) => this.createDcjRowFromGroup(Object.assign({...item}, {id: index}))));
    this.maxId = formArray.length;
    this.initIndex(formArray.value);
    return formArray;
  }

}

