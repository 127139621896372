import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
// import { DwCellButtonEditorComponent } from '@webdpt/framework';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgQuicksilverModule } from 'ng-quicksilver';
// import { ButtonEditorFilterComponent } from '@webdpt/framework';
import { DwAgGridDateInputComponent } from '@webdpt/framework';
import { FloatingButtonEditorFilterComponent } from '@webdpt/framework';
// import { DwAgGridDateEditorComponent } from '@webdpt/framework';
import * as moment from 'moment';
// import { DwAgTextCellEditorComponent } from '@webdpt/framework';
// import { DwAgTextColumnFilterComponent } from '@webdpt/framework';
// import { DwAgTextColumnFloatingFilterComponent } from '@webdpt/framework';
// import { DwAgNumberCellEditorComponent } from '@webdpt/framework';
// import { DwAgSelectCellEditorComponent } from '@webdpt/framework';
// import { DwAgLargeTextCellEditorComponent } from '@webdpt/framework';
import { TranslateModule } from '@ngx-translate/core';
// import { DwAgGridValidationEditorComponent } from '@webdpt/framework';
import { FxAgCheckboxEditorComponent } from './ag-grid/fx-ag-checkbox-editor.component';
import { FxAgNumberCellEditorComponent } from './ag-grid/fx-number-cell-editor.component';
import { FxAgGridDateEditorComponent } from './ag-grid/fx-ag-grid-date-editor.component'; // Added David Lee 客制Date CellEditor 21Feb2019
import { FxAgSelectCellEditorComponent } from './ag-grid/fx-ag-select-cell-editor.component'; //  David Lee 客制 Select CellEditor 21Feb2019
import { FxAgGridDateInputComponent } from './ag-grid/fx-ag-grid-date-input.component';
import { FxAgGridValidationEditorComponent } from './ag-grid/fx-ag-grid-validation-editor';
import { FxAgLargeTextCellEditorComponent } from './ag-grid/fx-ag-large-text-cell-editor.component';
import { FxAgTextCellEditorComponent } from './ag-grid/fx-ag-text-cell-editor.component';
import { FxAgTextColumnFilterComponent } from './ag-grid/fx-ag-text-column-filter.component';
import { FxAgTextColumnFloatingFilterComponent } from './ag-grid/fx-ag-text-column-floating-filter.component';
import { FxButtonEditorFilterComponent } from './ag-grid/fx-button-editor-filter.component';
import { FxCellButtonEditorComponent } from './ag-grid/fx-cell-button-editor.component';
import { FxFloatingButtonEditorFilterComponent } from './ag-grid/fx-floating-button-editor-filter.component';
import { FxAgCheckboxRendererComponent } from './ag-grid/fx-ag-checkbox-renderer.component';
import { FxAgFormCellRendererComponent } from './ag-grid/fx-ag-formcell-renderer.component';
import {FxGridOperationButtonRendererComponent} from './ag-grid/fx-grid-operation-button-renderer/fx-grid-operation-button-renderer.component';
import {FxGridOperationButtonEditorComponent} from './ag-grid/fx-grid-operation-button-editor/fx-grid-operation-button-editor.component';
import {FxAgGridSelectCellRendererComponent} from './ag-grid/fx-ag-grid-select-cell-renderer/fx-ag-grid-select-cell-renderer.component';
import {FxAgGridStatusCellRendererComponent} from './ag-grid/fx-ag-grid-status-cell-renderer/fx-ag-grid-status-cell-renderer.component';
import {FxAgNumberFormatCellEditorComponent} from './ag-grid/fx-ag-number-format-editor.component';
import {FxAgNumberFormatCellRendererComponent} from './ag-grid/fx-ag-number-format-renderer.component';
import {PipesModule} from './components/pipe/pipes.module';
import {MatAutocompleteModule} from '@angular/material';
import { FxNoteCellRendererComponent } from './ag-grid/fx-note-cell-renderer - copy/fx-note-cell-renderer.component';
import { FxAgColorRendererComponent } from './ag-grid/fx-ag-color-renderer.component';
import { FxAgColorEditorComponent } from './ag-grid/fx-ag-color-editor.component';
import { FxAgGridSelectCellRendererNewComponent } from 'app/implementation/shared/ag-grid/fx-ag-grid-select-cell-renderer-new/fx-ag-grid-select-cell-renderer.component';
import { FxAgGridStatusCellRendererNewComponent } from 'app/implementation/shared/ag-grid/fx-ag-grid-status-cell-renderer-new/fx-ag-grid-status-cell-renderer.component';
import { FxAgCheckboxRendererNewComponent } from 'app/implementation/shared/ag-grid/fx-ag-checkbox-renderer-new.component';
import { FxAgColorRendererNewComponent } from 'app/implementation/shared/ag-grid/fx-ag-color-renderer-new.component';
import { FxAgNumberFormatCellRendererNewComponent } from 'app/implementation/shared/ag-grid/fx-ag-number-format-renderer-new.component';
import { FxAgSelectCellEditorMultiComponent } from './ag-grid/fx-ag-select-cell-editor-multi.component';
import { FxAgGridSelectCellRendererMultiComponent } from './ag-grid/fx-ag-grid-select-cell-renderer-multi/fx-ag-grid-select-cell-renderer-multi.component';
import {FxAgSwitchRendererComponent} from './ag-grid/fx-ag-switch-renderer.component';
import { FxAgNumberBothsidesChangeCellRendererComponent } from './ag-grid/fx-number-bothsides-change-cell-renderer.component';
import { FxAgImageMessageCellRendererComponent } from './ag-grid/fx-image-message-cell-renderer.component';

const dateFilterCompare = (filterLocalDateAtMidnight, cellValue): any => {
  cellValue = moment(cellValue, 'DD/MM/YYYY').toDate();
  if (filterLocalDateAtMidnight.getFullYear() === cellValue.getFullYear() &&
    filterLocalDateAtMidnight.getDate() === cellValue.getDate() &&
    filterLocalDateAtMidnight.getMonth() === cellValue.getMonth()) {
    return 0;
  }
  if (cellValue.getTime() < filterLocalDateAtMidnight.getTime()) {
    return -1;
  }
  if (cellValue.getTime() > filterLocalDateAtMidnight.getTime()) {
    return 1;
  }
  return false;
};

export const FX_AG_GRID_COMPONENTS = [
  //DwCellButtonEditorComponent,
  FxCellButtonEditorComponent,
  //ButtonEditorFilterComponent,
  FxButtonEditorFilterComponent,
  //DwAgGridDateInputComponent,
  FxAgGridDateEditorComponent,
  // FloatingButtonEditorFilterComponent,
  FxFloatingButtonEditorFilterComponent,
  //DwAgGridDateEditorComponent,
  FxAgGridDateEditorComponent,
  //DwAgTextCellEditorComponent,
  FxAgTextCellEditorComponent,
  //DwAgTextColumnFilterComponent,
  FxAgTextColumnFilterComponent,
  //DwAgTextColumnFloatingFilterComponent,
  FxAgTextColumnFloatingFilterComponent,
  //DwAgNumberCellEditorComponent,
  FxAgNumberCellEditorComponent,
  //DwAgSelectCellEditorComponent,
  FxAgSelectCellEditorComponent,
  FxAgSelectCellEditorMultiComponent,
  //DwAgLargeTextCellEditorComponent,
  FxAgLargeTextCellEditorComponent,
  //DwAgGridValidationEditorComponent,
  FxAgGridValidationEditorComponent,
  FxAgGridDateInputComponent,
  FxAgCheckboxEditorComponent,
  FxAgCheckboxRendererComponent,
  FxAgSwitchRendererComponent,
  FxAgCheckboxRendererNewComponent,
  FxAgFormCellRendererComponent,
  FxAgGridStatusCellRendererComponent,
  FxAgGridStatusCellRendererNewComponent,
  FxAgNumberFormatCellEditorComponent,
  FxAgNumberFormatCellRendererComponent,
  FxAgNumberFormatCellRendererNewComponent,
  FxGridOperationButtonRendererComponent,
  FxAgGridSelectCellRendererComponent,
  FxAgGridSelectCellRendererMultiComponent,
  FxAgGridSelectCellRendererNewComponent,
  FxNoteCellRendererComponent,
  FxAgColorRendererComponent,
  FxAgColorRendererNewComponent,
  FxAgNumberBothsidesChangeCellRendererComponent,
  FxAgImageMessageCellRendererComponent,
  FxAgColorEditorComponent
];

export const FX_AG_GRID_FW_COMPONENTS: any =  {
  floatingCellButtonEditor: FloatingButtonEditorFilterComponent,
  fxFloatingCellButtonEditor: FxFloatingButtonEditorFilterComponent,
  //buttonEditorFilter: ButtonEditorFilterComponent,
  fxButtonEditorFilter: FxButtonEditorFilterComponent,
  agDateInput: DwAgGridDateInputComponent,
  fxAgDateInput: FxAgGridDateInputComponent,
  //dateEditor: DwAgGridDateEditorComponent,
  fxDateEditor: FxAgGridDateEditorComponent,
  dateFilterComparator: dateFilterCompare,
  //cellButtonEditor: DwCellButtonEditorComponent,
  fxCellButtonEditor: FxCellButtonEditorComponent,

  // 平台將預設的editor換成ng-zorro,Fx开头的组件客制Edit模式下的事件
  //agTextCellEditor: DwAgTextCellEditorComponent,
  fxAgTextCellEditor: FxAgTextCellEditorComponent,
  //agNumberCellEditor: DwAgNumberCellEditorComponent,
  fxAgNumberCellEditor: FxAgNumberCellEditorComponent,
  //agSelectCellEditor: DwAgSelectCellEditorComponent,
  fxAgSelectCellEditor: FxAgSelectCellEditorComponent,
  fxAgSelectCellEditorMulti: FxAgSelectCellEditorMultiComponent,
  //agLargeTextCellEditor: DwAgLargeTextCellEditorComponent,
  fxAgLargeTextCellEditor: FxAgLargeTextCellEditorComponent,
  //agDateCellEditor: DwAgGridDateEditorComponent,
  fxAgDateCellEditor: FxAgGridDateEditorComponent,
  fxAgNumberFormatEditor: FxAgNumberFormatCellEditorComponent,
  // agTextColumnFilter: DwAgTextColumnFilterComponent,
  // agTextColumnFloatingFilter: DwAgTextColumnFloatingFilterComponent
  fxAgCheckBoxCellEditor: FxAgCheckboxEditorComponent,
  fxCheckboxCellRenderer: FxAgCheckboxRendererComponent,
  fxSwitchCellRenderer: FxAgSwitchRendererComponent,
  fxNumberBothsidesChangeCellRenderer: FxAgNumberBothsidesChangeCellRendererComponent,
  fxCheckboxCellRendererNew: FxAgCheckboxRendererNewComponent,
  fxFormCellRenderer: FxAgFormCellRendererComponent,
  fxGridOperationRenderer: FxGridOperationButtonRendererComponent,
  fxGridOperationEditor: FxGridOperationButtonEditorComponent,
  fxGridSelectRenderer: FxAgGridSelectCellRendererComponent,
  fxGridSelectRendererMulti: FxAgGridSelectCellRendererMultiComponent,
  fxGridSelectRendererNew: FxAgGridSelectCellRendererNewComponent,
  fxGridStatusRenderer: FxAgGridStatusCellRendererComponent,
  fxGridStatusRendererNew: FxAgGridStatusCellRendererNewComponent,
  fxAgNumberFormatRenderer: FxAgNumberFormatCellRendererComponent,
  fxAgNumberFormatRendererNew: FxAgNumberFormatCellRendererNewComponent,
  fxAgColorRenderer: FxAgColorRendererComponent,
  fxAgColorRendererNew: FxAgColorRendererNewComponent,
  fxAgColorEditor: FxAgColorEditorComponent

};

export const enum FX_AG_GRID_FW_COMPONENT {
  CellButtonEditor = 'FxCellButtonEditor',
  ButtonEditorFilter = 'FxButtonEditorFilter',
  FloatingButtonEditor = 'FxFloatingCellButtonEditor'
}

export const enum AG_GRID_FW_COMPONENT {
  CellButtonEditor = 'cellButtonEditor',
  ButtonEditorFilter = 'buttonEditorFilter',
  FloatingButtonEditor = 'floatingCellButtonEditor'
}


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgQuicksilverModule,
    TranslateModule,
    PipesModule,
    AgGridModule.withComponents([
      ...FX_AG_GRID_COMPONENTS
    ]),
    MatAutocompleteModule
  ],
  declarations: [
    ...FX_AG_GRID_COMPONENTS
  ],
  exports: [
    AgGridModule,
    MatAutocompleteModule,
    PipesModule
  ],
  entryComponents: [
    FxAgGridSelectCellRendererComponent,
    FxAgGridSelectCellRendererMultiComponent,
    FxAgGridSelectCellRendererNewComponent,
    FxAgGridStatusCellRendererComponent,
    FxAgGridStatusCellRendererNewComponent
  ]

})
export class FxAgGridEditorsModule {
  static forAgGridComponents(): ModuleWithProviders {
    return {
      ngModule: FxAgGridEditorsModule,
      providers: [
        ...AgGridModule.withComponents([
          ...FX_AG_GRID_COMPONENTS
        ]).providers
      ]
    };
  }
}
