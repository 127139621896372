import { DwNewRouteKeyService, DwRoutingMessageService } from '@webdpt/framework';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DwModalService } from 'ng-quicksilver';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-fx-mper-cell-renderer',
  templateUrl: './fx-mper-cell-renderer.component.html',
})
export class FxMperCellRendererComponent implements ICellRendererAngularComp {

  @Input() params: any;
  public infoOption: any;
  public mper: any;
  public keyObj = {};
  infoList = [];
  public visible;
  public visible1 =true;
  public morelog_datas: any;
  constructor(
    public router: DwNewRouteKeyService,
    public dwMessage: DwRoutingMessageService,
    public http: FxDapHttpClient,
    public fxModalService: FxModalService,
    private translateService: TranslateService,
    public dwModalService: DwModalService
  ) {
  }

  // refresh(params: any): boolean {
  //   throw new Error("Method not implemented.");
  // }   //原fresh
  
  refresh(params: any): any {
    // throw new Error("Method not implemented.");
  }//by dinghao 0704
  agInit(params: any): any {
    this.params = params;
    this.mper = [this.params.data[this.params.field[0]], this.params.data[this.params.field[1]]];
    this.params.key.forEach((item) => {
      Object.assign(this.keyObj, { [item]: this.params.data[item] });
    });
  }

  public dataInit(): void {
    
    this.infoOption = {
      tableName: this.params.tableName,                // 类型：String  必有字段  备注：表编号
      keyValue: this.keyObj,
      ace001: this.params.ace001,
      acedocno: this.params.acedocno
    };
    // console.log('参数不全', this._infoOption);
    if (!this.infoOption.tableName || !this.infoOption.keyValue) {
      console.log('参数不全');
      return;
    }
    this.http.post<any>('A/IACommonService/getDynamicInfo', { param: this.infoOption }).subscribe(result => {
      console.log('异动资讯', result);
      this.infoList = result.data;
    });
  }

  public change(event): void {
    console.log(event);
    if (event) {
      this.visible1 =true;
      this.dataInit();
    }
  }
  public clickMoreLog(): void {
    this.visible1 =!this.visible1;
    console.log('更多日志处理', this.infoOption);
   
    this.http.post<any>('A/IFxai036Service/getOperationLog', { param: this.infoOption }).subscribe(result => {
      console.log('更多日志处理', result);
      this.morelog_datas = result.data;
     
    });
  }


}
