import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Provider} from '@angular/compiler/src/core';
import {FrameworkUIModule, FxTabRoutingModule} from '@webdpt/framework';
import {FxAgGridEditorsModule} from './fx-ag-grid-editors-module';
import {FxAgNumberCellEditorComponent} from './ag-grid/fx-number-cell-editor.component';
import {FxAgGridDateEditorComponent} from './ag-grid/fx-ag-grid-date-editor.component';
// import { FxAgSelectCellEditorComponent } from './ag-grid/fx-ag-select-cell-editor.component';
// import { FxAgGridDateInputComponent } from './ag-grid/fx-ag-grid-date-input.component';
// import { FxAgGridValidationEditorComponent } from './ag-grid/fx-ag-grid-validation-editor';
// import { FxAgLargeTextCellEditorComponent } from './ag-grid/fx-ag-large-text-cell-editor.component';
import {FxAgTextCellEditorComponent} from './ag-grid/fx-ag-text-cell-editor.component';
import {FxAgTextColumnFilterComponent} from './ag-grid/fx-ag-text-column-filter.component';
import {FxAgTextColumnFloatingFilterComponent} from './ag-grid/fx-ag-text-column-floating-filter.component';
import {FxButtonEditorFilterComponent} from './ag-grid/fx-button-editor-filter.component';
import {FxCellButtonEditorComponent} from './ag-grid/fx-cell-button-editor.component';
import {FxFloatingButtonEditorFilterComponent} from './ag-grid/fx-floating-button-editor-filter.component';
import {CommonComponentModule} from './common-componnet/common-component-module';
import {SearchConditionModule} from './search-condition/search-condition-module';
import {FxLayoutModule} from './layout';
import {FxDapModule} from './fx-http-client';
import {HyperlinkComponent} from './ag-grid/hyperlink.component';
import {ImgComponent} from './ag-grid/img.component';
import {OperationbtnComponent} from './ag-grid/operationbtn.component';
import {FxFormCellComponent} from './ag-grid/fx-form-cell/fx-form-cell.component';
// import {FxAgCheckboxRendererComponent} from './ag-grid/fx-ag-checkbox-renderer.component';
// import {FxAgCheckboxEditorComponent} from './ag-grid/fx-ag-checkbox-editor.component';
import {CheckboxRendererComponent} from 'app/implementation/shared/ag-grid/checkbox-renderer.component';
import {CheckboxEditorComponent} from 'app/implementation/shared/ag-grid/checkbox-editor.component';
import {FxAgIconRendererComponent} from './ag-grid/fx-ag-icon-renderer/fx-ag-icon-renderer.component';
import {ShowMenuService} from './layout/services/show-menu.service';
import {GirdBtnModule} from 'app/implementation/shared/grid-button/gird-btn.module';
import {FxGridOperationButtonEditorComponent} from './ag-grid/fx-grid-operation-button-editor/fx-grid-operation-button-editor.component';
import {FxGridOperationButtonRendererComponent} from './ag-grid/fx-grid-operation-button-renderer/fx-grid-operation-button-renderer.component';
// import { FxAgFormCellRendererComponent } from './ag-grid/fx-ag-formcell-renderer.component';
// import {DwLoginModule, FxLoginBlockComponent} from './components/login';
import {FxCheckboxComponent} from './components/fx-checkbox/fx-checkbox.component';
import {TrueFalseValueDirective} from './components/true-false-value.directive';
import {RoleAndUserRelationshipSettingComponent} from './components/modals/role-and-user-relationship-setting/role-and-user-relationship-setting.component';
import {FxModalModule} from './components/fx-modal/fx-modal.module';
import {FxValidatesDirective} from './validate/fx-validates';
import {FxAsyncValidatorDirective} from './validate/fx-asyncvalidators';
import {FxAgGridSelectCellRendererComponent} from './ag-grid/fx-ag-grid-select-cell-renderer/fx-ag-grid-select-cell-renderer.component';
import {FxToolBarComponent} from './components/fx-tool-bar/fx-tool-bar.component';
// import { FxAgGridStatusCellRendererComponent } from './ag-grid/fx-ag-grid-status-cell-renderer/fx-ag-grid-status-cell-renderer.component';
import {Data} from '@angular/router';
import {FieldAuthorizedDirective} from './auth/field-authorized.directive';
import {FieldAuthorizedService} from './auth/field-authorized.service';
import {FxUserInfoService} from '../auth/fx-auth/fx-user-info.service';
import {AngularSplitModule} from 'angular-split';
import {AngularDraggableModule} from '../../../../node_modules/angular2-draggable';
import {FxAddressPickerModule} from './components/fx-address-picker/fx-address-picker.module';
import {FxListViewBaseComponent} from './pattern/list-view/fx-list-view-base.component';
import {FxMperCellRendererComponent} from './ag-grid/fx-mper-cell-renderer/fx-mper-cell-renderer.component';
import {FxModalCellRendererComponent} from './ag-grid/fx-modal-cell-renderer/fx-modal-cell-renderer.component';
import {FxButtonEditModule} from './components/fx-button-edit/fx-button-edit.module';
import {FxDetailStatusModule} from './components/fx-detail-status/fx-detail-status.module';
import {FxNumberFormatModule} from './components/fx-number-format/fx-number-format.module';
import {FxAgNumberFormatCellEditorComponent} from './ag-grid/fx-ag-number-format-editor.component';
import {FxAgNumberFormatCellRendererComponent} from './ag-grid/fx-ag-number-format-renderer.component';
import {PipesModule} from './components/pipe/pipes.module';
import {MatAutocompleteModule} from '@angular/material';
import {FxBaseComponent} from './fxbase/fx-base.component';
import {FxMasterDetailBaseComponent} from './pattern/masterdetail/fx-masterdetailbase.component';
import {FxListBaseComponent} from './pattern/list/fx-listbase.component';
import {FxExportComponent} from './components/fx-export/fx-export.component';
import {FxDownloadComponent} from './components/fx-download/fx-download.component';
import {FxImportComponent} from './components/fx-import/fx-import.component';
import {FxEditCellTableComponent} from './components/fx-edit-cell-table/fx-edit-cell-table.component';
import { FxModals2Module } from './components/fx-modals2/fx-modals2.module';
import { FxModals2Component } from './components/fx-modals2/fx-modals2.component';
import { FxAutomaticService } from 'app/implementation/shared/components/fx-automatic/fx-automatic.service';
import {FxHeaderRendererComponent } from './ag-grid/fx-header-renderer/fx-header-renderer.component';
import { FxImportSelfComponent } from './components/fx-impor-self/fx-import-self.component';
import {FxToolPanelModule} from './components/fx-tool-panel/fx-tool-panel.module';
import {FxToolPanelComponent} from './components/fx-tool-panel/fx-tool-panel.component';
import {FxStorageModule} from './fx-storage/fx-storage.module';

/**
 * 共享模組
 *
 * @export
 * @class SharedModule
 */
@NgModule({
  imports: [
    CommonModule,
    FrameworkUIModule,
    FxLayoutModule,
    FxDapModule,
    // DwLoginModule,
    FxModalModule,
    AngularSplitModule,
    AngularDraggableModule,
    FxAddressPickerModule,
    FxButtonEditModule,
    PipesModule,
    FxDetailStatusModule,
    FxNumberFormatModule,
    MatAutocompleteModule,
    // NgxAddressModule
    FxTabRoutingModule,
    FxAgGridEditorsModule,
    FxModals2Module,
    FxToolPanelModule,
    FxStorageModule
    //FxCommonModalsModule
  ],
  declarations: [
    FxBaseComponent,
    FxMasterDetailBaseComponent,
    FxListBaseComponent,
    // FxAgNumberCellEditorComponent,
    // FxAgGridDateEditorComponent,
    // FxAgSelectCellEditorComponent,
    // FxAgGridDateInputComponent,
    // FxAgGridValidationEditorComponent,
    // FxAgLargeTextCellEditorComponent,
    // FxAgTextCellEditorComponent,
    // FxAgTextColumnFilterComponent,
    // FxAgTextColumnFloatingFilterComponent,
    // FxButtonEditorFilterComponent,
    // FxCellButtonEditorComponent,
    // FxFloatingButtonEditorFilterComponent,
    HyperlinkComponent,
    ImgComponent,
    OperationbtnComponent,
    CheckboxRendererComponent,
    CheckboxEditorComponent,
    // FxAgCheckboxRendererComponent,
    // FxAgCheckboxEditorComponent,
    FxFormCellComponent,
    FxAgIconRendererComponent,
    FxGridOperationButtonEditorComponent,
    // FxAgNumberFormatCellEditorComponent,
    // FxGridOperationButtonRendererComponent,
    // FxAgFormCellRendererComponent,
    // FxAgNumberFormatCellRendererComponent,
    TrueFalseValueDirective,
    FxHeaderRendererComponent,
    FxMperCellRendererComponent,
    FxModalCellRendererComponent,
    RoleAndUserRelationshipSettingComponent,
    FxValidatesDirective,
    FxAsyncValidatorDirective,
    // FxAgGridSelectCellRendererComponent,
    FxToolBarComponent,
    // FxAgGridStatusCellRendererComponent,
    FieldAuthorizedDirective,
    FxListViewBaseComponent,
    FxExportComponent,
    FxDownloadComponent,
    FxImportComponent,
    FxEditCellTableComponent,
    FxImportSelfComponent,
    // DataDisplayAuthorizedDirective
    // FxCheckboxComponent
  ],
  exports: [
    FrameworkUIModule,
    CommonComponentModule,
    SearchConditionModule,
    FxLayoutModule,
    FxDapModule,
    GirdBtnModule,
    FxModalModule,
    // DwLoginModule,
    AngularDraggableModule,
    TrueFalseValueDirective,
    FxHeaderRendererComponent,
    FxMperCellRendererComponent,
    FxModalCellRendererComponent,
    RoleAndUserRelationshipSettingComponent,
    // FxAgGridSelectCellRendererComponent,
    FxToolBarComponent,
    FieldAuthorizedDirective,
    AngularSplitModule,
    FxAddressPickerModule,
    PipesModule,
    FxButtonEditModule,
    FxDetailStatusModule,
    FxNumberFormatModule,
    MatAutocompleteModule,
    FxExportComponent,
    FxDownloadComponent,
    FxImportComponent,
    FxEditCellTableComponent,
    FxImportSelfComponent,
    FxModals2Module,
    FxToolPanelModule,
    FxStorageModule
    // NgxAddressModule
    // DataDisplayAuthorizedDirective
  ],
  providers: [
    // FxUserInfoService,
    // FieldAuthorizedService,
    FxAutomaticService
  ],
  entryComponents: [
    FxHeaderRendererComponent,
    FxMperCellRendererComponent,
    FxModalCellRendererComponent,
    RoleAndUserRelationshipSettingComponent,
    // FxAgGridSelectCellRendererComponent,
    FxToolBarComponent,
    FxExportComponent,
    FxDownloadComponent,
    FxImportComponent,
    FxEditCellTableComponent,
    FxImportSelfComponent,
    FxModals2Component,
    // FxToolPanelComponent
    // FxAgGridStatusCellRendererComponent
  ]
})
export class SharedModule {
  static forRoot(providers: Provider[]): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        ...providers,
        ...FxAgGridEditorsModule.forAgGridComponents().providers
      ]
    };
  }
}

