import {Observable} from 'rxjs';
import {CfcAgGridValidationRendererComponent} from 'app/implementation/shared/fx-grid/cell-component/cfc-validation-renderer';

export class CfcOperationButtonRendererComponent extends CfcAgGridValidationRendererComponent {
  private eGui;
  public values;
  private rendererParams: any = {};
  private eventParams: any = {};

  constructor() {
    super();
  }

  public init(params: any): void {
    this.eGui = document.createElement('div');
    this.eGui.className = 'cfc-operation-btn-div';

    this.rendererParams = params;
    this.eventParams = {
      gridId: params.context.id,
      value: params.value,
      rowIndex: params.node.rowIndex,
      id: params.node.id,
      colId: params.column.colId,
      controlName: '',
      dataId: params.data.id || '-1'
    };

    this.createElements();
  }

  isFunc(value: boolean | any): boolean {
    if (typeof value === 'boolean') {
      return value;
    }
    if (typeof value === 'function') {
      const isShowDelFunc = value;
      return isShowDelFunc(this.eventParams);
    }
    return false;
  }

  private modify = (event: any): any => {
    event.stopPropagation();
    if (this.rendererParams.modify && typeof this.rendererParams.modify == 'function') {
      this.rendererParams.modify(this.eventParams);
    }
  };

  private delete = (event: any): any => {
    event.stopPropagation();
    if (this.rendererParams.delete && typeof this.rendererParams.delete == 'function') {
      this.rendererParams.delete(this.eventParams);
    }
  };

  private up = (event: any): any => {
    event.stopPropagation();
    if (this.rendererParams.up && typeof this.rendererParams.up == 'function') {
      this.rendererParams.up(this.rendererParams);
    }
  };

  private down = (event: any): any => {
    event.stopPropagation();
    if (this.rendererParams.down && typeof this.rendererParams.down == 'function') {
      this.rendererParams.down(this.rendererParams);
    }
  };

  private detail = (event: any): any => {
    event.stopPropagation();
    if (this.rendererParams.detail && typeof this.rendererParams.detail == 'function') {
      this.rendererParams.detail(this.eventParams);
    }
  };

  public getGui() {
    return this.eGui;
  }

  public destroy(): void {
    this.eGui.removeEventListener('modify', this.modify);
    this.eGui.removeEventListener('delete', this.delete);
    this.eGui.removeEventListener('up', this.up);
    this.eGui.removeEventListener('down', this.down);
    this.eGui.removeEventListener('detail', this.detail);
  }

  private operations = {};

  private createElement(operation, display = true) {
    const a = document.createElement('a');
    a.setAttribute('id', operation.id);
    a.setAttribute('title', operation.title);
    a.className = 'cfc-obr-' + operation.id;
    if (!display) {
      a.setAttribute('style', 'display: none;');
    }
    const i = document.createElement('i');
    i.className = operation.className;
    a.appendChild(i);
    a.addEventListener('click', operation.clickHandler);
    return a;
  }

  private createElements() {
    this.operations = {
      edit: {id: 'a-edit', title: '修改', className: 'anticon anticon-edit', clickHandler: this.modify, displayFn: this.rendererParams.isShowMod},
      delete: {id: 'a-delete', title: '删除', className: 'anticon anticon-delete', clickHandler: this.delete, displayFn: this.rendererParams.isShowDel},
      up: {id: 'a-up', title: '上移', className: 'anticon anticon-up-circle-o', clickHandler: this.up, displayFn: this.rendererParams.isShowUp},
      down: {id: 'a-down', title: '下移', className: 'anticon anticon-down-circle-o', clickHandler: this.down, displayFn: this.rendererParams.isShowDown},
      detail: {id: 'a-detail', title: '详情', className: 'anticon anticon-edit', clickHandler: this.detail, displayFn: this.rendererParams.isShowDetail},
    };
    let fragment = document.createDocumentFragment();
    ['edit', 'delete', 'up', 'down', 'detail'].map(key => (
      this.createElement(this.operations[key], this.isFunc(this.operations[key].displayFn))
    )).forEach(ele => {
      fragment.appendChild(ele);
    });
    this.eGui.appendChild(fragment);
  }

  private refreshElement(operation, display) {
    const ele = this.eGui.getElementsByClassName('cfc-obr-' + operation.id)[0];
    const eleStyle = ele.getAttribute('style');
    // 检查当前的 style，和 display 不一致时再修改
    if (display && eleStyle) {
      ele.setAttribute('style', '');
    } else if (!display && !eleStyle) {
      ele.setAttribute('style', 'display: none;');
    }
  }

  private refreshElements() {
    return ['edit', 'delete', 'up', 'down', 'detail'].map(key => (
      this.refreshElement(this.operations[key], this.isFunc(this.operations[key].displayFn))
    ));
  }

  public refresh() {
    this.refreshElements();
    return true;
  }
}
