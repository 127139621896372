import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import Viewer from 'viewerjs';

@Component({
  selector: 'app-img-cell',
  template: '<img style="width: 84px;height: 84px;object-fit: cover;margin-bottom: 4px; cursor: pointer;" (click)="clicked()"  [src]="value" />'
})
export class ImgComponent implements ICellRendererAngularComp {
  private params: any;
  value: any;
  imagesView: any;

  agInit(params: any): any {
    this.params = params;
    if (params.getValue) {
      this.value = params.getValue();
    }
  }

  clicked(): void {
    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.onClick) {
      this.params.colDef.cellRendererParams.onClick(this.params, this);
      return;
    }
    this.imagesView = new Viewer(this.params.eGridCell.childNodes[0]
      , {
        hidden() {
          console.log('hidden');
          this.viewer.destroy();
        }
      });
  }

  refresh(): boolean {
    return false;
  }

}
