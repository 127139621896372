import {CfcAgGridValidationRendererComponent} from 'app/implementation/shared/fx-grid/cell-component/cfc-validation-renderer';

export class CfcOperationTextRendererComponent extends CfcAgGridValidationRendererComponent {
  private eGui;
  public values;
  private actions: any = {};
  private eventParams: any = {};

  constructor() {
    super();
  }

  public init(params: any): void {
    this.eGui = document.createElement('div');

    this.actions = params.actions;
    this.eventParams = {
      value: params.value,
      rowIndex: params.node.rowIndex,
      id: params.node.id,
      data: params.data,
      colId: params.column.colId,
      controlName: '',
      dataId: params.data.id || '-1'
    };

    this.createElements();
  }

  isFunc(value: boolean | any): boolean {
    if (typeof value === 'boolean') {
      return value;
    }
    if (typeof value === 'function') {
      const isShowDelFunc = value;
      return isShowDelFunc();
    }
    return false;
  }

  public getGui() {
    return this.eGui;
  }

  private createElement(operation) {
    const that = this;
    const show = operation.show(that.eventParams);
    if (!show) {
      return;
    }
    const a = document.createElement('a');
    a.title = operation.label;
    a.setAttribute('style', ' margin-right:8px');
    a.innerHTML = operation.label;
    a.addEventListener('click', function (event: any) {
      event.stopPropagation();
      if (operation.onClick && typeof operation.onClick == 'function') {
        operation.onClick(that.eventParams);
      }
    });
    return a;
  }

  private createElements() {
    const fragment = document.createDocumentFragment();
    let that = this;
    this.actions.forEach(action => {
      const ele = this.createElement(action);
      if (ele) {
        fragment.appendChild(ele);
      }
    });
    this.eGui.appendChild(fragment);
  }
}
