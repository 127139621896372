import { Component, OnDestroy } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'fx-ag-number-bothsides-change-cell-renderer',
  template: `
        <dw-input-group class="inline-block" [dwAddOnBefore]="addOnBeforeTemplate"
          [dwAddOnAfter]="addOnAfterTemplate">
          <input class="x-center" type="text" dw-input [(ngModel)]="value"
            (change)="onPayNumberChange($event)">
        </dw-input-group>
        <ng-template #addOnBeforeTemplate>
          <span class="jump-node" (click)="value=value-1">-</span>
        </ng-template>
        <ng-template #addOnAfterTemplate>
          <span class="jump-node" (click)="value=value+1">+</span>
        </ng-template>
  `,
  styles: [
    `.inline-block {
      display: inline-block;
    }
  
    .jump-node {
      cursor: pointer;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 14px;
    }
  
    .x-center {
      text-align: center;
  
      .fx_pagination,
      .ant-pagination {
        justify-content: center !important;
      }
    }`
  ]
})
export class FxAgNumberBothsidesChangeCellRendererComponent extends FxAgGridValidationEditorComponent implements ICellRendererAngularComp, OnDestroy {
  private params: ICellRendererParams;
  value: number = 1;
  name: string;
  valueDisplay: any = null;
  currentStyles: any; // 样式
  constructor(private fxUserService: FxUserInfoService) {
    super();
    // console.warn('创建FormCellRenderer:');
  }

  agInit(params: any): any {
    this.params = params;
    this.value = this.params.getValue();
    this.currentStyles = this.params['style'] ? this.params['style'] : null;
    // 解决grid值更新问题
    if (this.params.data && this.value != undefined) {
      this.params.data[params.column.colId] = Number(this.value);
    }

    super.init();
  }

  // 点击事件
  clickFun(): void {
    if (
      this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.onClick) {
      this.params.colDef.cellRendererParams.onClick(this.value, this);
    }
  }

  refresh(): boolean {
    // console.warn('刷新FormCellRenderer:', this.name);
    return false;
  }


  ngOnDestroy(): void {
    // console.warn('销毁FormCellRenderer:', this.name);
  }
}

