import { Injectable } from '@angular/core';

@Injectable()
export class DwHttpRequestUrlService {

  constructor() {
  }

  public getUrl(apiUrl: string, requestUrl: string): string {
    let url = apiUrl + requestUrl;

    if (requestUrl.startsWith('http://') || requestUrl.startsWith('https://')
      || requestUrl.startsWith('//') || requestUrl.startsWith('assets/')) {

      url = requestUrl;
    }

    return url;
  }
}
