import {CfcAgGridValidationRendererComponent} from 'app/implementation/shared/fx-grid/cell-component/cfc-validation-renderer';

export class CfcHyperlinksRendererComponent extends CfcAgGridValidationRendererComponent {
  private eGui;
  private rendererParams: any = {};
  private value;

  constructor() {
    super();
  }

  public init(params: any): void {
    // <a (click)="clicked()" title={{value}}>{{value}}</a>
    if(!params.value){
      this.eGui = document.createElement('span');
      return;
    }
    this.eGui = document.createElement('a');
    this.eGui.title = params.value;
    this.value = params.value;
    this.rendererParams = params;
    if (params.splitChar) {
      const array = params.value.split(params.splitChar);
      let that = this;
      for (let i = 0; i < array.length; i++) {
        if (i != 0) {
          this.eGui.append(params.splitChar);
        }
        const span = document.createElement('span');
        span.innerHTML = array[i];
        span.addEventListener('click', function (event) {
          event.stopPropagation();
          if (that.rendererParams.onClick && typeof that.rendererParams.onClick === 'function') {
            that.rendererParams.onClick(array[i], that);
          }
        });
        this.eGui.appendChild(span);
      }
    } else {
      this.eGui.innerHTML = (params.value === undefined || params.value === null || params.value === NaN) ? '' : params.value;
      this.eGui.addEventListener('click', this.clicked);
    }
  }

  private clicked = (event: any): any => {
    event.stopPropagation();
    if (this.rendererParams.onClick && typeof this.rendererParams.onClick === 'function') {
      this.rendererParams.onClick(this.value, this);
    }
  }

  public getGui(): any {
    return this.eGui;
  }

  public destroy(): void {
    if(this.eGui){
      document.removeChild(this.eGui);
    }
  }
}
