import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import * as crypto from 'crypto-js';
import {DwAuthService, DW_AUTH_TOKEN, DwUserService, DwSystemConfigService, DW_APP_AUTH_TOKEN, DwRoutingMessageService} from '@webdpt/framework';
// import { CustUserService } from './cust-user.service';
import { LocalStorage } from '@webdpt/framework';
import { APP_DEFAULT, LONIG_DEFAULT, DW_USING_FRSSO } from '@webdpt/framework';
import { IDwRequestOptions } from '../../../../../projects/webdpt/framework/http/interface';


@Injectable()
export class FxAuthService extends DwAuthService {
  //loginSubject: BehaviorSubject<boolean>; // 廣播登入狀態
  //loginSuccess = (this.custUserService.userInfo['isLoggedin'] && JSON.parse(this.custUserService.userInfo['isLoggedin'])) ? true : false;

  constructor(
    protected custRouter: Router,
    protected custUserService: DwUserService,
    protected custStorage: LocalStorage,
    protected http: HttpClient,
    public dwMessage: DwRoutingMessageService,
    @Inject(DW_AUTH_TOKEN) protected authToken: any,
    @Inject(APP_DEFAULT) private custDefaultApp: any,
    @Inject(LONIG_DEFAULT) protected defaultLogin: string,
    @Inject(DW_USING_FRSSO) protected usingFrSSO: boolean,
    protected configService: DwSystemConfigService,
    @Inject(DW_APP_AUTH_TOKEN) private dwAppAuthToken: string
  ) {
    super(custRouter, custUserService, custStorage, http, authToken, custDefaultApp, defaultLogin, usingFrSSO, configService);
    this.configService.getConfig().subscribe(
      config => {
        //this.msgURL = config.msgUrl + 'api/emc/v1/';
        //this.iamURL = config.iamUrl + 'api/iam/v2/';
      }
    );
    this.loginSubject = new BehaviorSubject<boolean>(this.loginSuccess);
  }

  public login(userConfig: any): Observable<any> {
    const info = {
      success: false,
      description: '', // 登入失敗時, 顯示.
      isFirstLogin: false,  //是否是首次登陆
      data: {} //登录返回信息
    };

    return new Observable((observer): void => {
      //this.logining().subscribe(
      //  result => {
      //    if (!result) {
      //      info.success = false;
      //      info.description = '登入失敗';
      //      observer.next(info);
      //      observer.complete();
      //      return;
      //    }

          //密码md5编码一下
          let md5_password = crypto.MD5(userConfig.password)+'';
          let userParam;
          // debugger
          console.log("md5_password:"+md5_password,userConfig.password);
          //新登录页添加新参数判断入参
          if(userConfig.saas != undefined) {
            userParam = {
              userId: userConfig.userId,  // 类型：String  必有字段  备注：无, userId可輸入id, email, mobilephone.
              password: md5_password, // 类型：String  必有字段  备注：與password兩者擇一
             // ent: userConfig.ent  ,    // 类型：String  必有字段  备注：无
              language: userConfig.language ,    // 类型：String  语言
              scend: userConfig.scend, //类型 Boolean 二次登陆
              saas: true
            };
          } else {
            userParam = {
              userId: userConfig.userId,  // 类型：String  必有字段  备注：无, userId可輸入id, email, mobilephone.
              password: md5_password, // 类型：String  必有字段  备注：與password兩者擇一
              ent: userConfig.ent  ,    // 类型：String  必有字段  备注：无
              language: userConfig.language ,    // 类型：String  语言
              scend: userConfig.scend, //类型 Boolean 二次登陆
            };
          }



          console.log('userConfig.captchaKey:',userConfig.captchaKey);
          console.log('-----------:',userConfig.language);
          //有验证码登录
          if(userConfig.captchaKey.isCaptcha){
            
            this.http.post('A/ILoginService/login', {
              param: userParam, captchaDataMap: userConfig.captchaKey
            }, this.addDefaultOption()).subscribe((response: any) => {
              console.log('login response:', response);
              if (!response.token) {
                window.location.href = response.redirectUrl;
              } else {
                let token = {id: 'token', value: response.token};
                this.userService.setUser(token);
                sessionStorage.setItem('token', response.token); //缓存token
                let userId = {id: 'userId', value: userConfig.userId};
                this.userService.setUser(userId);
                this.setLogined(userId);
                //console.log('token:',this.userService.userInfo['token']);
                let data = JSON.parse(response.data);
                console.log("response.data.isFirstLogin:",data.isFirstLogin);
                if(data.isFirstLogin === 'Y'){
                  info.isFirstLogin = true;
                }
                info.success = true;
                info.data = response;
              }
              observer.next(info);
              observer.complete();
              return;
            },(error:any) => {
              console.log('登录error:',error);
              this.dwMessage.error(error.error.errorMessage);
              info.success = false;
              info.description = error.error.errorMessage;
              observer.error(info);
              observer.complete();
            });
          }else{  //无验证码登录
            this.http.post('A/ILoginService/login', {
              param: userParam
            }, this.addDefaultOption()).subscribe((response: any) => {
              console.log('login response:', response);
              if (!response.token) {
                window.location.href = response.redirectUrl;
              } else {
                let token = {id: 'token', value: response.token};
                this.userService.setUser(token);
                sessionStorage.setItem('token', response.token); //缓存token
                let userId = {id: 'userId', value: userConfig.userId};
                this.userService.setUser(userId);
                this.setLogined(userId);
                //console.log('token:',this.userService.userInfo['token']);
                let data = JSON.parse(response.data);
                console.log("response.data.isFirstLogin:",data.isFirstLogin);
                if(data.isFirstLogin === 'Y'){
                  info.isFirstLogin = true;
                }
                info.success = true;
                info.data = response;
              }
              observer.next(info);
              observer.complete();
              return;
            },(error:any) => {
              console.log('登录error:',error);
              this.dwMessage.error(error.error.errorMessage);
              info.success = false;
              info.description = error.error.errorMessage;
              observer.error(info);
              observer.complete();
            });
          }

          /*this.http.post(this.iamURL + 'identity/login', this.userConfig)
            .subscribe((userData) => {
              console.log('userData>>>>>>>', userData);
              if (!userData) {
                info.success = false;
                info.description = '登入失敗';
                observer.next(info);
                observer.complete();
                return;
              }

              this.setLogined(userData);
              observer.next(info);
              observer.complete();
              return;
            },
            error => {
              observer.error(error)
              observer.complete();
            }
            );*/
        }
      );
    //});
  }

  private addDefaultOption(options?: IDwRequestOptions): any {
    const localOptions = options || {};

    let headers: HttpHeaders;
    if (localOptions.headers instanceof HttpHeaders) {
      headers = localOptions.headers;
    } else {
      headers = new HttpHeaders(localOptions.headers);
    }

    const localHeaders = {'ati-cookie': document.cookie || '', 'local-ip': localStorage.getItem('local_cip') || '', 'jdid': localStorage.getItem('jd_eid')|| ''};

    headers = Object.keys(localHeaders)
      .reduce((acc, name) => (acc.has(name)) ? acc : acc.set(name, localHeaders[name]), headers);


    return {
      headers,
      observe: 'body',
      params: [],
      reportProgress: localOptions.reportProgress,
      responseType: 'json',
      withCredentials: localOptions.withCredentials
    };
  }

  //设置登录token\user等实例
  // public setLoginInfo(response: any): Observable<any> {
  //   return new Observable((observer): void => {
  //     let token = {id: 'token', value: response.token};
  //     this.userService.setUser(token);
  //     let userId = {id: 'userId', value: response.userId};
  //     this.userService.setUser(userId);
  //     this.setLogined(userId);
  //     //console.log('token:',this.userService.userInfo['token']);
  //     let data = JSON.parse(response.data);
  //     console.log("response.data.isFirstLogin:",data.isFirstLogin);
  //     if(data.isFirstLogin === 'Y'){
  //       response.isFirstLogin = true;
  //     }
  //     observer.next(response);
  //     observer.complete();
  //   });
  // }

}
