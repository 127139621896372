import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation, Optional} from '@angular/core';
import { ICellRendererAngularComp} from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';
import {Subscription} from 'rxjs';
import { DwCheckboxComponent } from 'ng-quicksilver';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';

@Component({
  selector: 'fx-checkbox-cell-renderer',
  template: `
      <label\n dw-checkbox\n  dw-checkbox\n   [(ngModel)]="checked"  [(dwDisabled)]="disabled"></label>
  `,
  styleUrls: ['../../style/conponents/checkbox.less'],
  encapsulation: ViewEncapsulation.None
})
export class FxAgCheckboxRendererNewComponent extends FxAgGridValidationEditorComponent implements ICellRendererAngularComp, OnDestroy {
  private params: ICellRendererParams;
  checked: boolean = false;
  disabled: boolean = false;
  dwTrueVal: string = 'Y';
  dwFalseVal: string = 'N';
  name: string;

  constructor() {
    super();
  }

  agInit(params: any): any {

    this.params = params;


    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwTrueVal) {
        this.dwTrueVal = this.params.colDef.cellRendererParams.dwTrueVal;
    }

    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwFalseVal) {
      this.dwFalseVal = this.params.colDef.cellRendererParams.dwFalseVal;
    }

    if ( this.params.getValue() ===  this.dwTrueVal ) {
          this.checked = true;
    } else if ( this.params.getValue() === this.dwFalseVal ) {
          this.checked = false;
    } else {
          // console.log('Value is Error!');
    }

    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwDisabled) {
        this.disabled = this.params.colDef.cellRendererParams.dwDisabled;
    }
    super.init();
  }

  refresh(): boolean {
    return false;
  }


  ngOnDestroy(): void {
  }
}
