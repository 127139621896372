import { DOWN_ARROW, ENTER, ESCAPE, TAB, SPACE, UP_ARROW } from '@angular/cdk/keycodes';
import { Component, OnInit, OnChanges, forwardRef, Input, Output, ViewContainerRef, EventEmitter, Optional, ViewEncapsulation, SimpleChanges, ElementRef } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { AbstractDwFormItemComponent } from '../../../../../../projects/webdpt/framework/components/form-field/abstract-dw-form-item-component';
import { Observable, of, ConnectableObservable } from 'rxjs';
import { concatMap, map, startWith, publishLast } from 'rxjs/operators';
import { TranslateParser } from '@ngx-translate/core';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { DwModalService } from 'ng-quicksilver';
import {fxButtonEditFilterPipe} from 'app/implementation/shared/components/pipe/fx-button-edit-filter.pipe';
// import { replaceOrInsertTemplate } from '../../../../../../projects/webdpt/framework/components/dw-container/replaceOrInsertTemplate';
// import {FxSearchModalComponent, FxSearchModalparam, FxSearchModelColumn, FxSearchModelConfig} from 'app/implementation/shared/components/fx-modal/fx-search-modal.component';
import { FxConfirmModalComponent } from 'app/implementation/shared/components/fx-modal/fx-confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fx-button-edit',
  templateUrl: './fx-button-edit.component.html',
  styleUrls: ['./fx-button-edit.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    fxButtonEditFilterPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FxButtonEditComponent),
      multi: true
    }
  ]
})
export class FxButtonEditComponent extends AbstractDwFormItemComponent {
  @Input() id: string;
  @Output() inputChanged = new EventEmitter<string>();
  @Output() onFocus = new EventEmitter<string>();
  @Input() name: string;
  @Output() nameChange = new EventEmitter<string>();
  @Output() opened = new EventEmitter<string>();
  @Output() afterBlur = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() useDroplist: boolean = true;
  @Input() placeholder: string = '';
  @Input() optionData: any = {
    "method": "", // 服务名称--非必填如果是公共服务则searchType="all",否则按照实际来--非必填
    "param": {},  // 服务入参--必填
    "checkParam": {},  // 服务检核入参--必填
    "programId":"",//作业编号--（自定义表单传参）
    "id": "",     // 下拉数据的id对应服务返回数据的名称--必填
    "name": "",   // 下拉数据的name对应服务返回数据的名称--必填
    "reGetData": false, // 是否重新获取下拉数据--非必填
    "disableCheck": false, // 是否启用检核--非必填
    "hiddenName": false    // 是否隐藏name--非必填
    // "returnConfig": "" // 服务返回数据的格式--非必填
  };  // 开窗下拉的参数包含http名称和入参

  showContent: boolean = true;
  // myControl: FormControl;
  // @Input() options: Observable<any>;
  options: Observable<any>;
  filteredOptions: Array<any>;
  isReGetDropDownData: boolean = true; // 是否重新获取开窗下拉的值，默认true
  activeId: any; // 记录鼠标移动到的数据id
  oldId: any;
  oldValue: any;
  isSetData: string = ''; // 是否点击的是下拉选项-默认不是
  nameDisplay:  string = ''; // 显示的名称
  isBackendPaging: Boolean = false; // 是否后端分页，默认非后端分页
  onChange: (value: any) => void = () => null;

  constructor(@Optional() protected _fg: ControlContainer,
    @Optional() protected _ts: TranslateParser,
    public viewContainerRef: ViewContainerRef,
    public element: ElementRef,
    public http: FxDapHttpClient,
    public fxModalService: FxModalService,
    public dwModalService: DwModalService,
    private translateService: TranslateService,
    public fxButtonEditFilterPipe: fxButtonEditFilterPipe) {
    super(_fg, _ts);
  }

  private _filter(value: string): any {
    const filterValue = value
      ? value.toLowerCase()
      : '';
    return this.options;
  }

  get value(): any {
    return this.id;
  }

  set value(value: any) {
    if (value !== this.id) {
      this.id = value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
  }

  writeValue(value: any): void {
    this.id = value ? value : "";
    this.activeId = value ? value : "";
    if (this.filteredOptions && this.filteredOptions.length) {
      const idx = this.filteredOptions.findIndex(item => item.id === this.id)
      if (idx !== -1) {
        this.nameDisplay = this.filteredOptions[idx].name;
      } else {
        this.nameDisplay = '';
      }
    } else {
      setTimeout(() => {
        // name绑定的是字段名称
        this.nameDisplay = this.controlContainer['form'].value[this.name];
      }, 0);
    }
    if (!this.id) {
      this.nameDisplay = "";
    }
    //  this.onChange(value);
  }

  registerOnChange(fn: (_: boolean) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    // this.onChange = fn;
  }

  onIdChange($event: any): void {
    this.id = $event;
    this.inputChanged.emit(this.id);
    setTimeout(() => {
      this.nameChange.emit(this.nameDisplay);
    }, 0);
    if (this.optionData.disableCheck) {
      this.onChange(this.id);
      return;
    }
    this.onChange(this.id);

    // if (this.filteredOptions && this.filteredOptions.length) {
    //   const idx = this.filteredOptions.findIndex(item => item.id === this.id)
    //   if (idx !== -1) {
    //     this.nameDisplay = this.filteredOptions[idx].name;
    //   } else {
    //     this.nameDisplay = "";
    //   }

    // }
    // this.onChange(this.id);
    // if (!this.id) {
    //   this.nameDisplay = "";
    // }
  }

  onClick($event): void {
    this.opened.emit($event);
  }

  focus(): void {
    if (this.readonly || this.disabled) {
      return;
    }
    this.isSetData = '';
    this.oldId = this.id;
    this.onFocus.emit(this.id);
    this.getDropDownList();
    this.showContent = false;
    this.activeId = ""
    setTimeout(() => {
      this.viewContainerRef.element.nativeElement.getElementsByTagName('input')[0].focus();
    }, 0);
  }

  openModal(data): void {
    const modal = this.dwModalService.create({
      dwTitle: null,
      dwStyle: { top: '20px' },
      //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
      dwMaskClosable: false, // 點擊遮罩是否允許關閉
      dwContent: FxConfirmModalComponent,
      dwWrapClassName: 'fx_md_modal',
      dwFooter: [
        {
          label: this.translateService.instant('comm-取消'),
          shape: 'default',
          onClick: (data: any): any => {
            modal.destroy();

          }
        },
        {
          label: this.translateService.instant('comm-确定'),
          type: 'primary',
          onClick: (data: any): any => {
            modal.close({});

          }
        }
      ],
      dwComponentParams: {
        title: data.title,
        message: data.message,
        moreInfo: data.moreInfo,
        program_name: data.programName,
        program_no: data.programNo,
        isRemind: data.isRemind
      }
    });
  }

  blur($event: any): void {
    this.onIdChange(this.id);
    setTimeout(() => {
      if (this.isSetData === 'setName') {
        this.onChange(this.id);
        return;
      }
      this.isSetData = 'blur';
      // this.oldId = this.id;
      this.showContent = true;
      if (this.optionData.disableCheck) {
        this.onChange(this.id);
        this.afterBlur.emit({ id: this.id, name: "", postType: "", oldId: this.oldId });
        return;
      }
      if (this.oldValue === $event.target.value) {
        return;
      }
      if ($event.target.value) {
        const idx = this.filteredOptions.findIndex(item => item.id === $event.target.value)
        if (idx !== -1) {
          this.id = this.filteredOptions[idx].id;
          this.nameDisplay = this.filteredOptions[idx].name;
          this.activeId = this.id;
          this.filteredOptions[idx].postType = "success"
          this.filteredOptions[idx].oldId = this.oldId;
          this.onChange(this.id);
          this.afterBlur.emit(this.filteredOptions[idx]);
        } else {
          // 解决录入不存在资料提示的问题
          if (!this.optionData.method) {
            this.fxModalService.searchFx(this.optionData.checkParam,this.optionData.programId).subscribe(
              (data) => {
                // 解决后端分页资料检核智能检核部分的问题
                if (data && data.length) {
                  this.setFullData(data[0]);
                } else {
                  this.setEmptyData();
                }
              },
              (error) => {
                this.setEmptyData();
              }
            );
          } else {
            this.http.post(this.optionData.method, this.optionData.checkParam).subscribe(response => {
              if (!response.success) {
                this.setEmptyData();
                this.openModal(response);
              } else {
                if (response.data && response.data.length) {
                  this.setFullData(response.data[0]);
                } else {
                  this.setEmptyData();
                }
              }
            }, error => {
              this.setEmptyData();
              this.openModal(error);
            });
          }
        }
      } else {
        this.setEmptyData();
      }
    }, 300);
  }

  // 资料赋值
  setFullData(data: any) {
    this.id = data[this.optionData.id];
    this.nameDisplay = data[this.optionData.name];
    this.activeId = this.id;
    data.id =  this.id;
    data.name = this.nameDisplay;
    data.postType = "success"
    data.oldId = this.oldId;
    this.onChange(this.id);
    this.afterBlur.emit(data);
  }

  // 清空资料
  setEmptyData() {
    this.id = '';
    this.nameDisplay = '';
    this.activeId = '';
    this.onChange(this.id);
    this.afterBlur.emit({ id: "", name: "", postType: "fail", oldId: this.oldId });
  }

  onMouseEnter(item: any): void {
    this.activeId = item.id;
  };

  inputFocus($event): void {
    this.oldValue = $event.target.value;
  };

  keyEvent($event): void {
    if ($event.keyCode === ENTER) {
      $event.preventDefault();
    }

    if (($event.keyCode === ENTER || $event.keyCode === TAB)) {
      if (this.activeId && !this.showContent && this.filteredOptions && !this.optionData['disableCheck'] && !this.isBackendPaging) {
        if (this.activeId) {
          this.id = this.activeId;
        }
      }
    }
    // 绑定上下键事件
    if ($event.keyCode === DOWN_ARROW || $event.keyCode === UP_ARROW) {
      if (!this.activeId) {
        this.options.subscribe(data => {
          if (data.length) {
            this.onMouseEnter(data[0]);
          }
        });
      } else {
        this.options.subscribe(data => {
          if (data.length) {
            const idx = data.findIndex(item => {
              return item.id === this.activeId;
            });
            if (idx !== -1) {
              const newIdx = $event.keyCode === DOWN_ARROW ? (idx + 1) : (idx - 1)
              if (newIdx <= (data.length -1) && newIdx >= 0) {
                this.onMouseEnter(data[newIdx]);
                this.scrollIntoView(newIdx);
              } else if (newIdx <= (data.length -1) && newIdx < 0){
                this.onMouseEnter(data[data.length -1]);
                this.scrollIntoView(data.length -1);
              } else {
                this.onMouseEnter(data[0]);
                this.scrollIntoView(0);
              }
            }else {
              this.onMouseEnter(data[0]);
              this.scrollIntoView(0);
            }
          }
        });
      }
    }
  };

  // 滚动条位置
  scrollIntoView(Idx) {
    var height = 32; // 下拉每条数据的高度
    if (document.querySelector('.mat-autocomplete-panel')) {
      document.querySelector('.mat-autocomplete-panel').scrollTop = (height * Idx);
    }
  }


  afterContentInit(): void { };

  onInit(): void {
    // const formControl = new FormControl();
  }

  debug(value1: string, value2: string): void {
    // console.debug('onModelChange');
  }

  // 点击下拉框的数据给input赋值
  setName(option: any): void {
    // 判断如果是点击下拉选项则不再走失去焦点事件
    if (this.isSetData == 'blur') {
      return;
    }
    this.isSetData = 'setName';
    this.showContent = true;
    this.id = option.id;
    this.nameDisplay = option.name;
    option.oldId = this.oldId;
    this.onChange(this.id);
    this.afterBlur.emit(option);
  };

  // 输入框变动则获取过滤的数据
  filterData(): void {
    setTimeout(() => {
      if (this.filteredOptions && this.filteredOptions.length) {
        const filterList = this.fxButtonEditFilterPipe.transform(this.filteredOptions, this.id);
        if (filterList && filterList.length) {
          this.activeId = filterList[0].id;
        }
       }
    }, 500);
  }

  // 获取下拉获取数据
  getData(): Observable<any> {
    return Observable.create(observer => {
      if (!this.useDroplist) {
        observer.next([]);
        observer.complete();
        return;
      }
      this.fxModalService.searchFx(this.optionData.param,this.optionData.programId).subscribe(
        (data) => {
          const result = data.map(item => {
            item.id = item[this.optionData.id];
            item.name = item[this.optionData.name];
            return item;
          });
          // 后端分页则不用下拉否则使用下拉
          if (result[0].isBackendPaging) {
            this.isBackendPaging = true;
            observer.next([]);
          } else {
            observer.next(result);
          }
          observer.complete();
        },
        (error) => {
          observer.next([]);
          observer.complete();
        }
      );
    });
  }
  // 设置开窗下拉列表数据
  getDropDownList(): void {
    if ((!this.isReGetDropDownData && !this.optionData.reGetData) || this.optionData.disableCheck || this.isBackendPaging ) {
      return;
    }
    this.isReGetDropDownData = false;
    if (!this.optionData.method) {
      this.options = this.getData().pipe(publishLast());
      (this.options as ConnectableObservable<any>).connect();
      this.options.subscribe(data => {
        this.filteredOptions = data;
      });
    } else {
      this.options = this.http.post(this.optionData.method, this.optionData.param)
        .pipe(concatMap(res => {
          var data;  // 存放服务返回的数据
          data = res.data || [];
          const result = data.map(item => {
            item.id = item[this.optionData.id];
            item.name = item[this.optionData.name];
            return item;
          });
          return of(result);
        })).pipe(
          publishLast()
        );
      (this.options as ConnectableObservable<any>).connect();
      this.options.subscribe(data => {
        this.filteredOptions = data;
      });
    }
  };

}
