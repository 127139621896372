import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// import { ActivatedRoute, NavigationExtras } from '@angular/router';
// import { IAgGridEditorEvent, FxAgGridEventHandlerService } from 'app/implementation/shared/ag-grid/fx-ag-grid-event-handler.service';
import { DwModalService } from 'ng-quicksilver';
// import { DwRoutingMessageService, DwNewRouteKeyService } from '@webdpt/framework';
// import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { ColumnApi, Column, RowNode, GridOptions, GridApi } from 'ag-grid-community';
// import { MasterObj } from '../fxai901.module.spec';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { ActivatedRoute } from '@angular/router';
import { DwUserStorage } from '../../../../../../../projects/webdpt/framework/auth/user-storage';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.less'],
  providers: [
    /*
     * 注：建立新的实例，才不会造成作业间的错误
     */
    // FxAgGridEventHandlerService
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordComponent implements OnInit {
  showPassword: boolean = false; // 密碼是否使用明碼顯示.
  showPassword1: boolean = false; // 密碼是否使用明碼顯示.
  showPassword2: boolean = false; // 密碼是否使用明碼顯示.
  userId: string;
  old_password: string;
  new_password: string;
  sure_password: string;
  c_E017: string;
  public myform: FormGroup; // 表单控件
  public master;
  public Flag: any;
  public param_userId: any;
  public overAllParam: any = {};

  @Input() set abj001(abj001: any) {
    this.param_userId = abj001;
    console.log(abj001, this.param_userId);
  }
  @Input() set flag(flag: any) {
    this.Flag = flag;
    console.log(flag, this.Flag);
  }
  @Input() ent: any;
  availableFields: Array<string> = [];



  constructor(
    public route: ActivatedRoute,
    // public router: DwNewRouteKeyService,
    public dwModalService: DwModalService,
    // public dwMessage: DwRoutingMessageService,
    // public http: FxDapHttpClient,
    private translateService: TranslateService,
    public fxModalService: FxModalService,
    public userinfo: FxUserInfoService,
    // private agGridEventHandler: FxAgGridEventHandlerService
    private fb: FormBuilder,
    protected userStorage: DwUserStorage,
    protected http: HttpClient
  ) {
    const overAllParam = this.userStorage.get('DwSysInfo');
    if (overAllParam) {
      this.overAllParam = JSON.parse(overAllParam);
    }

  }

  ngOnInit(): void {
    this.variableInit(); // 全局变量初始化

    this.onBeforeGetData();
    // if (this.gState == 'add') { // 新增状态
    this.stateControl();
    //   this.onAfterGetData();
    // } else {
    //   this.getData();
    // }
  }

  // 变量初始化
  variableInit(): void {
    // this.gdetaileditable = this.gState !== 'browse'; // 单身是否可修改
    this.dataGroup();
    this.master = {
      userId: this.Flag ? this.param_userId : this.userinfo.userInfo['userId'],//用户
      old_password: '',//原密码
      new_password: '',//新密码
      sure_password: ''//确认密码
    };
    const masterConObj = {};
    Object.keys(this.master).forEach(key => {
      if (this.master[key] instanceof Array) {
        masterConObj[key] = new FormGroup({});
      } else {
        masterConObj[key] = new FormControl(this.master[key]);
      }
    });
    this.myform = new FormGroup(masterConObj);
  }

  createDocumentFormcontrol(): void {
    this.myform.get('old_password').setValidators([Validators.required]);
    this.myform.get('new_password').setValidators([Validators.required, this.regexNew_password()]);
    this.myform.get('sure_password').setValidators([Validators.required, this.regexSure_password()]);
  }
  // 表编号检核


  // 获取详情数据
  getData(): void {

  }

  // 根据页面状态设置页面控件
  stateControl(): void {
    console.log(this.userStorage.get('DwUserInfo'));
    if (!this.overAllParam || !this.overAllParam.overAllSys) {
      this.http.post('A/IACommonService/pub_upd_aat', { param: ['c_E017'], aatent: this.ent }).subscribe(
        (sysDatas: any) => {
          if (sysDatas.success) {
            this.overAllParam.overAllSys = sysDatas.data;
          }
          this.createDocumentFormcontrol();
        }
      );
    } else {
      this.createDocumentFormcontrol();
    }
  }

  // 新增标准字段 比如建立组织，部门等管理字段
  onClickAddStandardField(): void {
  }

  // 新增常用字段 比如单号，日期等等
  onClickAddCommonField(): void {
  }

  onBeforeGetData(): void {
    // this.searchLoading = true; // 是否顯示加載中
  }

  onAfterGetData(): void {
    // this.searchLoading = false; // 是否顯示加載中
  }

  // 页面开窗 ===  begin ==

  // 用户开窗
  public openazb012(xEvent): void {
    const openModelParam = {
      modalID: 'qry_abj001',     // (必传) 开窗的编号
      postParam: [{

      }],             // (必传) 查询参数，注意是数组
      selected: [],
      searchType: 'q',
      mutilSelected: true,
    };
    if (xEvent.value) {
      openModelParam.selected = (xEvent.value instanceof Array) ? xEvent.value : [xEvent.value];
    }
    this.fxModalService.searchFx(openModelParam).subscribe((data) => {
      // this.myform.get('azb012').setValue(data[0].abj001);
    });
  }

  // 页面开窗 ===   end  ==
  // 页面逻辑处理 ===  begin  ==



  // 页面逻辑处理 ===   end  ==

  // 功能按钮 === begin  ==
  // 回列表

  onClickCancel(): void {
    // this.modal.triggerCancel();
  }

  OK(): void {
  }


  // 新增
  create(): void {
  }

  // 修改
  modify(): void {
  }

  /**
   * 取消
   */
  public cancel(): void {
  }
  // 保存
  public save(xstate: any): void {
  }

  // 保存前
  onBeforeSaveOrder(): void {
    console.log('保存前');
  }

  // 保存后
  onAfterSaveOrder(result: any, xstate: string, param: any): void {
  }



  // 重新加载页面数据
  dataGroup(): void {
  }

  // 重新加载页面数据
  dataReload(): void {
  }

  // 页面重载
  pageReload(xId: any, xState: any): void {
    // const navigationExtras: NavigationExtras = {
    //   relativeTo: this.route, // 相對路徑導頁
    //   queryParams: { 'caggoods': xId, xState: xState }
    // };
    // this.router.navigate(['../fxai901-s03'], navigationExtras);
    // location.reload();//F5



  }

  public regexSure_password(): ValidatorFn {

    return (control: AbstractControl): any => {
      const val = control.value;
      let result = false;
      const aac0013 = this.myform.get('new_password').value;
      if (aac0013 != '' && val != '') {
        if (val != aac0013) {
          return { tips: '您两次输入的密码不相符，请重新输入' };
        } else {
          this.myform.controls.new_password.markAsDirty(); //手动关闭 new_password 的同步验证器
          this.myform.controls.new_password.updateValueAndValidity();//手动启动 new_password 的同步验证器
        }
      }
      let c_E017: any;
      if (this.overAllParam && this.overAllParam.overAllSys) {
        const data = this.overAllParam.overAllSys.filter(obj => obj.aat001 === 'c_E017');
        c_E017 = data.length ? data[0].aat002 : '3';
      } else {
        c_E017 = '3';
      }
      if (c_E017 == "0") {
        const reg_id = /^.{6,20}$/;
        result = !reg_id.test(val) ? false : true;
      } else if (c_E017 == "1") {
        const reg_id = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{6,20}$/;
        result = !reg_id.test(val) ? false : true;
      } else if (c_E017 == "2") {
        const reg_id = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
        result = !reg_id.test(val) ? false : true;
      } else if (c_E017 == "3") {
        if (val == this.userinfo.userInfo['c_user']) {
          return { tips: this.translateService.instant('请设置6~20位不等于帐号或者手机号') };
        }
        const new_password = /^.{6,20}$/;
        return !new_password.test(val) ? { tips: this.translateService.instant('请设置6~20位不等于帐号或者手机号') } : true;
      }
    };
  }

  public regexNew_password(): ValidatorFn {
    type NewType = AbstractControl;
    return (control: NewType): any => {
      const val = control.value;
      const aac0013 = this.myform.get('sure_password').value;

      // 0.不控制，1.强，需含英文数字和符合字符，2.中，需包含英文和数字，3.弱，不等于帐号或者手机号
      let c_E017: any;
      if (this.overAllParam && this.overAllParam.overAllSys) {
        const data = this.overAllParam.overAllSys.filter(obj => obj.aat001 === 'c_E017');
        c_E017 = data.length ? data[0].aat002 : '3';
      } else {
        c_E017 = '3';
      }
      if (c_E017 == "0") {
        const new_password = /^.{6,20}$/;
        if (aac0013) {
          if (val != aac0013) {
            console.log(new_password.test(val))
            if (new_password.test(val)) {
              return new_password.test(val) ? { tips: '您两次输入的密码不相符，请重新输入' } : true;
            } else {
              return !new_password.test(val) ? { tips: this.translateService.instant('fxed020-请设置6~20位密码') } : true;
            }
          } else {
            this.regexSure_password();
          }
        } else {
          return !new_password.test(val) ? { tips: this.translateService.instant('fxed020-请设置6~20位密码') } : true;
        }
      } else if (c_E017 == "1") {
        const new_password = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{6,20}$/;
        if (aac0013) {
          if (val != aac0013) {
            console.log(new_password.test(val))
            if (new_password.test(val)) {
              return new_password.test(val) ? { tips: '您两次输入的密码不相符，请重新输入' } : true;
            } else {
              return !new_password.test(val) ? { tips: this.translateService.instant('fxed020-请设置6~20位密码') } : true;
            }
          } else {
            this.regexSure_password();
          }
        } else {
          return !new_password.test(val) ? { tips: this.translateService.instant('fxed020-请设置6~20位密码') } : true;
        }
      } else if (c_E017 == "2") {
        const new_password = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
        if (aac0013) {
          if (val != aac0013) {
            console.log(new_password.test(val))
            if (new_password.test(val)) {
              return new_password.test(val) ? { tips: '您两次输入的密码不相符，请重新输入' } : true;
            } else {
              return !new_password.test(val) ? { tips: this.translateService.instant('请设置6~20位包含英文和数字组合') } : true;
            }
          } else {
            this.regexSure_password();
          }
        } else {
          return !new_password.test(val) ? { tips: this.translateService.instant('请设置6~20位包含英文和数字组合') } : true;
        }
      } else if (c_E017 == "3") {
        if (val == this.userinfo.userInfo['c_user'] || val == this.userinfo.userInfo['abjmtel']) {
          return { tips: this.translateService.instant('请设置6~20位不等于帐号或者手机号') };
        }
        const new_password = /^.{6,20}$/;
        if (aac0013) {
          if (val != aac0013) {
            console.log(new_password.test(val))
            if (new_password.test(val)) {
              return new_password.test(val) ? { tips: '您两次输入的密码不相符，请重新输入' } : true;
            } else {
              return !new_password.test(val) ? { tips: this.translateService.instant('请设置6~20位不等于帐号或者手机号') } : true;
            }
          } else {
            this.regexSure_password();
          }
        } else {
          return !new_password.test(val) ? { tips: this.translateService.instant('请设置6~20位不等于帐号或者手机号') } : true;
        }
      }
    };
  }

}






