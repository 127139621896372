import {Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation, Optional, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {FxUserInfoService} from 'app/implementation/auth/fx-auth/fx-user-info.service';
import {FxAgGridValidationEditorComponent} from '../../../shared/ag-grid/fx-ag-grid-validation-editor';

@Component({
  selector: 'fx-ag-number-cell-renderer',
  template: `
    <div class="dw-f-txt-right" style="padding: 0px;" (click)="clicked()">
      <div *ngIf="status === 'invalid'" class="error-panel" [ngClass]="className"> {{valueDisplay}} </div>
      <span *ngIf="status !== 'invalid'" [ngClass]="className"> {{valueDisplay}} </span>
    </div>
  `
  ,
  // styles: [`
  // .error-panel {
  //   min-height: 27px;
  //   margin-left: -11px;
  //   margin-right: -11px;
  //   padding-left: 12px;
  //   border: 1px solid red;
  // }
  // `]

})
export class CfcNumberFormatRendererComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams;
  value: any = null;
  valueDisplay: any = null; // 显示的数值，1.解决千分位问题，2.解决返回number类型小数末尾是0会舍去的问题
  status: string = 'valid';
  className: string = '';

  constructor(private fxUserService: FxUserInfoService) {
  }

  agInit(params: any): any {
    this.params = params;
    this.className = params.className ? params.className : '';
    this.value = this.params.getValue();
    this.value = this.initValue(this.value, this.params['dwType'], this.params['dwCode'], this.params['dwCommas'], this.params['dwDecimal']);
    // 千分位处理
    if (this.params['displayFunc']) {
      this.valueDisplay = this.params['displayFunc'](this.params.data, this.value);
    } else if (this.params['dwCommas'] && !(Number.isNaN(this.value)) && (this.value !== null) && (this.value !== undefined)) {
      this.valueDisplay = this.addCommas(this.value);
    } else {
      this.valueDisplay = this.value;
    }
    // 解决grid值更新问题
    if (this.params.data && this.value != undefined) {
      this.params.data[params.column.colId] = Number(this.value);
    }

    // 判断栏位是否验证通过
    // if (params.vstatus) {
    //   console.log(params.vstatus(params));
    //   const _valueStatus = ((typeof params.vstatus === 'function'
    //     ? params.vstatus(params)
    //     : params.vstatus) || {}).status as string || '';
    //   this.status = _valueStatus.toLowerCase();
    // }
    if (params.context.formArrayId && params.context.serviceObj && params.data) {
      const id = [params.context.formArrayId, params.context.serviceObj.indexMap.get(params.data.id), params.column.colId].join('@');
      const vformControl = params.context.serviceObj.invalidFormControls.get(id);
      if (vformControl) {
        this.status = vformControl.status.toLowerCase();
      }
    }
  }

  /**
   * Decimal adjustment of a number.
   *
   * @param  {String}  type  The type of adjustment.
   * @param  {Number}  value  The number.
   * @param  {Integer}  exp    The exponent (the 10 logarithm of the adjustment base).
   * @returns  {Number}      The adjusted value.
   */
  decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  // Decimal round
  round10 = function (value, exp) {
    return this.decimalAdjust('round', value, exp);
  };
  // Decimal floor
  floor10 = function (value, exp) {
    return this.decimalAdjust('floor', value, exp);
  };
  // Decimal ceil
  ceil10 = function (value, exp) {
    return this.decimalAdjust('ceil', value, exp);
  };

  initValue(input, type, code, commas, dwDecimal): number {
    // if ((input != 0 && !input) || input == null) return null;
    // 判断是否是数值类型
    if (parseFloat(input).toString() == 'NaN') {
      return null;
    }
    var num: any = Number(input);
    var defaultDecimal = null;
    var decimal = null;
    var defaultRoundType = null;
    var roundType = null;
    if (type == 'unit') {
      defaultDecimal = (this.fxUserService.getEntParamById('c_E067') === null || this.fxUserService.getEntParamById('c_E067') === undefined || this.fxUserService.getEntParamById('c_E067') === '') ? 2 : this.fxUserService.getEntParamById('c_E067');
      defaultRoundType = (this.fxUserService.getEntParamById('c_E068') === null || this.fxUserService.getEntParamById('c_E068') === undefined || this.fxUserService.getEntParamById('c_E068') === '') ? 2 : this.fxUserService.getEntParamById('c_E068');
    } else if ((['unitPriceDecimal', 'priceDecimal']).indexOf(type) != -1) {
      let defaultCode = this.fxUserService.userInfo['c_curr'];
      let defaultItems = this.fxUserService.getCurrencyParam([]);
      let defaultItem = defaultItems ? defaultItems.filter(x => x.baacurr == defaultCode)[0] : null;
      if (defaultItem) {
        if (type == 'unitPriceDecimal') {
          defaultDecimal = defaultItem.baa001;
        } else {
          defaultDecimal = defaultItem.baa002;
        }
        defaultRoundType = defaultItem ? (defaultItem.baa003 ? defaultItem.baa003 : 2) : 2;
      } else {
        defaultDecimal = 2;
        defaultRoundType = 2;
      }
    }

    var items = [];
    var defaultCurr;
    if (type == 'unit') {
      if (code == 'default' || !code) { // dwCode传的default或空，取集团参数里设置的单位默认小数位数和舍入方式
        decimal = (this.fxUserService.getEntParamById('c_E067') === null || this.fxUserService.getEntParamById('c_E067') === undefined || this.fxUserService.getEntParamById('c_E067') === '') ? defaultDecimal : this.fxUserService.getEntParamById('c_E067');
        roundType = (this.fxUserService.getEntParamById('c_E068') === null || this.fxUserService.getEntParamById('c_E068') === undefined || this.fxUserService.getEntParamById('c_E068') === '') ? defaultRoundType : this.fxUserService.getEntParamById('c_E068');
      } else {
        items = this.fxUserService.getUnitParam([]);
        defaultCurr = items ? items.filter(x => x.bacunit == code)[0] : null;
        if (!defaultCurr) {
          decimal = defaultDecimal;
          roundType = defaultRoundType;
        } else {
          decimal = defaultCurr.bac002;
          roundType = defaultCurr.bac003 ? defaultCurr.bac003 : defaultRoundType;
        }
      }
    } else if ((['unitPriceDecimal', 'priceDecimal']).indexOf(type) != -1) {
      if (code == 'default' || !code) {// dwCode传的default或空，取缓存里的当前登录组织设置的主币别
        code = this.fxUserService.userInfo['c_curr'];
      }
      items = this.fxUserService.getCurrencyParam([]);
      defaultCurr = items ? items.filter(x => x.baacurr == code)[0] : null;
      if (!defaultCurr) {
        roundType = defaultDecimal;
        decimal = defaultRoundType;
      } else {
        roundType = defaultCurr.baa003 ? defaultCurr.baa003 : defaultRoundType;
        switch (type) {
          case 'unitPriceDecimal':
            decimal = defaultCurr.baa001;
            break;

          case 'priceDecimal':
            decimal = defaultCurr.baa002;
            break;

          default:
            break;
        }
      }
    }

    if (parseFloat(dwDecimal).toString() != 'NaN') {// 使用作业自定义小数位数
      decimal = dwDecimal;  // 使用作业自定义小数位数
      roundType = defaultRoundType;        // 使用作业自定义小数位数默认采用舍入类型为四舍六入五成双
    }

    if (num == 0) {
      return 0;
    }
    if (decimal == null) {
      decimal = defaultDecimal;
    }
    if (roundType == null) {
      roundType = defaultRoundType;
    }
    if (decimal != null) {
      if (roundType == '1') {  // 舍入类型为四舍五入
        num = this.round10(num, -1 * decimal);
      } else if (roundType == '2') {  // 舍入类型为四舍六入五成双
        num = num.toFixed(decimal);
      } else if (roundType == '3') {  // 舍入类型为无条件舍弃
        num = this.floor10(num, -1 * decimal);
      } else if (roundType == '4') {  // 舍入类型为无条件进位
        num = this.ceil10(num, -1 * decimal);
      }
      // 修改整数的时候不会显示0的问题
      if (num.toString().indexOf('.') == -1 && decimal != 0) {
        num += '.';
        for (var i = 0; i < decimal; i++) {
          num += '0';
        }
      }

      // 数值小数点后缺位补0
      if (num && num.toString().indexOf('.') != -1 && decimal != 0) {
        var numLength = Number((num.toString().split('.')[1]).length);
        if (numLength < decimal) {
          for (var i = numLength; i < decimal; i++) {
            num += '0';
          }
        }
      }

    }
    return num;
  }

  addCommas(val): any {
    // 根据`.`作为分隔，将val值转换成一个数组
    var aIntNum = val.toString()
      .split('.');
    // 整数部分
    var iIntPart = aIntNum[0];
    // 小数部分（传的值有小数情况之下）
    var iFlootPart = aIntNum.length > 1 ? '.' + aIntNum[1] : '';
    var rgx = /(\d+)(\d{3})/;
    // 如果整数部分位数大于或等于4
    if (iIntPart.length >= 4) {
      // 根据正则要求，将整数部分用逗号每三位分隔
      while (rgx.test(iIntPart)) {
        iIntPart = iIntPart.replace(rgx, '$1' + ',' + '$2');
      }
    }
    return iIntPart + iFlootPart;
  }

  public clicked = (event: any): any => {
    // event.stopPropagation();
    if (this.params['onClick'] && typeof this.params['onClick'] === 'function') {
      this.params['onClick'](this.value, this);
    }
  };

  refresh(): boolean {
    return false;
  }

}
