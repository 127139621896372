import { FxGridRowEditService } from 'app/implementation/shared/fx-grid/fx-grid-row-edit.service';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';

export class masterObj { //排序对象
    acj002: string = ''; //排序字段编号
    acj002_n1:  string = ''; //排序字段名称
    acj003:  string = ''; //排序方式
    // acj004:  string = ''; //是否默认排序
    acj005:  string = ''; //表编号
    constructor(values?: any) {
      Object.assign(this, values);
    }
  }
  
@Injectable()
export class OrderByMasterService extends FxGridRowEditService { //FxGridRowEditService 
constructor(private fb: FormBuilder, public http: FxDapHttpClient) {
    super(http);
}
// 建立group 
initDetailRowFromGroup(data: any): any {
    const formGroup = this.fb.group({});
    Object.keys(data).forEach(key => {
    if (['acj002'].find(element => element === key)) { //排序字段必填
        formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
    } else {
        formGroup.setControl(key, new FormControl(data[key]));
    }

    });
    return formGroup;
}

//赋值对象初始值，并建立group
public createRowFromGroup(customRowData: any): FormGroup {
    // 设置新增的默认值
    let _rowData = {
    id: this.getNextId()
    };
    _rowData = Object.assign(new masterObj(), _rowData);
    if (customRowData) {
    _rowData = Object.assign(_rowData, customRowData);
    }
    return this.initDetailRowFromGroup(_rowData);
}

//创建表单fromarray
public createFormArray(datas: any): FormArray {
    const formArray: FormArray = this.fb.array(datas.map((item, index) => this.createRowFromGroup(Object.assign({...item}, {id: index}))));
    this.maxId = formArray.length;
    this.initIndex(formArray.value);
    return formArray;
}
}