import { Component, OnDestroy } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'fx-ag-image-message-cell-renderer',
  template: `
    <div style="display: flex;">
      <img style="width: 85px;height: 85px;" [src]="image">
      <div class="y-grid-center">
        <div>
          {{number}} <br>
          {{name}}
        </div>
      </div>
    </div>
  `,
  styles: [
    `.y-grid-center {
      display: grid;
      align-items: center;
    }`
  ]
})
export class FxAgImageMessageCellRendererComponent extends FxAgGridValidationEditorComponent implements ICellRendererAngularComp, OnDestroy {
  private params: ICellRendererParams;
  image: string;
  number: string;
  name: string;
  valueDisplay: any = null;
  currentStyles: any; // 样式
  constructor(private fxUserService: FxUserInfoService) {
    super();
    // console.warn('创建FormCellRenderer:');
  }

  agInit(params: any): any {
    this.params = params;
    this.image = params.data.goodsimage;
    this.number = params.data.goodsnumber;
    this.name = params.data.goodsname;
    this.currentStyles = this.params['style'] ? this.params['style'] : null;

    super.init();
  }

  // 点击事件
  clickFun(): void {
  }

  refresh(): boolean {
    // console.warn('刷新FormCellRenderer:', this.name);
    return false;
  }


  ngOnDestroy(): void {
    // console.warn('销毁FormCellRenderer:', this.name);
  }
}

