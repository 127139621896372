import {Directive, ElementRef, Input, OnDestroy, OnInit, Renderer, TemplateRef, ViewContainerRef} from '@angular/core';
// import {DwActionAuthorizedService} from '@webdpt/framework';
import {ActivatedRoute} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {FieldAuthorizedService} from './field-authorized.service';
import {FormGroup, FormBuilder, Validators, FormControl, AsyncValidatorFn, ValidatorFn, AbstractControl, ValidationErrors, FormArray} from '@angular/forms';

@Directive({
  selector: '[fxFieldAuthorized]',
  host: {
    document: 'fxFieldId'
  }

})
export class FieldAuthorizedDirective implements OnInit {

  @Input() fxAuthorizedId: string; // 作業權限ID
  @Input() fxFieldId: string; // 功能按鈕ID
  @Input() fxDefaultAuthorized: string; // 預設權限 hidden, disabled
  @Input() formGroup: any;
  private hasView = false;
  constructor(private elf: ElementRef,
              private renderer: Renderer,
              private activatedRoute: ActivatedRoute,
              private fieldAuthorizedService: FieldAuthorizedService
              ) {
  }

  async ngOnInit(): Promise<any> {
    // 设置默认状态
    // if (this.fxDefaultAuthorized) {
    //   this.setAttribute(this.fxDefaultAuthorized);
    // }
    const state = await this.getCurrentStateId();
    if (state) {
      //this.actionAuthorizedInit(this.fxAuthorizedId, this.fxFieldId);
      this.actionAuthorizedInit(this.fxAuthorizedId, this.formGroup);
    }
  }

  private actionAuthorizedInit(authorizedId: string, formGroup: any): void {
    if (authorizedId) {
      // this.setAttribute('hidden');
      this.fieldAuthorizedService.setActionAuth(authorizedId);
      this.fieldAuthorizedService.fieldAuthorizedSubject$.subscribe(
        restriction => {
          if(restriction.authorized.length > 0){
            for(var i = 0; i < restriction.authorized.length; i++){
              const fieldNo = restriction.authorized[i].fieldNo;
              // if(formGroup.controls[fieldNo] !== undefined){
              //   this.setAttribute(restriction.style, 'div[fxfieldid="'+ fieldNo +'"]');
              // }
              this.setAttribute(restriction.style, 'div[fxfieldid="'+ fieldNo +'"]');
            };  
          }
        },
        error => {
          console.log(formGroup + ':' + error);
        }
      );
    }
  }

  async getCurrentStateId(): Promise<string> {
    if (this.fxAuthorizedId) {
      return this.fxAuthorizedId;
    }
    let currentSate = '';
    try {
      const routeData = await this.activatedRoute.data.toPromise();
      if (routeData['dwRouteData'] && routeData['dwRouteData'].hasOwnProperty('dwAuthId')) {
        currentSate = routeData['dwRouteData'].dwAuthId;
      }
    } catch (e) {
      console.error(e);
    }
    return currentSate;
  }

  private setAttribute(restriction: string, divfxfieldid: string): void {
    switch (restriction) {  // 按鈕功能限制：allow 允許, hidden 隱藏, disabled 禁用
      case 'allow': // 允許
        this.seAttributeAllow(divfxfieldid);
        break;
      case 'hidden': // 隱藏
        this.seAttributeHidden(true, divfxfieldid);
        break;
      case 'disabled': // 禁用
        this.seAttributeDisabled(true, divfxfieldid);
        this.seAttributeHidden(false, divfxfieldid);
        break;
    }
  }

  // 允許
  private seAttributeAllow(divfxfieldid: string): void {
    this.seAttributeHidden(false, divfxfieldid);
    this.seAttributeDisabled(false, divfxfieldid);
  }

  // 隱藏
  private seAttributeHidden(isHidden: boolean, divfxfieldid: String): void {
    let display = '';
    if (isHidden) {
      display = 'none';
    }
    // this.renderer.setElementStyle(this.elf.nativeElement, 'display', display);
    this.renderer.setElementStyle(this.elf.nativeElement.querySelector(divfxfieldid), 'display', display);
  }

  // 禁用
  private seAttributeDisabled(isDisabled: boolean, divfxfieldid: String): void {
    this.elf.nativeElement.disabled = isDisabled;
    this.renderer.setElementClass(this.elf.nativeElement, 'dw-f-action-disable', isDisabled);
  }
}
