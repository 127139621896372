import {Observable} from 'rxjs';
import { CfcAgGridValidationRendererComponent } from 'app/implementation/shared/fx-grid/cell-component/cfc-validation-renderer';

export class CfcHyperlinkRendererComponent extends CfcAgGridValidationRendererComponent {
  private eGui;
  private rendererParams: any = {};
  private value;

  constructor() { super(); }

  public init(params: any): void {
    // <a (click)="clicked()" title={{value}}>{{value}}</a>
    this.eGui = document.createElement('a');
    this.eGui.title = params.value;
    this.eGui.innerHTML = (params.value === undefined || params.value === null || params.value === NaN) ? '' : params.value;
    this.eGui.addEventListener('click', this.clicked);
    this.value = params.value;
    this.rendererParams = params;
  }

  private  clicked = (event: any): any => {
    event.stopPropagation();
    if (this.rendererParams.onClick && typeof this.rendererParams.onClick === 'function') {
      this.rendererParams.onClick(this.value, this);
    }
  }

  public getGui(): any {
    return this.eGui;
  }

  public destroy(): void {
    this.eGui.removeEventListener('click', this.clicked);
  }
}
