import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fxButtonEditFilter'
})
export class fxButtonEditFilterPipe implements PipeTransform {

  transform(value: Array<any> | any, args?: any): any {
    if (!value || !args) {
      return value;
    } else {
      return value.filter(item => (JSON.stringify(item.id).toLowerCase().includes(args.toLowerCase()))
        || (item.name ? JSON.stringify(item.name).toLowerCase().includes(args.toLowerCase()) : null));
    }
  }

}
