import { Injectable } from '@angular/core';
import { Model, ModelFactory } from '@angular-extensions/model';
import { Observable } from 'rxjs';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxDetailService } from '../../../../shared/pattern/masterdetail/fx-detailservice';

export interface Fxai001S01DetailModel {
    id: number;              // ID
    aaaent: String;      // 企业编号
    aaa001: String;      // 系统分类
    aaa002: String;      // 分类值
    aaalang: String;      // 语系
    aaa002_n1: String;   // 分类值明称
    aaa002_n2: String;
    aaa002_n3: String;
    aaa003: number;      // 选择顺序
    aaa004: String;      // 自定义1
    aaa005: String;      // 自定义2
    aaa006: String;      // 自定义3
    aaa007: String;      // 自定义4
    aaa008: String;      // 自定义5
    aaa009: String;      // 自定义6
    aaa010: String;      // 自定义7
    aaa011: String;      // 自定义8
    aaa012: String;      // 自定义9
    aaa013: String;      // 自定义10
    aaacto: String;
}

 // let ID = 1;    // id 值
 const initialData: Fxai001S01DetailModel[] = [];  // 初始化表格为空
// [{
//      id: 1,           // ID
//      aaaent: 'test',  // 企业编号
//      aaa001: '',      // 系统分类
//      aaa002: '',      // 分类值
//      aaa002_n1: '',   // 分类值明称
//      aaa002_n2: '',
//      aaa002_n3: '',
//      aaa003: '',      // 选择顺序
//      aaa004: '',      // 自定义1
//      aaa005: '',      // 自定义2
//      aaa006: '',      // 自定义3
//      aaa007: '',      // 自定义4
//      aaa008: '',      // 自定义5
// }]



@Injectable()
export class Fxai001S01DetailService {
  private model: Model<Fxai001S01DetailModel[]>;

  static ID = 0; // id 值

  static AAA003 = 0;
  static  MAXINITAAA003 = 0;

  // 定义明细的可观察对象
  detailDataSet$: Observable<Fxai001S01DetailModel[]>;

  constructor(private modelFactory: ModelFactory<Fxai001S01DetailModel[]>, public http: FxDapHttpClient) {

    this.model = this.modelFactory.create(initialData);
     this.detailDataSet$ = this.model.data$;
  }

  public getAaalangOptions(): Observable<any> {
    return Observable.create(observer => {
        this.http.post<any>('A/IACommonService/pub_get_aaa', { param: { 'is_enum': 'Y', 'aaa001': '6' } }).subscribe(response => {
            // console.log('--打印语系服务返回值--', response);
            if (response.success && response.data.length > 0) {
                const resultdata = response.data.map(item => {
                    return {
                        label: item.aaa002_n1,
                        value: item.aaa002,
                        color: item.aaa004
                    };
                });
                observer.next(resultdata);
            } else {
                observer.next([]);
            }
            observer.complete();
        }, error => {
            observer.next([]);
            observer.complete();
        });
    });
  }

  // 调用此initMaster给MasterGrid赋值(一般为从后端查询回来的数据，）
  initDetail(datas: any): void {

    // 初始化选择顺序自动编号
    Fxai001S01DetailService.AAA003 = 0;
    Fxai001S01DetailService.MAXINITAAA003 = 0;


    Fxai001S01DetailService.ID = 0;  // 翻页或从后端查重询之后ID从0开始
    let detailDataSet = this.model.get();
    detailDataSet = [];
    if ( datas !== undefined && datas !== null ) {  // 参数检查
      datas.forEach((data, idx, array) => {
        Fxai001S01DetailService.ID++;         // 自动设定ID值
        data.id = Fxai001S01DetailService.ID;
        detailDataSet.push(data);
      });
    }
    this.model.set(detailDataSet);
  }

  // 选择顺序自动编号
  autoNumber(): number {
    const detailDataSet = this.model.get();
      // aaa003选择顺序自动取值逻辑
      if (detailDataSet.length === 0 && Fxai001S01DetailService.AAA003 === 0 ) {  // 单身新增首次取值, 新增完成之后需要把这个值aaa003清零
        Fxai001S01DetailService.AAA003 ++;   // 即新增的每一笔赋值，从1开始取值
    } else {
      if (detailDataSet.length === 0) {   // 新增状态下每次+1
        Fxai001S01DetailService.AAA003 ++;
      } else  {                         // 编辑状态先取最大值(未保存则每次取的最大值相同，以此判断是否为编辑状态下第一次取值)
        Fxai001S01DetailService.MAXINITAAA003 = detailDataSet[0].aaa003;
        detailDataSet.forEach((item, index) => {
          Fxai001S01DetailService.MAXINITAAA003 = Fxai001S01DetailService.MAXINITAAA003 > item.aaa003 ? Fxai001S01DetailService.MAXINITAAA003 : item.aaa003;
        });
       if (Fxai001S01DetailService.AAA003 < Fxai001S01DetailService.MAXINITAAA003) {   // 编辑状态下第一次取值
        Fxai001S01DetailService.AAA003 = Fxai001S01DetailService.MAXINITAAA003 + 1;
        } else {
          Fxai001S01DetailService.AAA003++;                     // 编辑状态下每次+1
        }
    }
  }

  return Fxai001S01DetailService.AAA003;

  }

  // 清空明细
  clearDetail(): void {
    let detailDataSet = this.model.get();
    detailDataSet = [];
    // 初始化自动选择顺序的值
    Fxai001S01DetailService.AAA003 = 0;
    Fxai001S01DetailService.MAXINITAAA003 = 0;
    Fxai001S01DetailService.ID = 0;
    this.model.set(detailDataSet);
  }

    // 创建一个新的一行数据
  createNewRowData(): Fxai001S01DetailModel {
      const detailDataSet = this.model.get();

      Fxai001S01DetailService.ID++; // 自动给ID

      // 设置新增的默认值
      const defaultData: Fxai001S01DetailModel = {
        id: Fxai001S01DetailService.ID,           // ID
        aaaent: '',    // 企业编号
        aaa001: '',      // 系统分类
        aaa002: '',      // 分类值
        aaalang: 'zh_CN',     // 语系
        aaa002_n1: '',   // 分类值明称
        aaa002_n2: '',
        aaa002_n3: '',
        aaa003: null,   // 选择顺序
        aaa004: '',      // 自定义1
        aaa005: '',      // 自定义2
        aaa006: '',      // 自定义3
        aaa007: '',      // 自定义4
        aaa008: '',      // 自定义5
        aaa009: '',      // 自定义6
        aaa010: '',      // 自定义7
        aaa011: '',      // 自定义8
        aaa012: '',      // 自定义9
        aaa013: '',      // 自定义10
        aaacto: ''
      };

    return defaultData;
}

// 新增
  addDetail(datas: any, detailList: Fxai001S01DetailModel[]): void {
    const detailDataSet = this.model.get();

    if ( detailList !== undefined) {  // 返回参数检查
      detailList.forEach((data, idx, array) => {
          // 加入Model并刷新Grid
          let detaildata = datas.find(detail => detail.aaa002 === data.aaa002);
          if (detaildata !== undefined) {
            data.id = detaildata.id ;
            detaildata = Object.assign(detaildata, data);
            detailDataSet.push(detaildata);
          } else {  // 后台返回的数据比Ag-grid 要多,可能是其他人新增的
            Fxai001S01DetailService.ID++; // 自动给ID
            data.id = Fxai001S01DetailService.ID;
            detailDataSet.push(data);
          }
     });
    }

    // 初始化选择顺序自动编号
    Fxai001S01DetailService.AAA003 = 0;
    Fxai001S01DetailService.MAXINITAAA003 = 0;

    this.model.set(detailDataSet);
  }

   // 取消
   cancel(): void {
    const detailDataSet = this.model.get();
    // 初始化选择顺序自动编号
    Fxai001S01DetailService.AAA003 = 0;
    Fxai001S01DetailService.MAXINITAAA003 = 0;
    this.model.set(detailDataSet);
  }

  checkUnique_aaa002lang(datas: any, aaa002: string, aaalang: string): boolean {
    // 验证页面数据中在同aaa002下是否已包含了aaalang 的数据
    if ( datas.length > 0 ) {  // 为空一定不会重复，可不做重复性检查
       const filterdatas =  datas.filter(item => item.data.aaa002 === aaa002 && item.data.aaalang === aaalang);
       // 若大于1表示ag-grid有重复的值
       if (filterdatas.length > 1 ) {
            return false;
       }
    }
    return true;
  }

  checkUnique_aaa003(datas: any, aaa003: string, aaalang: string): boolean {
    // 验证页面数据中在同aaalang下是否已包含了aaa003的数据
    if ( datas.length > 0 ) {  // 为空一定不会重复，可不做重复性检查
       const filterdatas =  datas.filter(item => item.data.aaa003 == aaa003 && item.data.aaalang === aaalang);
       // 若大于1表示ag-grid有重复的值
       if (filterdatas.length > 1 ) {
            return false;
       }
    }
    return true;
  }

  // 从后端获取明细的请求
  public getDetailInfo(detailParam: any): Observable<any> {
    return this.http.post('A/IFxai001Service/getDetailInfo', {'param': detailParam});
  }


}

