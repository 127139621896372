import {Component, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {FxAgGridValidationEditorComponent} from '../../../ag-grid/fx-ag-grid-validation-editor';
import {ICellEditorAngularComp} from 'ag-grid-angular/src/interfaces';
import {CandyDate, DwDatePickerComponent} from 'ng-quicksilver';
import {ICellEditorParams} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FxAgGridEventHandlerService} from '../../../ag-grid/fx-ag-grid-event-handler.service';
import {FxUserInfoService} from '../../../../auth/fx-auth/fx-user-info.service';
import {DatePipe} from '@angular/common';
import * as _ from 'lodash';
import {el} from '@angular/platform-browser/testing/src/browser_util';

@Component({
  selector: 'app-cfc-date-editor',
  templateUrl: './cfc-date-editor.component.html',
  styleUrls: ['./cfc-date-editor.component.less']
})
export class CfcDateEditorComponent extends FxAgGridValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {
  formGroup: FormGroup;
  disabledExpr: Function;
  key;
  columnName: string;
  private rowId: number;

  dateFormat = this.fxUserService.getEntParamById('c_E031');
  dwShowToday = true;
  // colId;
  @ViewChild('cellInput') cellInput: DwDatePickerComponent;  // 获取子组件元素的实例引用 Added By David Lee 18Feb2019
  params: any;  // 存放cellEditorParams   Added By David Lee 18Feb2019
  // valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  flag = true;

  message: string;
  is_ShowTime: false;
  dwDisabledDate; //禁用日期范围
  constructor(private fb: FormBuilder, private fxUserService: FxUserInfoService, private datePipe: DatePipe) {
    super();
  }
  onKeyDown(event: any): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
      || event.keyCode === 39 || event.keyCode === 40) {
      event.stopPropagation();
    }
  }
  agInit(params: any): void {
    this.columnName = params.column.colDef.headerName;
    this.key = params.column.colId;
    this.rowId = params.node.id;
    const rowFromGroup = params.context.serviceObj.rowCachedFormGroup;
    this.formGroup = rowFromGroup;
    this.disabledExpr = params.disabledExpr || ((params: any) => false);
    this.dwShowToday = (params.dwShowToday === false) ? false : true;

    this.formControl = _.cloneDeep(this.formGroup.get(this.key));
    this.params = params;  // 把cellEditorParams参数赋给params Added By David Lee 18Feb2019

    this.dwDisabledDate = params.dwDisabledDate || '';
    this.is_ShowTime = params.is_ShowTime || false;
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    this.dateFormat = params.dateFormat ? params.dateFormat : (this.dateFormat ? this.dateFormat : 'yyyy/MM/dd');
    let currentDate = null;
    const value = this.formControl.value;
    if (value !== 'Invalid Date' && value) {
      currentDate = new Date(this.datePipe.transform(value, this.dateFormat));
    } else {
      currentDate = null;
    }

    // 监听赋值 Add David lee 25 Feb 2019
    // this.valueSetSubscription = this.eventHandler.valueSet.subscribe(event => {
    //   if ( (event.colId === this.colId) && (event.gridId === params.context.id) ) {
    //     this.formControl.setValue(event.value);
    //     this.message = event.message;
    //     this.formControl.markAsDirty();
    //   }
    // });

    super.init();
  }

  getValue(): any {
    if (!this.formControl.value || this.formControl.value === 'Invalid Date') {
      return null;
    } else {
      // return  moment(this.formControl.value, this.dateFormat).format(this.dateFormat);
      const formValue = this.datePipe.transform(this.formControl.value, this.dateFormat);
      this.formControl.setValue(formValue);
      return formValue;
    }
  }

  afterGuiAttached(): void {
    // 这里是 row 在外部更新后，重新更新editor
    const valueChange = (value: string): void => {
      // if ( this.params.onChange !== undefined) {
      //  this.params.onChange(value);  // Added by David Lee 值发生变化时回调
      // }
      // 通过订阅消息回传onChangeField事件

      if (!this.formControl.value || this.formControl.value === 'Invalid Date') {
        value = null;
        this.formControl.setValue(value);
      }
      this.formControl.markAsDirty({onlySelf: true});

    };
    this.cellInput.registerOnChange( valueChange) ;  // Added by David Lee 注册OnChange事件


    this.flag = true;
    this.cellInput.onOpenChange = (): void => {
      if (this.params.onOpenChange) {
        if ( this.flag === true) {
          this.params.onBeforeField({
            gridId: '',
            value: this.formControl.value,
            rowIndex: '',
            id: '',
            colId: '',
            controlName: ''
          });
          this.flag = false;
        }
      }
    };

    this.cellInput.onValueChange = (value: CandyDate): void => {
      const date = (value && value.getDate()) ? value.nativeDate : null;
      const rawValue = this.datePipe.transform(date, this.dateFormat);
      this.formGroup.get(this.key).patchValue(rawValue);
      this.formControl.patchValue(rawValue);

      // if (this.eventHandler) {
      //   this.eventHandler.onChangeField({
      //     gridId: '',
      //     value: value ? value.nativeDate : null,
      //     rowIndex: '',
      //     id: '',
      //     colId: '',
      //     controlName: ''
      //   });
      // }
      if (this.is_ShowTime === false) {
        this.cellInput.closeOverlay();
      }
      if ( this.params.onChange !== undefined) {
       this.params.onChange(rawValue);  // Added by David Lee 值发生变化时回调
      }
      // if (this.eventHandler) {
      //   this.eventHandler.onAfterField({
      //     gridId: this.params.context.id,
      //     // value: this.formControl.value,
      //     value: value ? value.nativeDate : null,
      //     rowIndex: this.params.node.rowIndex,
      //     id: this.params.node.id,
      //     colId: this.colId,
      //     controlName: ''
      //   });
      // }
    };


  }

  focusIn(): void {
    if (this.params.onFocus) {
      this.params.onFocus({
        gridId: '',
        value: this.formControl.value,
        rowIndex: '',
        id: '',
        colId: '',
        controlName: ''
      });
    }
  }

  focusOut(): void {
    if (this.params.onBlur) {
      this.params.onBlur({
        gridId: '',
        value: this.formControl.value,
        rowIndex: '',
        id: this.rowId,
        colId: '',
        controlName: ''
      });
    }
  }

  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    // this.validateForm.removeControl('');
    // 当销毁时取消所有订阅
    // if (this.valueSetSubscription) {
    //   this.valueSetSubscription.unsubscribe();
    // }
  }
}
