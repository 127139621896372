import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { IAgGridEditorEvent } from 'app/implementation/shared/ag-grid/fx-ag-grid-event-handler.service';
import { DwModalService } from 'ng-quicksilver';
import { DwRoutingMessageService, DwNewRouteKeyService } from '@webdpt/framework';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { TranslateService } from '@ngx-translate/core';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import {
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ColumnApi, Column, RowNode, GridOptions, GridApi } from 'ag-grid-community';
import { MasterObj } from './search-condition-01.service';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';

@Component({
  selector: 'app-search-condition-01',
  templateUrl: './search-condition-01.component.html',
  styleUrls: ['./search-condition-01.component.less']
})
export class SearchCondition01Component implements OnInit {
  cag005: string;
  [x: string]: any;
  public searchLoading: boolean; // 是否顯示加載中
  public myform: FormGroup; // 表单控件
  //public master: MasterObj; // 单头
  public gridOptions: GridOptions;
  public infoOption = {}; // 详情显示异动资讯
  public gcaggoods: string = '';//商品编号
  private rowNodeId: string; // 记录当前编辑的行的rowNodeId
  public dropDownData: any; // 单头开窗配置信息
  public master;
  @Input() searchvalue: any;
  @Input() param_daemail: any;
  @Input() param_daesmtel: any;
  @Input() param_dae035: any;
  public gcag005list = [
  ];
  public gcag005;
  public dh = 0;
  public dh2 = "";

  availableFields: Array<string> = [];

  // grid ==  bgein  ==
  private gridApi: GridApi;
  private gridColumnApi: ColumnApi;
  public frameworkComponents: any; // 组件渲染
  public editType = 'fullRow'; // 是否启用行修改
  public defaultColDef = {
  };
  public columnDefs = []; // 列定义
  public components = {}; // 渲染器函数
  // grid  ==  end  ==
  // grid  ===  end  ==

  constructor(public route: ActivatedRoute,
    public router: DwNewRouteKeyService,
    public dwModalService: DwModalService,
    public dwMessage: DwRoutingMessageService,
    public http: FxDapHttpClient,
    private translateService: TranslateService,
    public fxModalService: FxModalService,
    public userinfo: FxUserInfoService
  ) { // 取得路由参数
    this.route.queryParamMap.subscribe( // 路由变化执行
      params => {
        this.gcaggoods = params.get('caggoods') || '';
        this.infoOption = {
          tableName: 'cag_f',                // 类型：String  必有字段  备注：表编号
          keyValue: {                // 类型：Object  必有字段  备注：主键信息
            caggoods: this.gcaggoods               // 类型：String  必有字段  备注：无
          }
        };
      }
    );
  }

  ngOnInit(): void {
    this.variableInit(); // 全局变量初始化
    this.gridInit(); // grid初始化
    this.onBeforeGetData();
    this.myform.get('searchvalue').setValue(this.searchvalue);
    // if (this.gState == 'add') { // 新增状态
    //   this.stateControl();
    //   this.onAfterGetData();
    // } else {
    //   this.getData();
    // }
  }

  // 变量初始化
  variableInit(): void {
    // this.gdetaileditable = this.gState !== 'browse'; // 单身是否可修改
    this.dataGroup();
    this.master = new MasterObj({});

    const masterConObj = {};
    Object.keys(this.master).forEach(key => {
      if (this.master[key] instanceof Array) {
        // masterConObj[key] = new FormGroup({});
      } else {
        masterConObj[key] = new FormControl(this.master[key]);
      }
    });
    this.myform = new FormGroup(masterConObj);
    this.searchvalueChange('');
  }

  createDocumentFormcontrol(): void {
  }

  // grid ===  begin   ==
  // grid 初始化
  /**
   * gridInit
   */
  public gridInit(): void {

  }

  // grid多语系
  localTextFunc = (key: string): any => {// grid多语系
    return this.translateService.instant(key);
  };

  // 状态内涵外显
  public aaddtypeCellRenderer(xparam: any): any {

  }

  // grid初始化后事件
  onGridReady(params: any): void {

  }


  /**
   * 行编辑模式，在当前行进入编辑状态之前会都会执行
   * @param {JSON} params
   * @memberof FxGridComponent
   */
  onRowEditingStarted(params: any): void {
    this.rowNodeId = params.node.id; // 当前行进行编辑状态时记录rowNodeId
  }

  /**
   * 行编辑模式，退出当前行编辑状态或离开当前行时，在离开之后会执行，并且每个单元格的CellEditingStarted/CellEditingStop将被激发，此事件仅对应于整行编辑
   * @param {JSON} params
   * @memberof FxGridComponent
   */
  onRowEditingStopped(params: any): void {
    console.log('ON ROW ' + params.rowIndex + ' AFTER EDIT MODE');
  }


  selectionChanged(params: any): void {
    console.log("选择行" + this.gridApi.getSelectedRows());
    console.log("选择行", params);

  }
  public clickRowId: Number;
  onClickRow(params: any): void {
    console.log('点击行', params);
    this.clickRowId = params.rowIndex;
  }

  upRow(): void {
    if (!this.clickRowId) return;
  }
  downRow(): void {
    if (!this.clickRowId) return;
  }

  // 新增行
  addDetail(): void {
    this.gridApi.stopEditing();
    console.log('新增行', this.master.cah_f_list);
    //  this.gridApi.updateRowData({ add: [new DetailObj({})] });
  }


  /**
   * 刪除明細
   *
   * @param {number} id
   *
   */
  public detailDelete(id: any): void {
    /**
     * 按 rowID 删除数据
     * 注意: 删除数据用 setRowData 和 updateRowData 都可以
     * 但 setRowData 适合在全部数据更新时使用，比较慢
     * 这里使用 updateRowData
     */
    const selectedRow = this.gridApi.getRowNode(id);
    this.gridApi.updateRowData({ remove: [selectedRow.data] });
  }

  // grid ===  end  ==
  // 获取详情数据
  getData(): void {
    this.http.post('C/IFxed006Service/getDetailInfo', {
      param: { caggoods: this.gcaggoods }
    }).subscribe(response => {
      console.log('order-list response:', response);
      this.myform.patchValue(response.data); // 更新master表单数据
      this.master = Object.assign(new MasterObj({}), response.data);
      // this.master.cah_f_list = response.data.cah_f_list.map(item => new DetailObj(item));

      this.stateControl();
      this.onAfterGetData();

    });
  }

  // 根据页面状态设置页面控件
  stateControl(): void {
    this.createDocumentFormcontrol();

    if (this.gridColumnApi) {
      this.gridColumnApi.setColumnVisible('operation', this.gdetaileditable); // 动态设置操作列显示隐藏
    }
    if (this.gState === 'browse') {
      Object.keys(this.myform.value).forEach(key => {
        this.myform.get(key).disable();
      });
      // 获取list值
      this.gcag005 = this.gcag005list.filter(obj => obj.value === this.myform.get('cag005').value)[0].label;

    } else if (this.gState === 'edit') {
      Object.keys(this.myform.value).forEach(key => {
        this.myform.get(key).enable();
      });
      this.myform.get('caggoods').disable();
    } else if (this.gState === 'add') {
      Object.keys(this.myform.value).forEach(key => {
        this.myform.get(key).enable();
      });
      //this.myform.get('caggoods').disable();
      this.myform.patchValue(new MasterObj({})); // 更新master表单数据
      this.master = new MasterObj({});
      this.master.cah_f_list = [];
      this.myform.get('caggoods').setValidators([]);
    }
  }

  // 新增标准字段 比如建立组织，部门等管理字段
  onClickAddStandardField(): void {
  }

  // 新增常用字段 比如单号，日期等等
  onClickAddCommonField(): void {
  }

  onBeforeGetData(): void {
    this.searchLoading = true; // 是否顯示加載中
  }

  onAfterGetData(): void {
    this.searchLoading = false; // 是否顯示加載中
  }

  // 页面开窗 ===  begin ==

  // 用户开窗
  public openazb012(xEvent): void {
    const openModelParam = {
      modalID: 'qry_abj001',     // (必传) 开窗的编号
      postParam: [{

      }],             // (必传) 查询参数，注意是数组
      selected: [],
      searchType: 'q',
      mutilSelected: true,
    };
    if (xEvent.value) {
      openModelParam.selected = (xEvent.value instanceof Array) ? xEvent.value : [xEvent.value];
    }
    this.fxModalService.searchFx(openModelParam).subscribe((data) => {
      this.myform.get('azb012').setValue(data[0].abj001);
    });
  }

  // 页面开窗 ===   end  ==
  // 页面逻辑处理 ===  begin  ==



  // 页面逻辑处理 ===   end  ==

  // 功能按钮 === begin  ==
  // 回列表

  onClickCancel(): void {
    this.modal.triggerCancel();
  }

  OK(): void {
  }


  // 新增
  create(): void {
  }

  // 修改
  modify(): void {
  }

  /**
   * 取消
   */
  public cancel(): void {
  }
  // 保存
  public save(xstate: any): void {
  }

  // 保存前
  onBeforeSaveOrder(): void {
    console.log('保存前');
  }

  // 保存后
  onAfterSaveOrder(result: any, xstate: string, param: any): void {
  }



  // 重新加载页面数据
  dataGroup(): void {
  }

  // 重新加载页面数据
  dataReload(): void {
  }

  // 页面重载
  pageReload(xId: any, xState: any): void {
    const navigationExtras: NavigationExtras = {
      relativeTo: this.route, // 相對路徑導頁
      queryParams: { 'caggoods': xId, xState: xState }
    };
    this.router.navigate(['../search-condition-01'], navigationExtras);
    // location.reload();//F5
  }


   // 变更人开窗
  // tslint:disable-next-line: typedef
  public opensearchvalue(xEvent): void {
    // 字段属性检核逻辑 带回名称、字段类型、长度
    const openModelParam = {
      modalID: 'qry_bap001',     // (必传) 开窗的编号
      postParam: [{
        baplorg: this.userinfo.userInfo['c_org']
      }],             // (必传) 查询参数，注意是数组
      selected: [{ searchvalue: xEvent.value }],
      searchType: 'a'
    };
    this.fxModalService.searchFx(openModelParam).subscribe((data) => {
      this.myform.get('searchvalue').setValue(data[0].bap001);
      this.myform.get('searchvalue_n1').setValue(data[0].bap001_n1);
      this.myform.get('dcgcdept').setValue(data[0].bapldept);
      this.myform.get('dcgcdept_n1').setValue(data[0].bapldept_n1);
    },
      (error) => {
        this.cleansearchvalue(xEvent);
      }
    );
  }

  // 变更人检核
  public onChangesearchvalue(xEvent): void {
    this.myform.get('searchvalue').setValue(xEvent.id);
    this.myform.get('searchvalue_n1').setValue(xEvent.name);
    this.myform.get('dcgcdept').setValue(xEvent.bapldept);
    this.myform.get('dcgcdept_n1').setValue(xEvent.bapldept_n1);
  }

  // 清空变更人相关字段
  // tslint:disable-next-line: typedef
  cleansearchvalue(xEvent): void {
    this.myform.get('searchvalue').setValue('');
    this.myform.get('searchvalue_n1').setValue('');
    this.myform.get('dcgcdept').setValue('');
    this.myform.get('dcgcdept_n1').setValue('');
  }

  public searchvalueChange(value): void {
    this.dropDownData = {
      "param": {
        'modalID': 'qry_bap001',     //  (必传) 开窗的编号
        'postParam': [{
          baplorg: this.userinfo.userInfo['c_org']
        }],             //  (必传) 查询参数，注意是数组
        'selected': [],
        'searchType': 'all'
      },
      "checkParam": {
        'modalID': 'qry_bap001',     //  (必传) 开窗的编号
        'postParam': [{
          baplorg: this.userinfo.userInfo['c_org']
        }, {
          'bap001': value ? value : ""
        }],             //  (必传) 查询参数，注意是数组
        'searchType': 'c'
      },
      "id": "bap001",
      "name": "bap001_n1"
    }
  }

}
