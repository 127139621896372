/**
 * 异动资讯封装
 * 入参：infoOption {tableName： 'aac_f', keyValue: {aacno: '***001'}} //表名 主键
 */
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
@Component({
  selector: 'app-operation-info',
  template: `<div class="fx_collapse_head">
                <a class="fx_leftTitle"  (click)="showInfo = !showInfo">
                  <i class="anticon" dwTitle="{{ 'com-异动资讯' | translate }}" dw-tooltip [class.anticon-down]="showInfo" [class.anticon-right]="!showInfo"></i>{{ 'com-异动资讯' | translate }}
                </a>
                  <a class="fx_rightMore" 
                      dwType="primary"
                      dw-popover
                      dwTitle="{{ 'com-更多日志' | translate }}"
                      [(dwVisible)]="visible"
                      (dwVisibleChange)="clickMoreLog()"
                      dwTrigger="click"
                      dwPlacement="leftBottom"
                      [dwContent]="contentTemplate">
                      {{ 'com-更多日志' | translate }}
                    </a>
                    <ng-template #contentTemplate>
                      <div style='height:350px;overflow:scroll;'>
                            <dw-collapse *ngFor="let item of morelog_datas; index as index">
                              <dw-collapse-panel dwHeader="{{item.operation}}" [dwActive]="false">
                              <div *ngFor="let obj of item.update_list; index as index">
                                <div class="fx_list1">
                                  <span>{{obj}}</span>
                                </div>
                              </div>
                              </dw-collapse-panel>
                            </dw-collapse>
                      </div>
                    </ng-template>
              </div>
              <div dw-row [dwType]="'flex'" [dwJustify]="'start'" *ngIf="showInfo" [dwGutter]="5" class="fx_collapse">
                <div class="fx_listInfo" *ngIf="_infoList.length > 0">
                  <div dw-col dwXs="12" dwMd="8" dwLg="6" dwXl="6" *ngFor="let item of _infoList; index as index">
                    <div class="fx_list"><span>{{item.field_name}}:</span> <p>{{item.field_value}}</p></div>
                  </div>
                </div>
              </div>`,
  styles: [`
    .fx_collapse_head {
      display:flex;
      justify-content: space-between;
      padding: 5px 7px;
      background: #f5f5f5;
    }
    .fx_collapse_head .fx_listInfo {
      margin: 8px 2px;
    }
    .fx_collapse_head .fx_leftTitle {
      font-size: 13px;
      color: rgb(0,0,.85);
    }
    .fx_collapse_head .fx_leftTitle i {
      margin-right: 5px;
    }
    .fx_collapse_head .fx_rightMore {
      font-size:12px;
      color: #1890ff;
    }
    .fx_list {
      display: flex;
      line-height: 20px;
    }
    .fx_list p {
      flex: 1;
      padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .fx_list span {
      display: inline-block;
      min-width: 90px;
      text-align: right;
      font-weight: 600;
    }
    .fx_list1 span {
      display: inline-block;
      min-width: 90px;
      text-align: left;
      font-size:10px
    }
  `]
})
export class OperationInfoComponent implements OnInit {

  public _infoOption: any;
  public _infoList: any;
  @Input() headers: any;
  @Input() set infoOption(infoOption: any) {
    this._infoOption = infoOption;
  }
  get infoOption(): any { return this._infoOption; }
  public morelog_datas: any;
  showInfo = false;

  constructor(
    public http: FxDapHttpClient) {
  }

  ngOnInit(): void {
    this.dataInit(); //获取初始数据
  }
  visible: boolean;

  clickMe(): void {
    this.visible = false;
  }

  change(value: boolean): void {
    console.log(value);
  }

  /**
   * dataInit
   */
  public dataInit(): void {
    // console.log('参数不全', this._infoOption);
    if (!this._infoOption.tableName || !this._infoOption.keyValue) {
      console.log('参数不全');
      return;
    }
    this.http.post<any>('A/IACommonService/getDynamicInfo', { param: this._infoOption }, this.headers).subscribe(result => {
      console.log('异动资讯', result);
      this._infoList = result.data;
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['infoOption'] !== undefined) {
      this.dataInit();
    }
  }

  public clickMoreLog(): void {
    console.log('更多日志处理', this.infoOption);
    // A/IFxai036Service/getOperationLog 获取更多操作信息（公共服务）
    // var param = {
    //   ace001:'fxed006',
    //   acedocno:'00000137'
    // }
    var param = {
      ace001: this.infoOption.ace001,
      acedocno: this.infoOption.acedocno
    }
    this.http.post<any>('A/IFxai036Service/getOperationLog', { param: this._infoOption }, this.headers).subscribe(result => {
      console.log('更多日志处理', result);
      this.morelog_datas = result.data;
      // this._infoList = result.data;
    });
  }

}

// export class infoOptionObj {
//     tableName: string = 'aac_f';
//     keyValue: {aacno: "20190219105306245"    };
//     constructor(values: any) {
//       Object.assign(this, values);
//     }
// }
