/**
 * 查询条件封装
 * 入参：programId 作业编号 
 * isAutoSearch 是否自动查询 
 * isProgram 是否作业调用
 * onSearch 查询事件响应
 * isAutoHeight 是否自动布局
 */
import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, Inject } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
// import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { isString, isDate, isArray } from 'util';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { DatePipe } from '@angular/common';
import { DwRoutingMessageService, APP_DATE_FORMAT, APP_DATE_TIME_FORMAT } from '@webdpt/framework';
import { DwModalService } from 'ng-quicksilver';
import { saveCondComponent } from './modal/save-comcond';
import { editCondComponent } from './modal/edit-comcond';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as moment from 'moment';
import { FxDapHttpClient } from '../fx-http-client';
import { calDate } from 'app/implementation/shared/services/common-method';
import * as _ from 'lodash';
import { SearchCondition01Component } from './search-condition-01/search-condition-01.component';
import { SetOrderByComponent } from './modal/set-orderby';
import { FormArray } from '@angular/forms';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';

@Component({
  selector: 'app-search-more',
  templateUrl: './search-more.component.html',
  styleUrls: ['./search-more.component.less'],
  animations: [
    trigger('childAnimation', [
      state('block', style({ opacity: 1, transform: 'translateY(0px)' })),
      state('none', style({ transform: 'translateY(10px)', opacity: 0 })),
      transition('none <=> block', animate(500)),

      // transition(':enter', [
      //   style({ opacity: 0, transform: 'translateX(10px)' }),
      //   animate('5s', style({ opacity: 1, transform: 'translateX(0)'})),
      // ]),
      // transition(':leave', [
      //   style({ opacity: 0, transform: 'translateX(0)'}),
      //   animate('5s', style({ opacity: 0, transform: 'translateX(10px)' }))
      // ])
    ]),
  ]
})
export class SearchMoreComponent implements OnInit, AfterViewInit {
  public _programId: string;
  @Input() set programId(programId: string) {
    this._programId = (programId && programId.trim());
  }
  @Input() isAutoSearch = true;

  get programId(): string {
    return this._programId;
  }
  @Input() isProgram = true;
  @Input() isAutoHeight = true;

  @Output() onSearch = new EventEmitter<boolean>();
  @Output() openModal = new EventEmitter<boolean>();
  @Output() onReady = new EventEmitter<any>();
  @Output() OptionList = new EventEmitter<boolean>();
  public nowOpenIndex = 0;
  // 進階查詢開合 true:關閉, false:打開
  public isCollapse = true;
  public c_S004 = '0';
  public c_P119 = 'Y';
  // 查询条件  ==  begin ==
  public searchParam = {
    pageable: true,
    // pageSize: 10,
    // pageNumber: 1,
    glikesel: '',
    gcomConds: '',
    orderfields: [],
    condition: {
      joinOperator: 'AND',
      items: []
    }
  }; //查询条件结果
  public gcomCondsList = []; //常用查询条件
  public searchlableList = []; //更多查询label
  // public searchCondList = []; //更多查询条件
  public searchCondList = {
    'all': [], 'select': [], 'date': [], 'month': [], 'self': []
  };//更多查询条件 //根据字段类型不同显示不同内容
  public searchVal = []; //更多查询value
  public searchValNum = 3; //更多查询个数
  public searchValObj = { table_no: '', field_no: '', condition: 'EQUAL', value: '', lable_type: '3', more01: [], cond_type: 'all', aae005: 'VARCHAR2', openKey: '', param_list: null, aal005: '', aal008: '' };
  // public gcomCondsList = [
  //   { text: '常用条件1', value: '1' },
  //   { text: '常用条件2', value: '2' }
  // ];
  //查询条件字段
  //lable_type 1开窗 2下拉 3输入 4日期 5日期+时间 7年月 8日期区间
  // 查询条件  ==  end  ==
  public heightEle; //页面高度布局ele
  public initHeight; //页面布局初始高度
  public keyWords = '请输入关键字'; //关键字提示信息
  public orderByListInit = []; //作业注册配置排序列表
  public orderByList = []; //已选择排序列表
  public dwDateMonthFormat;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public dwMessage: DwRoutingMessageService,
    public http: FxDapHttpClient,
    private userService: FxUserInfoService,
    public fxModalService: FxModalService, // 分销自定提醒框
    public dwModalService: DwModalService,
    private datePipe: DatePipe,
    // public doc: DwDocument,
    private translateService: TranslateService,
    @Inject(APP_DATE_FORMAT) public dwDateFormat: string, // 自定义日期格式变量
    @Inject(APP_DATE_TIME_FORMAT) public dwDateTimeFormat: string, // 自定义日期格式变量
  ) {
    this.dwDateMonthFormat = dwDateFormat.substring(0, 7);
  }

  ngOnInit(): void {
    this.getProgramParam();
    this.pageInit(); //页面初始化
    this.dataInit(); //获取初始数据
    // this.getStorageCondition();
    // this.searchData();
    var formEles = document.getElementsByTagName("form");
    if (formEles.length > 0) { //页面存在grid
      var formEle = <HTMLElement>formEles.item(0);
      if (formEle.outerHTML.indexOf('height: calc(100vh - ') > -1 || formEle.outerHTML.indexOf('height:calc(100vh - ') > -1) { //页面高度计算在form上
        this.heightEle = formEle;
        this.initHeight = this.heightEle.style.height;
      }
    }
    var gridEles = document.getElementsByTagName("ag-grid-angular");
    if (gridEles.length > 0) { //页面存在grid
      var gridEle = <HTMLElement>gridEles.item(0);
      if (gridEle.outerHTML.indexOf('height: calc(100vh - ') > -1 || gridEle.outerHTML.indexOf('height:calc(100vh - ') > -1) { //页面高度计算在grid上
        this.heightEle = gridEle;
        this.initHeight = this.heightEle.style.height;
      }
    }
  }

  // 获取是否自动查询列表作业参数
  async getProgramParam(): Promise<any> {
    const programParam = await this.userService.getProgramParamById$(this._programId).toPromise();
    this.c_P119 = (programParam && programParam.has('c_P119')) ? programParam.get('c_P119') : '';
    // 如果作业参数有设定则优先使用作业参数，否则使用作业代码里面的，1，如果是首页跳转的，2.如果有默认查询条件，3.详情页返回的（根据是否有缓存判断）则默认都查询
    const homeIsAutoSearchParam = sessionStorage.getItem('homeIsAutoSearchParam' + this._programId);
    const detailRetrunParam = sessionStorage.getItem(this._programId + '_searchMore');
    var isGoBack;
    this.route.queryParamMap.subscribe( // 路由变化执行
      params => {
        isGoBack = params.get('isGoBack') == 'true' ? true : false;
      }
    );
    if (homeIsAutoSearchParam === 'Y') {// 首页跳转的
      this.isAutoSearch = true;
      sessionStorage.removeItem('homeIsAutoSearchParam' + this._programId);
    } else if (detailRetrunParam && isGoBack) {// 详情页返回的
      this.isAutoSearch = true;
    } else {
      if (this.c_P119 === 'Y') {
        this.isAutoSearch = true;
      } else if (this.c_P119 === 'N') {
        this.isAutoSearch = false;
      }
    }
  }

  /**
   * name
   */
  public pageInit(): void {
    this.c_S004 = this.userService.getSysParamById('c_S004') || '0';


    // 1.下拉，=(in)，不等于，空白，非空
    // 2.开窗，=(in)，不等于，包含，不包含，空白，非空
    // 3.1输入，字符类型不等于，包含，不包含，空白，非空,开头，结尾
    // 3.2输入，数值类型：=，>=，<=，>，<，不等于，区间，空白，非空
    // => ：=(in)，>=，<=，>，<，包含，不包含，区间, 空白，非空,开头，结尾
    // 4.日期，=，>=，<=，>，<，不等于，区间，空白，非空
    // 5.日期时间，=，>=，<=，>，<，不等于，区间，空白，非空
    // =>区间，当天，三天内，一周内，一月内
    this.searchCondList = {
      'all': [
        { label: '=', value: 'EQUAL' },
        { label: '>', value: 'GreaterThan' },
        { label: '>=', value: 'GreaterThanOrEqualTo' },
        { label: '<', value: 'LessThan' },
        { label: '<=', value: 'LessThanOrEqualTo' },
        { label: '不等于', value: 'NotEquals' },
        { label: '包含', value: 'LIKE' },
        { label: '不包含', value: 'NOT_LIKE' },
        { label: '区间', value: 'BETWEEN' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' },
        { label: '开头', value: 'StartWith' },
        { label: '结尾', value: 'EndWith' }
      ],
      'select': [
        { label: '=', value: 'EQUAL' },
        { label: '不等于', value: 'NotEquals' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' }
      ],
      'date': [
        { label: '=', value: 'EQUAL' },
        { label: '>', value: 'GreaterThan' },
        { label: '>=', value: 'GreaterThanOrEqualTo' },
        { label: '<', value: 'LessThan' },
        { label: '<=', value: 'LessThanOrEqualTo' },
        { label: '不等于', value: 'NotEquals' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' },
        { label: '区间', value: 'BETWEEN' },
        { label: '当天', value: 'TODAY' },
        { label: '三天内', value: 'THREE' },
        { label: '一周内', value: 'WEEK' },
        { label: '一月内', value: 'MONTH' },
        { label: '当月', value: 'NOW_MONTH' },
        { label: '上月', value: 'LAST_MONTH' },
        { label: '当年', value: 'NOW_YEAR' },
        { label: '去年', value: 'LAST_YEAR' }
      ],
      'month': [
        { label: '=', value: 'EQUAL' },
        { label: '>', value: 'GreaterThan' },
        { label: '>=', value: 'GreaterThanOrEqualTo' },
        { label: '<', value: 'LessThan' },
        { label: '<=', value: 'LessThanOrEqualTo' },
        { label: '不等于', value: 'NotEquals' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' },
        { label: '区间', value: 'BETWEEN' },
      ],
      'self': [
        { label: '=', value: 'EQUAL' },
        { label: '>', value: 'GreaterThan' },
        { label: '>=', value: 'GreaterThanOrEqualTo' },
        { label: '<', value: 'LessThan' },
        { label: '<=', value: 'LessThanOrEqualTo' },
        { label: '不等于', value: 'NotEquals' },
        { label: '包含', value: 'LIKE' },
        { label: '不包含', value: 'NOT_LIKE' },
        { label: '区间', value: 'BETWEEN' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' },
        { label: '开头', value: 'StartWith' },
        { label: '结尾', value: 'EndWith' },

        { label: '当天', value: 'TODAY' },
        { label: '三天内', value: 'THREE' },
        { label: '一周内', value: 'WEEK' },
        { label: '一月内', value: 'MONTH' },
        { label: '当月', value: 'NOW_MONTH' },
        { label: '上月', value: 'LAST_MONTH' },
        { label: '当年', value: 'NOW_YEAR' },
        { label: '去年', value: 'LAST_YEAR' }
      ]
    };
    for (let index = 0; index < this.searchValNum; index++) {
      const newobj = Object.assign({}, this.searchValObj);
      this.searchVal[index] = newobj;
    }
  }

  /**
   * name
   */
  public dataInit(): void {
    this.getStorageCondition();
    this.userService.getSysEnumById$('4').subscribe(res => {
      this.searchCondList.all = this.getCondlabel(this.searchCondList.all, res);
      this.searchCondList.select = this.getCondlabel(this.searchCondList.select, res);
      this.searchCondList.date = this.getCondlabel(this.searchCondList.date, res);
      this.searchCondList.month = this.getCondlabel(this.searchCondList.month, res);
    });
    this.searchlableList = []; //更多查询label
    var labelParam = {
      aah001: this._programId,
      option_type: '3'
    };
    this.http.post<any>('A/IACommonService/pub_get_aah', { param: labelParam }).subscribe(result => {
      console.log('更多查询label', result);
      this.searchlableList = result.data;
      this.searchlableList.push({ label: this.translateService.instant('com-请选择'), value: '' });
      // this.onAfterSearch();
      if (result.data2 && result.data2.length > 0) {
        this.keyWords = '请输入' + result.data2.map(obj => {
          return obj.label;
        }).join(',');
      }
      if (result.data3 && result.data3.length > 0) { //排序资料
        this.orderByListInit = result.data3 || [];
        this.orderByList = this.orderByListInit.filter(obj => { return obj.acj004 == 'Y' });
        console.log('====获取到排序数据')
      }
    });

    this.getComCondData();

  }

  //获取常用查询条件
  getComCondData(isCollapse?: boolean): void {
    if (!this.isProgram) { return; } //如果是查询条件设置作业调用
    var comCondParam = {
      aan001: this.userService.userInfo['c_user'], //用户编号
      aan002: this._programId //作业编号
    };
    this.http.post<any>('A/IACommonService/pub_get_aan', { param: comCondParam }).subscribe(result => {
      console.log('常用查询', result);
      this.gcomCondsList = result.data;
      if (isArray(this.gcomCondsList) && this.gcomCondsList.length > 0) {
        for (let index = 0; index < this.gcomCondsList.length; index++) {
          //this.gcomCondsList[index].aan004 = this.gcomCondsList[index].aan004.replace(":USER",this.userService.userInfo['c_user'])
          this.gcomCondsList[index].aan004 = this.gcomCondsList[index].aan004.replace(/:USER/g, this.userService.userInfo['c_user'])
          this.gcomCondsList[index].aan004 = this.gcomCondsList[index].aan004.replace(/:TODAY/g, this.datePipe.transform(new Date(), this.dwDateFormat))
          this.gcomCondsList[index].aan004 = this.gcomCondsList[index].aan004.replace(/:ORG/g, this.userService.userInfo['c_org'])
          this.gcomCondsList[index].aan004 = this.gcomCondsList[index].aan004.replace(/:PER/g, this.userService.userInfo['c_per'])
          //this.gcomCondsList[index].aan004 = this.gcomCondsList[index].aan004.replace(":YESTERDAY",this.datePipe.transform(new Date(),this.dwDateFormat))
          if (this.gcomCondsList[index].aan004.indexOf('TODAY') != -1 || this.gcomCondsList[index].aan004.indexOf('THREE') != -1
            || this.gcomCondsList[index].aan004.indexOf('WEEK') != -1 || this.gcomCondsList[index].aan004.indexOf('MONTH') != -1
            || this.gcomCondsList[index].aan004.indexOf('NOW_MONTH') != -1 || this.gcomCondsList[index].aan004.indexOf('LAST_MONTH') != -1
            || this.gcomCondsList[index].aan004.indexOf('NOW_YEAR') != -1 || this.gcomCondsList[index].aan004.indexOf('LAST_YEAR') != -1) {
            this.gcomCondsList[index].aan004 = JSON.parse(this.gcomCondsList[index].aan004);
            var oneDay = 1000 * 60 * 60 * 24;
            var date1 = new Date(new Date(new Date().toLocaleDateString()).getTime());
            var date2 = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
            var now_Date = new Date();
            this.gcomCondsList[index].aan004.moresel.forEach(obj => {
              if (obj.condition == 'TODAY') {
                obj.value = [date1, date2];
              } else if (obj.condition == 'THREE') {
                obj.value = [calDate('day', -2, date1), date2];
              } else if (obj.condition == 'WEEK') {
                obj.value = [calDate('day', -6, date1), date2];
              } else if (obj.condition == 'MONTH') {
                obj.value = [calDate('month', -1, date1), date2];
              } else if (obj.condition == 'NOW_MONTH') { //当月
                var now_Date = new Date();
                var currentMonth = now_Date.getMonth();
                var nextMonth = ++currentMonth;
                var nextMonthFirstDay = new Date(now_Date.getFullYear(), nextMonth, 1);
                obj.value = [new Date(now_Date.getFullYear(), now_Date.getMonth(), 1), new Date(nextMonthFirstDay.getTime() - 1)];
              } else if (obj.condition == 'LAST_MONTH') { //上月
                var now_Date = new Date();
                var currentMonthFirstDay = new Date(now_Date.getFullYear(), now_Date.getMonth(), 1);
                var lastMonthlastDay = new Date(currentMonthFirstDay.getTime() - 1);
                obj.value = [new Date(lastMonthlastDay.getFullYear(), lastMonthlastDay.getMonth(), 1), lastMonthlastDay];
              } else if (obj.condition == 'NOW_YEAR') { //当年
                obj.value = [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31, 23, 59, 59)];
              } else if (obj.condition == 'LAST_YEAR') { //去年
                obj.value = [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59)];
              }
            });
          } else {
            this.gcomCondsList[index].aan004 = JSON.parse(this.gcomCondsList[index].aan004);
          }
        }
      }
      this.gcomCondsList.push({ label: '保存常用查询', value: 'save' });
      this.gcomCondsList.push({ label: '编辑常用查询', value: 'edit' });
      const searchSession = sessionStorage.getItem(this.programId + '_searchMore') || sessionStorage.getItem(this.programId);
      if (!searchSession) {
        var filterData = JSON.parse(JSON.stringify(this.gcomCondsList.filter(obj => obj.aan005 == 'Y')));
        if (filterData.length > 0) {
          this.searchParam.gcomConds = filterData[0].value;
          if (filterData[0].aan004.moresel) {
            this.searchVal = filterData[0].aan004.moresel;
          }
          this.searchParam.glikesel = filterData[0].aan004.likesel || '';
          if (filterData[0].aan004.orderfields && filterData[0].aan004.orderfields.length > 0) {
            this.orderByList = filterData[0].aan004.orderfields;
          }
          // var aan004str = JSON.parse(filterData[0].aan004);
          // if (aan004str.moresel) {
          //   this.searchVal = aan004str.moresel;
          // }
          // this.searchParam.glikesel = aan004str.likesel || '';
          this.isAutoSearch = true;// 有默认查询条件的默认查询
          console.log('默认查询=== ')
          // 判断如果有默认查询条件且至少有一个栏位有值，则默认展开，否则关闭
          const valueList = this.searchVal.filter(x => x.value);
          if (valueList.length >= 1) {
            if (isCollapse === undefined) {
              this.clickCollapse(false);
            } else {
              this.clickCollapse(isCollapse);
            }
          } else {
            if (isCollapse === undefined) {
              this.clickCollapse(true);
            } else {
              this.clickCollapse(isCollapse);
            }
          }
        } else {
          //都无条件时将默认查询条件展示在更多查询中
          var defLabelList = this.searchlableList.filter(obj => { return obj.aal007 == 'Y' });
          if (defLabelList.length > 0) {
            for (let index = 0; index < defLabelList.length; index++) {
              var valObj = { table_no: '', field_no: '', condition: 'EQUAL', value: '', lable_type: '3', more01: [], cond_type: 'all', aae005: 'VARCHAR2' };
              var newobj = Object.assign(valObj, {
                field_no: defLabelList[index].value,
                aae005: defLabelList[index].aae005,
                aal005: defLabelList[index].aal005,
                openKey: defLabelList[index].openKey,
                param_list: defLabelList[index].param_list,
                lable_type: defLabelList[index].lable_type,
                more01: defLabelList[index].more01,
                table_no: defLabelList[index].table_no,
              });
              if (newobj.lable_type == '1' || newobj.lable_type == '3') {
                newobj.cond_type = 'all';
              } else if (newobj.lable_type == '2' || newobj.lable_type == '6') {
                newobj.cond_type = 'select';
              } else if (newobj.lable_type == '4' || newobj.lable_type == '5') {
                newobj.cond_type = 'date';
              } else if (newobj.lable_type == '7') {
                newobj.cond_type = 'month';
              } else {
                newobj.cond_type = 'all';
              }
              this.searchVal[index] = newobj;
            }
            // this.clickCollapse(isCollapse);
            this.clickCollapse(true);
          }
        }
        if (this.isAutoSearch) {
          this.searchData(true);
        }
      } else {
        this.clickCollapse(isCollapse);
        if (this.isAutoSearch) {
          this.searchData(false);
        }
      }
    });
  }
  // 日期格式化的方法
  dateFtt(fmt: string, date: Date): any {
    var o = {
      "M+": date.getMonth() + 1,                  //月份
      "d+": date.getDate(),                       //日
      "h+": date.getHours(),                      //小时
      "m+": date.getMinutes(),                    //分
      "s+": date.getSeconds(),                    //秒
      "q+": Math.floor((date.getMonth() + 3) / 3),//季度
      "S": date.getMilliseconds()                 //毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  }

  getStorageCondition(): void {
    var searchSession = sessionStorage.getItem(this.programId + '_searchMore') || sessionStorage.getItem(this.programId);
    if (searchSession !== null && searchSession !== undefined && searchSession !== 'null' && searchSession !== '') {
      this.searchVal = JSON.parse(searchSession).searchVal;
      this.searchParam.gcomConds = JSON.parse(searchSession).gcomConds;
      this.searchParam.glikesel = JSON.parse(searchSession).glikesel;
      // if (JSON.parse(searchSession).orderfields.length > 0) {
      //   this.orderByList = JSON.parse(searchSession).orderfields.map(obj => {
      //     // return {table_no: obj.acj005, name: obj.acj002, orderby: obj.acj003}
      //     return {acj005: obj.table_no, acj002: obj.name, acj003: obj.orderby}
      //   });
      // }
      if (JSON.parse(searchSession).orderfields && JSON.parse(searchSession).orderfields.length > 0) {
        this.orderByList = JSON.parse(searchSession).orderfields;
      }
      // this.searchParam.orderfields = JSON.parse(searchSession).orderfields;
    } else {
      this.searchParam.gcomConds = '';
      this.searchParam.glikesel = '';
      this.searchParam.orderfields = [];
    }
  }

  /**
   * getCondlabel
   * 获取更多查询条件
   */
  public getCondlabel(xarry: any, xdata: any) {
    xarry.forEach(element => {
      var filterData = xdata.filter(obj => obj.value == element.value);
      element.label = filterData.length > 0 ? filterData[0].label : element.label;
    });
    return xarry;
  }
  public getAal009List(xdata: any) {
    // var xdataList = xdata.split(',');
    var xdataList = xdata;
    var aal009List = [];
    this.searchCondList.self.forEach(element => {
      var filterData = xdataList.filter(obj => obj == element.value);
      if (filterData.length > 0) {
        aal009List.push(element);
      }
    });
    return aal009List;
  }

  //更多查询条件label change
  //1、开窗 2、单选 3、text 4、日期 5、日期时间 6、多选 7、年月  // 8、日期区间 9日期时间区间
  public changesearchfield(index: any): void {
    this.searchVal[index].value = '';
    this.searchVal[index].condition = 'EQUAL';
    if (this.searchVal[index].field_no) {
      var fieldArray = this.searchlableList.filter(obj => obj.value == this.searchVal[index].field_no);
      if (fieldArray.length > 0) {
        this.searchVal[index].lable_type = fieldArray[0].lable_type;
        this.searchVal[index].table_no = fieldArray[0].table_no;
        this.searchVal[index].more01 = fieldArray[0].more01;
        this.searchVal[index].aae005 = fieldArray[0].aae005;
        this.searchVal[index].aal005 = fieldArray[0].aal005;
        this.searchVal[index].openKey = fieldArray[0].openKey;
        this.searchVal[index].param_list = fieldArray[0].param_list;
      }
      if (fieldArray[0].aal009 && fieldArray[0].aal009.length > 0) {
        this.searchVal[index].cond_type = 'self';
        this.searchVal[index].aal009List = this.getAal009List(fieldArray[0].aal009);
      } else {
        if (this.searchVal[index].lable_type == '1' || this.searchVal[index].lable_type == '3') {
          this.searchVal[index].cond_type = 'all';
        } else if (this.searchVal[index].lable_type == '2' || this.searchVal[index].lable_type == '6') {
          this.searchVal[index].cond_type = 'select';
          this.searchVal[index].value = [];
          if (this.searchVal[index].aal005 === null) {
            this.searchVal[index].more01 = [];
          }
        } else if (this.searchVal[index].lable_type == '4' || this.searchVal[index].lable_type == '5') {
          this.searchVal[index].cond_type = 'date';
          this.searchVal[index].between = false;
        } else if (this.searchVal[index].lable_type == '7') {
          this.searchVal[index].cond_type = 'month';
          this.searchVal[index].between = false;
        } else {
          this.searchVal[index].cond_type = 'all';
        }
      }

    } else {
      this.searchVal[index].lable_type = '3';
      this.searchVal[index].table_no = '';
      this.searchVal[index].more01 = [];
      this.searchVal[index].cond_type = 'all';
    }
  }

  /**
   * name
   */
  public changesearchCondition(index) {
    if (this.searchVal[index].lable_type == '4' || this.searchVal[index].lable_type == '5' || this.searchVal[index].lable_type == '7' || this.searchVal[index].lable_type == '8') {
      if (this.searchVal[index].condition == 'BETWEEN' || this.searchVal[index].condition == 'TODAY'
        || this.searchVal[index].condition == 'THREE' || this.searchVal[index].condition == 'WEEK'
        || this.searchVal[index].condition == 'MONTH' || this.searchVal[index].condition == 'NOW_MONTH'
        || this.searchVal[index].condition == 'LAST_MONTH' || this.searchVal[index].condition == 'NOW_YEAR'
        || this.searchVal[index].condition == 'LAST_YEAR') {
        this.searchVal[index].between = true;
        var date1 = new Date(new Date(new Date().toLocaleDateString()).getTime());
        var date2 = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
        var now_Date = new Date();
        switch (this.searchVal[index].condition) {
          case 'BETWEEN':
            this.searchVal[index].value = [];
            break;
          case 'TODAY':
            this.searchVal[index].value = [date1, date2];
            break;
          case 'THREE':
            this.searchVal[index].value = [calDate('day', -2, date1), date2];
            break;
          case 'WEEK':
            this.searchVal[index].value = [calDate('day', -6, date1), date2];
            break;
          case 'MONTH':
            this.searchVal[index].value = [calDate('month', -1, date1), date2];
            break;
          case 'NOW_MONTH':
            var currentMonth = now_Date.getMonth();
            var nextMonth = ++currentMonth;
            var nextMonthFirstDay = new Date(now_Date.getFullYear(), nextMonth, 1);
            this.searchVal[index].value = [new Date(now_Date.getFullYear(), now_Date.getMonth(), 1), new Date(nextMonthFirstDay.getTime() - 1)];
            break;
          case 'LAST_MONTH':
            var currentMonthFirstDay = new Date(now_Date.getFullYear(), now_Date.getMonth(), 1);
            var lastMonthlastDay = new Date(currentMonthFirstDay.getTime() - 1);
            this.searchVal[index].value = [new Date(lastMonthlastDay.getFullYear(), lastMonthlastDay.getMonth(), 1), lastMonthlastDay];
            break;
          case 'NOW_YEAR':
            this.searchVal[index].value = [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31, 23, 59, 59)];
            break;
          case 'LAST_YEAR':
            this.searchVal[index].value = [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59)];
            break;
          default:
            this.searchVal[index].value = [];
            break;
        }
      } else {
        this.searchVal[index].between = false;
        this.searchVal[index].value = null;
      }
    }

  }

  /**
   * clickCollapse
   * 打开关闭更多查询
   */
  public clickCollapse(isCollapse?: boolean) {
    if (isCollapse !== undefined) {
      // this.isCollapse = JSON.parse(JSON.stringify(isCollapse));
      this.isCollapse = isCollapse;
    } else {
      this.isCollapse = !this.isCollapse;
    }
    if (this.isAutoHeight && this.heightEle) {
      if (this.isCollapse) { //关闭更多查询
        this.heightEle.style.height = this.initHeight;
      } else {
        var count = this.searchVal.length > 24 ? 33 * 8 + 5 : (33 * this.searchVal.length / 3) + 5;
        this.heightEle.style.height = this.heightEle.style.height.replace(')', ' - ' + count + 'px)');
      }
    }
  }
  /**
   * 添加更多查询条件
   */
  public addMore(): void {
    var nowLen = this.searchVal.length;
    for (let index = nowLen; index < (nowLen + 3); index++) {
      const newobj = Object.assign({}, this.searchValObj);
      this.searchVal[index] = newobj;
    }
    if (this.heightEle && this.searchVal.length <= 24 && this.isAutoHeight) {
      this.heightEle.style.height = this.heightEle.style.height.replace(')', ' - 33px)');
    }
    console.log('添加更多', this.searchVal);
  }

  /**
   * 添加更多查询条件
   */
  public minusMore(): void {
    var newLen = this.searchVal.length - 3;

    if (newLen > 1) {
      this.searchVal.splice(newLen, 3);
      if (this.heightEle && this.searchVal.length <= 21 && this.isAutoHeight) {
        this.heightEle.style.height = this.heightEle.style.height.replace(')', ' + 33px)');
      }
    }
    console.log('添加更多', this.searchVal);
  }

  /**
   * 查询事件
   *
   * @param {boolean} [isAsync=false] 是否同步处理
   * @memberof DocumentOrderComponent
   */
  public searchData(isAsync: boolean = true): void {
    // sessionStorage.setItem(this.programId, JSON.stringify({searchVal: this.searchVal, pageNumber: 1, pageSize: 10}));
    if (this.getsearchParam()) {
      this.onSearch.emit(isAsync);
    };

  }

  getsearchParam() {
    // 传入参数错误，存在SQL注入风险！
    var ischeck = true;
    this.searchParam.condition.items = [];
    var sqlstr = ''
    var reg = new RegExp('\(\\b\(and|exec|insert|select|drop|grant|alter|delete|update|count|chr|mid|master|truncate|char|declare|or\)\\b\)|\(\\*|;|\\+|\'|%\)');

    //var reg = new RegExp('\(\\b\(and|exec|insert|select|drop|grant|alter|delete|update|count|chr|mid|master|truncate|char|declare|or\)\\b\)');
    this.searchParam.glikesel = _.trim(this.searchParam.glikesel);
    // if (reg.test(this.searchParam.glikesel)) {
    //   sqlstr = '关键字';
    // }else {
    if (this.orderByList.length > 0) {
      this.searchParam.orderfields = this.orderByList.map(obj => {
        return { table_no: obj.acj005, name: obj.acj002, orderby: obj.acj003 }
      });
    } else {
      this.searchParam.orderfields = [];
    }

    for (const obj of this.searchVal) {
      // if (reg.test(obj.value) && (this.c_S004 !== '0')) {
      //   if (sqlstr) {
      //     sqlstr = this.searchlableList.filter( item => {return item.value == obj.field_no})[0].label;
      //   } else {
      //     sqlstr = sqlstr + ',' + this.searchlableList.filter( item => {return item.value == obj.field_no})[0].label;
      //   }
      // } else {
      var valData = [];
      if (obj.field_no != '') {
        if (obj.condition == 'IS_BLANK' || obj.condition == 'NOT_BLANK') {
          this.searchParam.condition.items.push({
            table_no: obj.table_no,
            name: obj.field_no,
            operator: obj.condition,
            value: valData,
            type: obj.lable_type
          });
        } else {
          if (isString(obj.value) && obj.value != '') {
            valData = obj.value.split(new RegExp('[,|\x22|，]+'));
            obj.condition2 = obj.condition;
          } else if (isDate(obj.value) && obj.value != null) {
            if (obj.lable_type == '4') {
              var valdate = (new DatePipe('zh_tw')).transform(obj.value, 'yyyy/MM/dd');
            } else if (obj.lable_type == '7') {
              var valdate = (new DatePipe('zh_tw')).transform(obj.value, 'yyyy/MM');
            } else {
              var valdate = (new DatePipe('zh_tw')).transform(obj.value, 'yyyy/MM/dd HH:mm:ss');
            }
            valData = [valdate];
            obj.condition2 = obj.condition;
          } else if (isArray(obj.value) && obj.value.length > 0) {
            for (let index = 0; index < obj.value.length; index++) {
              if (obj.lable_type == '4') {
                obj.condition2 = 'BETWEEN'
                valData[index] = (new DatePipe('zh_tw')).transform(obj.value[index], 'yyyy/MM/dd');
              } else if (obj.lable_type == '5') {
                obj.condition2 = 'BETWEEN'
                valData[index] = (new DatePipe('zh_tw')).transform(obj.value[index], 'yyyy/MM/dd HH:mm:ss');
              } else if (obj.lable_type == '7') {
                obj.condition2 = 'BETWEEN'
                valData[index] = (new DatePipe('zh_tw')).transform(obj.value[index], 'yyyy/MM');
              } else {
                valData[index] = obj.value[index];
                obj.condition2 = obj.condition;
              }
            }
          }
          if (valData.length > 0) {
            this.searchParam.condition.items.push({
              table_no: obj.table_no,
              name: obj.field_no,
              operator: obj.condition2,
              value: valData,
              type: obj.lable_type
            });
          }

          // 数值区间额外处理
          if (obj.lable_type == '8' && obj.condition == 'BETWEEN' && obj.valueStart && obj.valueEnd) {
            obj.condition2 = obj.condition;
            this.searchParam.condition.items.push({
              table_no: obj.table_no,
              name: obj.field_no,
              operator: obj.condition2,
              value: valData,
              type: obj.lable_type
            });
          }
        }
      }

      // }
    }
    // //暂时注销掉
    // if (sqlstr && (this.c_S004 !== '0')) {
    //   this.dwMessage.errorNew(sqlstr +  this.translateService.instant('com-sqlZR'), this.translateService.instant('type_com-sqlZR'), '', '');
    //   ischeck = false;
    // }
    return ischeck;
  }

  /**
   * 常用查询条件
   */
  public changecomCond(xVal): void {
    console.log('常用查询', xVal);
    if (xVal != 'save' && xVal != 'edit') { //切换查询条件
      var fieldArray = this.gcomCondsList.filter(obj => obj.value == xVal);
      if (fieldArray.length > 0) {
        // var aan004str = JSON.parse(fieldArray[0].aan004);
        if (fieldArray[0].aan004.moresel) {
          this.searchVal = JSON.parse(JSON.stringify(fieldArray[0].aan004.moresel));
        }
        this.searchParam.glikesel = JSON.parse(JSON.stringify(fieldArray[0].aan004.likesel || ''));
        this.orderByList = JSON.parse(JSON.stringify(fieldArray[0].aan004.orderfields || ''));
      }
      this.searchData(true);
      this.clickCollapse(false);
    }
  }

  //下拉菜单打开状态变化回调
  public openChangecomCond(xVal): void {
    if (!xVal) {
      if (this.searchParam.gcomConds == 'save') {
        const modal = this.dwModalService.create({
          dwTitle: '保存常用查询条件',
          dwContent: saveCondComponent,
          dwComponentParams: {}, //入参使用@input
          dwClassName: 'fx_fillter_model',
          dwWidth: window.screen.width * 0.4,
          dwWrapClassName: 'fx_sm_modal',
          dwOnOk: (result: any): void => {
            // console.log('保存后', result, result.addForm)
            this.saveComCond(result, 'save');
          },
          dwOnCancel(): void {
          },
          dwFooter: null
        });
      } else if (this.searchParam.gcomConds == 'edit') {
        var dataList = [];
        for (let index = 0; index < this.gcomCondsList.length; index++) {
          this.gcomCondsList[index].aan005c = this.gcomCondsList[index].aan005 == 'Y' ? true : false;
          if (this.gcomCondsList[index].value != 'save' && this.gcomCondsList[index].value != 'edit' && this.gcomCondsList[index].aan001 != 'ALL') {
            dataList.push(this.gcomCondsList[index]);
          }
        }
        const modal = this.dwModalService.create({
          dwTitle: '编辑常用查询条件',
          dwContent: editCondComponent,
          dwComponentParams: {
            dataList: dataList
          },
          dwWidth: window.screen.width * 0.4,
          dwWrapClassName: 'fx_sm_modal',
          dwOnOk: (result: any): void => {
            this.saveComCond(result.dataList, 'edit');
          },
          dwOnCancel(): void {
          },
          dwFooter: null
        });
      }
    }
  }

  /**
   * 保存常用条件
   */
  public saveComCond(xResult: any, xState) {
    if (xState == 'save') {
      // 保存常用查询条件判断如果是：当天，三天内，一周内，一月内，当月，上月，当年，去年,改成动态日期
      this.searchVal.forEach(x => {
        if (x.condition === 'TOADY' || x.condition === 'THREE' || x.condition === 'WEEK' || x.condition === 'MONTH'
          || x.condition === 'NOW_MONTH' || x.condition === 'LAST_MONTH' || x.condition === 'NOW_YEAR' || x.condition === 'LAST_YEAR') {
          x.value = ':' + x.condition;
        }
      });
      // 日期转换
      // this.searchVal.forEach(x => {
      //   if (x.condition != 'BETWEEN') {
      //     if (x.lable_type == '4') { //日期
      //       x.value = moment(x.value).format('YYYY/MM/DD').toString();
      //     } else if (x.lable_type == '5') { //日期时间
      //       x.value = moment(x.value).format('YYYY/MM/DD HH:mm:ss').toString();
      //     } else if (x.lable_type == '7') { //年月
      //       x.value = moment(x.value).format('YYYY/MM').toString();
      //     }
      //   }
      // });
      var aan004str = JSON.stringify({
        moresel: this.searchVal || [],
        likesel: this.searchParam.glikesel,
        orderfields: this.orderByList
      });
      var comCondParam = {
        aan001: this.userService.userInfo['c_user'], //用户编号
        aan002: this._programId, //作业编号
        value: '',
        label: xResult.addForm.get('label').value,
        aan004: aan004str,
        aan005: xResult.addForm.get('aan005').value ? 'Y' : 'N',
      };
      this.http.post<any>('A/IACommonService/pub_add_aan', { param: comCondParam }).subscribe(result => {
        // console.log('保存常用查询', result);
        var msg = result.message;
        if (result.success) {
          this.dwMessage.success('常用查询保存成功！');
          this.getComCondData(this.isCollapse);
        } else {
          if (msg) {
            this.dwMessage.success(msg); // 單一訊息顯示
          }
        }
      });
    } else {
      xResult.forEach(element => {
        element.aan001 = this.userService.userInfo['c_user'];
        element.aan002 = this._programId;
        element.aan005 = element.aan005c ? 'Y' : 'N';
        element.aan004 = JSON.stringify(element.aan004);
      });
      this.http.post<any>('A/IACommonService/pub_upd_aan', { param: xResult }).subscribe(result => {
        // console.log('保存常用查询', result);
        var msg = result.message;
        if (result.success) {
          this.dwMessage.success('编辑常用条件成功！');
          this.getComCondData(this.isCollapse);
        } else {
          if (msg) {
            this.dwMessage.success(msg); // 單一訊息顯示
          }
        }
      });
    }

  }

  /**
   * setOrderBy
   * 设置排序方式
   */
  public setOrderBy(): void {
    const modal = this.dwModalService.create({
      dwTitle: this.translateService.instant('com-排序'),
      dwStyle: { top: '40px' },
      dwWidth: window.screen.width * 0.4,
      dwMaskClosable: true, // 點擊遮罩是否允許關閉
      dwWrapClassName: 'fx_xs_modal',
      dwContent: SetOrderByComponent,
      dwComponentParams: {
        orderByParam: {
          orderByListInit: this.orderByListInit, //作业注册配置的排序条件
          orderByList: this.orderByList  //已经选择的排序条件
        }
      },
      dwFooter: [
        {
          label: "取消",
          shape: 'default',
          onClick: (data: any): void => {
            modal.destroy();
          }
        },
        {
          label: "确定",
          type: 'primary',
          onClick: (data: any): void => {
            if (data.MasterService.rowCachedFormGroup) {
              data.onMasterRowEditingStopped({});
              data.MasterService.rowCachedFormGroup = null;
              data.masterGridApi.stopEditing(false);
            }
            this.orderByList = data.myform.get('acj_f_list').getRawValue().filter(obj => { return obj.acj002 });
            modal.close({});
            this.searchData(true);
          }
        }
      ],
    });
  }

  /**
   * 清除
   *
   * @memberof DocumentOrderComponent
   */
  public resetForm(): void {
    // 查詢條件欄位初始化
    this.searchParam = {
      pageable: true,
      // pageSize: 10,
      // pageNumber: 1,
      glikesel: '',
      gcomConds: '',
      orderfields: [],
      condition: {
        joinOperator: 'AND',
        items: []
      }
    };
    this.searchVal.forEach(obj => {
      obj.table_no = this.searchValObj.table_no;
      obj.field_no = this.searchValObj.field_no;
      obj.condition = this.searchValObj.condition;
      obj.value = this.searchValObj.value;
      obj.lable_type = this.searchValObj.lable_type;
      obj.more01 = this.searchValObj.more01;
      obj.cond_type = this.searchValObj.cond_type;
      obj.aae005 = this.searchValObj.aae005;
      obj.openKey = this.searchValObj.openKey;
      obj.param_list = this.searchValObj.param_list;
      obj.aal005 = this.searchValObj.aal005;

    });
    console.log('cansh', this.searchVal);
    // for (let index = 0; index < this.searchValNum; index++) {
    //   this.searchVal[index] = newobj;
    // }
  }

  //开窗 ==  begin  ==
  public openParamModal($event: MouseEvent, isAsync: boolean = false, index): void {
    $event.preventDefault();
    if (this.searchVal[index].aal005) {// 判断作业注册里面如果有配置了开窗信息则调用开窗配置的信息
      if (!this.searchVal[index].openKey) {
        this.dwMessage.warning("请至开窗中选中勾选显示字段的主键！");
        return;
      }
      const openModelParam = {
        modalID: this.searchVal[index].aal005,     // (必传) 开窗的编号
        postParam: [(this.searchVal[index].param_list) ? this.searchVal[index].param_list : {}],             // (必传) 查询参数，注意是数组
        mutilSelected: true,
        selected: [],
        isChkAuth: true,
        searchType: 'q'
      };
      this.fxModalService.searchFx(openModelParam, this.programId).subscribe(data => {
        this.searchVal[index].value = data.map(obj => {
          return obj[this.searchVal[index].openKey];
        });
      });
    } else {// 调用作业的信息
      this.nowOpenIndex = index;
      this.openModal.emit(isAsync);
    }
  }

  public openSelect(isAsync: boolean = false, index): void {
    if (this.searchVal[index].aal005 === null || this.searchVal[index].aal005 === "") {
      this.nowOpenIndex = index;
      this.OptionList.emit(isAsync);
    }
  }

  public changeValue($event: MouseEvent, isAsync: boolean = false, index): void {
    $event.preventDefault();
    const namelist = this.searchlableList.filter(item => item.value === this.searchVal[index].field_no);
    let searchvalue = "";
    if (Array.isArray(this.searchVal[index].value)) {
      for (let i = 0;i<this.searchVal[index].value.length;i++) {
        if (i==this.searchVal[index].value.length-1) {
          searchvalue += this.searchVal[index].value[i];
        } else {
          searchvalue += this.searchVal[index].value[i] + '\n';
        }
      }
    } else {
      searchvalue = this.searchVal[index].value ? this.searchVal[index].value.replaceAll(',', "\n") : '';
    }
    const modal = this.dwModalService.create({
      dwTitle: namelist != null && namelist.length > 0 ? namelist[0].label : "",
      dwStyle: { top: '40px' },
      dwWidth: 400,
      dwWrapClassName: 'fx_xs_modal',
      dwMaskClosable: true, // 點擊遮罩是否允許關閉
      dwContent: SearchCondition01Component,
      dwComponentParams: {
        searchvalue: searchvalue
      },
      dwFooter: [
        {
          label: "取消",
          shape: 'default',
          onClick: (data: any): void => {
            modal.destroy();
          }
        },
        {
          label: "确定",
          type: 'primary',
          onClick: (data: any): void => {
            this.searchVal[index].value = data.myform.value.searchvalue ? data.myform.value.searchvalue.replaceAll("\r\n", ',').replaceAll("\n", ',') : '';
            modal.close({});
          }
        }
      ],
    });
  }

  onKeyDown(event): void {
    if (event.keyCode === 13) {
      this.searchData(true);
    }
  }

  //排序
  clickOrderBy(): void {

  }

  // 开窗 ==  end  ==


  ngAfterViewInit(): void {
    this.onReady.emit(true);
  }

}
