import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import * as _ from 'lodash';

export class ModalErrorInfo {
  public message: string = '';
  public isRemind: boolean = false;
  public itemInfos: Array<ModalItemInfo> = [];

  public checked?: boolean = false;

  constructor(value: any) {
    Object.assign(this, value);
  }
}

export class ModalItemInfo {
  public errorCode: string = '';
  public content: string = '';
  public program_name: string = '';
  public program_no: string = '';
  public title: string = '';
  public isRemind: string = '';
  public moreInfo: string = '';

  public hasMoreContent?: boolean = false;
  public showMoreContent?: boolean = false;
  keyWord;

  constructor(value: any) {
    Object.assign(this, value);
  }
}

/**
 * 用JS分析、导出CSV文件
 * https://blog.csdn.net/jennieji/article/details/78575118
 */

export function arrayToCsv(data, args = {}): string {
  // @ts-ignore
  const columnDelimiter = args.columnDelimiter || ',';
  // @ts-ignore
  const lineDelimiter = args.lineDelimiter || '\n';

  return data.reduce((csv, row) => {
    const rowContent = Array.isArray(row)
      ? row.reduce((rowTemp, col) => {
        let ret = rowTemp ? rowTemp + columnDelimiter : rowTemp;
        if (col) {
          const formatedCol = col.toString().replace(new RegExp(lineDelimiter, 'g'), ' ');
          ret += /,/.test(formatedCol) ? `"${formatedCol}"` : formatedCol;
        }
        return ret;
      }, '')
      : row;
    return (csv ? csv + lineDelimiter : '') + rowContent;
  }, '');
}

function exportCsv(inputData, filename = 'export.csv'): void {
  const BOM = '\uFEFF';
  const csv = arrayToCsv(inputData);

  if (navigator.msSaveOrOpenBlob) {
    const blob = new Blob([BOM + csv], { type: 'text/csv;charset=utf-8;' });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const uri = encodeURI(`data:text/csv;charset=utf-8,${BOM}${csv}`);
    const downloadLink = document.createElement('a');
    downloadLink.href = uri;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}

@Component({
  selector: 'fx-batch-modal-component',
  template: `
    <div class="fx_modal_container" appModalDrag>
      <div class="fx_modal_title">
          <div class="fx_le_title">
            <i class="anticon anticon-{{iconType}}"></i>
            <span>{{ title }}</span>
          </div>
          <div class="fx_ri_full">
                <ul>
                    <li class="fx_modal_search">
                      <dw-input-group dwSuffixIcon="anticon anticon-search">
                        <input type="text" dw-input placeholder="请输入筛选条件" [(ngModel)]="searchText" (keyup)="($event.which === 13)?jump():0">
                      </dw-input-group>
                    </li>
                    <li>
                        <ol class="fx_modal_icon-group">
                          <li>
                            <i class="anticon anticon-close fx_modal_close_btn" (click)="fxCloseModal()"></i>
                          </li>
                        </ol>
                    </li>
                </ul>
          </div>

      </div>
      <div class="fx_modal_content">
      <div class="fx-modal-content-more" style="overflow-y: hidden;">
        <div dw-row class="fx_batch_status">
          <dw-col dwSpan="15" style="display:flex;align-items: center;">
            <i class="anticon anticon-check-circle fx_primary_color"></i>
            <span class="fx_success_text">{{msg}} <b>{{successCount}}</b> 笔， 异常 <b class="color-error">{{failCount}}</b> 笔！</span>
          </dw-col>
        </div>
        <div dw-row>
          <dw-col dwSpan="15" style="display:flex;align-items: center;">
            <b>{{successInfos}}</b>
          </dw-col>
        </div>
      </div>

      <div class="fx-modal-content-more fx-modal-message-panel" id="fx-modal-message-panel">
      <div dw-row>
      <label class="stay-left" dw-checkbox [(ngModel)]="allchecked"  (ngModelChange)="allChecked($event)" *ngIf="isRemind">全选</label>
      </div>
        <div dw-row *ngFor="let error of errorInfos">
          <div class="fx_warring_title" style="padding:0 10px 0 0;color:#333;">
          <label class="stay-left" dw-checkbox [(ngModel)]="error.checked" (ngModelChange)="updateSingleChecked()" *ngIf="error.isRemind"></label>
          <span [innerHTML]="error.message | fxHighlight: searchText"></span>
          </div>
          <div dw-row class="fx-modal-message-detail" *ngFor="let info of error.itemInfos">
            <div dw-col dwSpan="21" style="padding:5px 0;">
              <i class="anticon anticon-info-circle color-warning" *ngIf="info.isRemind == '2'"></i>
              <i class="anticon anticon-close-circle color-error" *ngIf="info.isRemind != '2'"></i>
              <span [innerHTML]="info.title | fxHighlight: searchText" style="padding:0 10px 0 0;color:#333;"></span>
              <span *ngIf="info.errorCode">(</span>
              <span [innerHTML]="info.errorCode | fxHighlight: searchText" class="fx_search_text"></span>
              <span *ngIf="info.errorCode">) </span>
              <span [innerHTML]="info.content | fxHighlight: searchText" class="color-error"></span>
              <span *ngIf="info.program_name" class="color-error">请查询
                <a href="javascript: void(0)"
                   (click)="openMenu(info.program_no)">{{info.program_name}}</a> 作业</span>
            </div>
            <div dw-col dwspan="3" style="float: right;padding:6 0;" *ngIf="info.hasMoreContent">
              <i class="anticon anticon-plus-square-o color-info" *ngIf="!info.showMoreContent"
                 (click)="info.showMoreContent = !info.showMoreContent"></i>
              <i class="anticon anticon-minus-square-o color-info" *ngIf="info.showMoreContent"
                 (click)="info.showMoreContent = !info.showMoreContent"></i>
            </div>
            <div dw-col dwspan="24" *ngIf="info.hasMoreContent && info.showMoreContent">
              <textarea rows="4" dw-input [ngModel]="info.moreInfo" disabled></textarea>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="fx_modal_footer">
        <div dw-row>
          <div dw-col dwSpan="9" dwType="flex" dwAlign="middle">
            <i class="anticon anticon-clock-circle-o fx_default_color"></i>
            <span>{{ time }}</span>
          </div>
          <div dw-col dwSpan="15">
            <div dw-row dwType="flex" dwJustify="end">
              <button dw-button [dwType]="'default'" (click)="onClickExport()">导出</button>
              <button dw-button [dwType]="'default'" (click)="onClickCancel()">取消</button>
              <button dw-button [dwType]="'primary'" (click)="onClickContinue()" [disabled]="selectedItem.length < 1" *ngIf="isRemind">继续执行</button>
              <button dw-button [dwType]="'primary'" (click)="onClickContinue()" *ngIf="!isRemind">确定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [
    './fx-batch-modal.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class FxBatchModalComponent implements OnInit {
  @Input() title: string;
  @Input() msg: string;
  @Input() successCount: number;
  @Input() failCount: number;
  @Input() time: string;
  @Input() successInfos: string;

  @Input() errorInfos: Array<ModalErrorInfo>;

  iconType: string = 'close-circle';
  // <i nz-icon type="info-circle" theme="outline"></i>
  // <i nz-icon type="close-circle" theme="outline"></i>

  isRemind: boolean = false;
  searchText: string = ''; // 筛选文本
  selectedItem: Array<ModalErrorInfo> = [];
  data = [];
  allchecked: boolean = false;

  //弹出层拖动控制
  draggable = true;

  constructor(private modal: DwModalRef, private elementRef: ElementRef) {
    // @ts-ignore
    if (_.findIndex(this.modal.dwComponentParams.errorInfos, 'isRemind') > -1) {
      this.isRemind = true;
      this.iconType = 'info-circle';
    }
  }

  private offset(element: any): number {
    let actualTop = element.offsetTop;
    let current = element.offsetParent;
    while (current.className !== 'ant-modal-content') {
      actualTop += current.offsetTop;
      current = current.offsetParent;
    }
    return actualTop;
  }

  // 回车后，跳转到高亮区域
  jump(): void {
    debugger
    if (this.searchText.length < 1) {
      return;
    }
    const markElement = this.elementRef.nativeElement.querySelector('mark');
    const messagePanel = this.elementRef.nativeElement.querySelector('.fx-modal-message-panel');
    if (!messagePanel || !markElement) {
      return;
    }
    const offsetHeight = this.offset(markElement) - messagePanel.offsetTop;
    messagePanel.scrollTo(0, offsetHeight);
  }

  updateSingleChecked(): void {
    this.selectedItem = _.filter(this.errorInfos, (error) => {
      return error.checked;
    });
  }

  allChecked(event): void {
    this.errorInfos.forEach(obj => {
      obj.checked = event;
    });
    this.updateSingleChecked();
  }

  openMenu(state: string): void {
    alert('open ' + state);
  }

  onClickContinue(): void {
    this.data = [];
    this.selectedItem.forEach(ele => {
      var keyval = ''
      ele.itemInfos.forEach(obj => {
        if (obj.isRemind == '2') {
          keyval = obj.keyWord;
        }
      });
      this.data.push(keyval);
    });
    this.modal.triggerOk();
  }
  onClickExport(): void {
    const exportDataTable: Array<Array<string>> = [];
    exportDataTable.push([
      '错误类型',
      '编号',
      '内容',
      '内容说明',
      '错误代码',
      '其他信息'
    ]);
    for (const error of this.errorInfos) {
      for (const info of error.itemInfos) {
        exportDataTable.push([
          info.isRemind ? '警告' : '错误',
          error.message || '',
          info.title || '',
          info.content || '',
          info.errorCode || '',
          info.moreInfo || ''
        ]);
      }
    }
    exportCsv(exportDataTable, this.title + '.csv');

    this.modal.triggerCancel();
  }
  onClickCancel(): void {
    this.modal.triggerCancel();
  }
  fxCloseModal(): void {
    this.modal.triggerCancel();
  }

  ngOnInit(): void {
    for (const error of this.errorInfos) {
      // error.checked = error.isRemind;
      error.checked = error.checked || false;
      for (const info of error.itemInfos) {
        info.hasMoreContent = info.moreInfo && info.moreInfo.length > 0;
        info.showMoreContent = false;
        if (!(/^[a-zA-Z0-9]+$/.test(info.errorCode))) {
          info.errorCode = '';
        }
      }
    }
    this.updateSingleChecked();
    // console.log(this.searchText);
  }
}
