import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {DwModalRef} from 'ng-quicksilver';

/**
 * 设计要求
 * icon 22px
 * title 16px
 * content 14px
 */
@Component({
  selector: 'fx-confirm-modal-component',
  template: `
    <div class="fx_modal_container" appModalDrag>
      <!-- 标题栏 -->
      <div class="fx_modal_title">
        <div class="fx_le_title">
          <i class="anticon anticon-{{iconType}}"></i>
          <span>{{ title }}</span>
        </div>
      </div>
      <!-- 消息区域 -->
      <div class="fx_modal_content">
        <div dw-row>
          <span [innerHTML]='message' ></span>
          <span *ngIf="program_name">请查询 <a href="javascript: void(0)" (click)="openMenu(program_no)">{{program_name}}</a> 作业</span>
        </div>

      <!-- 更多消息区域 -->
      <div class="fx-modal-content-more" *ngIf="hasMoreContent">
        <!-- show more -->
        <div dw-row *ngIf="!showMoreContent" (click)="showMoreContent = !showMoreContent">
          <i class="anticon anticon-plus-square-o color-info"></i>
          <span>更多信息</span>
        </div>
        <!-- hide more -->
        <div dw-row *ngIf="showMoreContent" (click)="showMoreContent = !showMoreContent">
          <i class="anticon anticon-minus-square-o color-info"></i>
          <span>隐藏更多信息</span>
        </div>
        <!-- more content -->
        <div dw-row *ngIf="showMoreContent">
          <textarea rows="4" dw-input [ngModel]="moreInfo" disabled></textarea>
        </div>
      </div>
      </div>
    </div>
  `,
  styleUrls: [
    './fx-confirm-modal.component.less'
  ]
})
export class FxConfirmModalComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() moreInfo: string;
  @Input() program_name: string;
  @Input() program_no: string;
  @Input() isRemind: boolean = false;

  hasMoreContent: boolean = false;
  showMoreContent: boolean = false;
  iconType: string = 'close-circle';
  draggable: true;
  // <i nz-icon type="info-circle" theme="outline"></i>
  // <i nz-icon type="close-circle" theme="outline"></i>

  constructor(private modal: DwModalRef) {
    // @ts-ignore
    if (this.modal.dwComponentParams.moreInfo) {
      this.hasMoreContent = true;
    }
    // @ts-ignore
    if (this.modal.dwComponentParams.isRemind) {
      this.iconType = 'info-circle';
    }
  }

  openMenu(state: string): void {
    alert('open ' + this.program_name);
  }

}
