import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {FxAgGridValidationEditorComponent} from './fx-ag-grid-validation-editor';

@Component({
  selector: 'fx-switch-cell-renderer',
  template: `
    <dw-switch [formControl]="formControl" [(ngModel)]="checked" [dwCheckedChildren]="dwCheckedChildren" [dwUnCheckedChildren]="dwUnCheckedChildren" [(dwDisabled)]="disabled"></dw-switch>
  `,
  encapsulation: ViewEncapsulation.None
})
export class FxAgSwitchRendererComponent extends FxAgGridValidationEditorComponent implements ICellRendererAngularComp, OnDestroy {
  private params: ICellRendererParams;
  checked: boolean = false;
  disabled: boolean = false;
  dwTrueVal: string = 'Y';
  dwFalseVal: string = 'N';
  dwCheckedChildren: string = '是';
  dwUnCheckedChildren: string = '否';
  validateForm: FormGroup;  // Added by David Lee 1Mar2019
  name: string;

  constructor(private fb: FormBuilder) {
    super();
  }

  agInit(params: any): any {

    this.params = params;


    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwTrueVal) {
      this.dwTrueVal = this.params.colDef.cellRendererParams.dwTrueVal;
    }

    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwFalseVal) {
      this.dwFalseVal = this.params.colDef.cellRendererParams.dwFalseVal;
    }

    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwCheckedChildren) {
      this.dwCheckedChildren = this.params.colDef.cellRendererParams.dwCheckedChildren;
    }

    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwUnCheckedChildren) {
      this.dwUnCheckedChildren = this.params.colDef.cellRendererParams.dwUnCheckedChildren;
    }
    if (this.params.getValue() === this.dwTrueVal) {
      this.checked = true;
    } else if (this.params.getValue() === this.dwFalseVal) {
      this.checked = false;
    } else {
      // console.log('Value is Error!');
    }

    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwDisabled) {
      this.disabled = this.params.colDef.cellRendererParams.dwDisabled;
    }

    this.formControl = new FormControl(this, params.validators);
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.name = this.params.node.id + '-' + params.column.colId;

    if (this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);
    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }

    this.formControl.markAsDirty();


    super.init();
  }

  refresh(): boolean {
    return false;
  }


  ngOnDestroy(): void {
    this.validateForm.removeControl(this.name);
  }
}
