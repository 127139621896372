import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DwRoutingMessageService, DwUserService } from '@webdpt/framework';
import { DwModalService } from 'ng-quicksilver';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, fromEvent } from 'rxjs';
import { FxDapHttpClient } from '../../fx-http-client';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'fx-tool-bar',
  templateUrl: './fx-tool-bar.component.html',
  styleUrls: ['./fx-tool-bar.component.less']
})
export class FxToolBarComponent implements OnInit {
  @Input() programId: string = '';
  @Input() btnData: any[] = []; //按钮资料\
  @Input() btnIdNameMap: any; //id和name的对应Map
  @Input() exportData: any = {};   // 导出资料
  @Input() exportEabData: any = {};   // 导出资料
  @Input() downloadData: any[] = []; // 模板下载资料
  @Input() importData: any = {};   // 导入资料
  @Input() warningMsg: any; // 导入后提示信息
  @Input() maxbtn: number = 0; // 导入后提示信息
  btnList$: Observable<Array<{
    icon: string,
    type: string,
    id: string,
    name: string,
    disabled: () => boolean,
    isHide: () => boolean
  }>>;
  @Output() onButton = new EventEmitter<boolean>();
  @Output() onReload = new EventEmitter<boolean>();
  @ViewChild('btn') btnW: ElementRef; //绑定按钮栏位的节点
  @ViewChild('btns') btns: ElementRef;
  public currentStyles = {}; //浮层按钮的样式
  public showBtn = false; //更多按钮显示层
  public btnMore: any[] = []; // 更多按钮
  public showMoreBtn = false;
  // public btnGroup = fromEvent(document,'resize')
  public autoWidth = '525px'; //记录默认可见宽
  public isChangeData: any = [];
  public winChange;
  public resizeListen;
  public isSplit = false; //替换数组显示隐藏
  public newBtnList: any = [];//待替换数组
  public btnExportListShow = false; // 是否展示导出列表
  public visible = false;
  public downloadItem: any = null; // 模板下载item
  public programRouteId: any; // 记录作业的作业编码+路由id
  private FTemplateData: any; //下载模板返参
  private FShowbtnData: any; //按钮返参
  private FProgramId: string = ''; //当前作业id


  constructor(
    public http: FxDapHttpClient,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    // 记录当前作业编号+路由id确定唯一性-start
    const programUrl = window.location.href;
    this.programRouteId = (programUrl.split('/dwTabRouting/') && programUrl.split('/dwTabRouting/').length) ? programUrl.split('/dwTabRouting/')[programUrl.split('/dwTabRouting/').length - 1] : '';
    // 记录当前作业编号+路由id确定唯一性-end

    this.refresh();

    // 页面监听
    this.winChange = fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(event => {
        // 解决所有作业共用一个window，导致监听会导致其他作业tool-bar按钮展示到更多里面的问题-start
        var currentProgramUrl = window.location.href, currentProgramRouteId;
        if (currentProgramUrl) {  // 获取当前打开页面作业编号+路由id
          currentProgramRouteId = (currentProgramUrl.split('/dwTabRouting/') && currentProgramUrl.split('/dwTabRouting/').length) ? currentProgramUrl.split('/dwTabRouting/')[currentProgramUrl.split('/dwTabRouting/').length - 1] : '';
        }
        if (this.programRouteId !== currentProgramRouteId) {
          return;
        }
        // 解决所有作业共用一个window，导致监听会导致其他作业tool-bar按钮展示到更多里面的问题-end
        let clWidth = document.body.clientWidth;
        let param = Math.round(Math.round((clWidth - (200 + 10 + 20)) / 3) + (Math.round((clWidth - (200 + 10 + 20)) / 3) / 3) * 2);
        const getReW$ = this.btnW.nativeElement.parentNode.parentNode.clientWidth;
        this.changeBtnWidth(getReW$, this.isChangeData);
      });


  }

  refresh(): void {
    this.btnList$ = this.updateButtonList();
    this.btnList$.subscribe(data => {
      // this.updateButtonList().subscribe(data => {
      //第一次赋值
      this.isChangeData = data;
      //获取宽度值
      //let param = Math.round(Math.round((document.body.clientWidth - (200 + 10 + 20))/3) + (Math.round((document.body.clientWidth - (200 + 10 + 20))/3)/3)*2);
      const getCurrentW$ = this.btnW.nativeElement.parentNode.parentNode.clientWidth;
      this.changeBtnWidth(getCurrentW$, data);
    });
  }

  changeBtnWidth($W: any, $D: any) {
    //$W不允许为空
    if ($W == '0' || $W == '') {
      return;
    }
    try {
      var sumlength = 70;
      var btnArr = [].concat($D);
      $D.forEach((obj, index) => {
        console.log(obj);
        if (obj.isHide && obj.isHide()) {
          return;
        }
        sumlength = sumlength + obj.btn_len;
        if (sumlength > $W || (this.maxbtn !== 0 && index >= this.maxbtn && $D.length > this.maxbtn + 1)) {
          this.showMoreBtn = true;
          this.isSplit = true; //显示备用数组
          this.newBtnList = btnArr.splice(0, index);  //新的展示按钮清单
          this.btnMore = btnArr; //新的更多按钮清单
          throw new Error('split break');
        } else {
          this.showMoreBtn = false;
        }
      });
    } catch (error) {

    } finally {
      if (!this.showMoreBtn) { //没有更多按钮
        this.newBtnList = btnArr;
      }
      if (this.btnMore.length > 0) {
        this.btnMore.forEach((item, key) => {
          item.order = key;
        });
        const downloadIdx = this.btnMore.findIndex(item => {
          return item.id == 'btnTDownload';
        });
        if (downloadIdx !== -1) {
          this.downloadItem = this.btnMore[downloadIdx];
          this.btnMore.splice(downloadIdx, 1);
        } else {
          this.downloadItem = null;
        }
      }
    }
  }


  updateButtonList(): Observable<any> {
    return Observable.create(observer => {
      this.resizeListen = this.getAuthedButtonList(this.programId).then(lstBtn => {
        // modify by quwj--调整按钮隐藏功能
        const result = this.btnData.map(item => {
          const index = lstBtn.findIndex(btn => btn.id === item.id);
          item.btn_len = (item.name ? item.name.length : 2) * 12 + 34;
          return index < 0
            ? item
            : Object.assign({}, item, {
              authed: true,
              icon: lstBtn[index].icon,
              name: lstBtn[index].name,
              btn_len: (lstBtn[index].name ? lstBtn[index].name.length : 2) * 12 + 34
            });
        });
        var data = result.filter(item => item.authed);
        var isHasImport = data.filter(item => item.id == 'btnImport' || item.id == 'btnCusImport' || item.id == 'btnExport' || item.id == 'btnSelfExport' || item.id == 'btnTDownload').length > 0;
        if (isHasImport) {
          // 获取模板
          if (this.FProgramId != this.programId || !this.FTemplateData) { //作业编号改变或返回模板为空
            this.http.post('A/IFxai014Service/getTemplateList', { param: { program_no: this.programId } }, { headers: { 'program-code': this.programId } }).subscribe(tempResponse => {
              if (tempResponse.success && tempResponse.data && tempResponse.data.length) {
                this.FProgramId = this.programId;
                this.FTemplateData = tempResponse.data;
                data.forEach(btnItem => {
                  var templateArr = tempResponse.data.filter(tmp => {
                    return tmp.abx004 == btnItem.id && tmp.abx002 == this.programId;
                  });
                  if (templateArr.length) {// 如果有模板则为导入按钮
                    btnItem.importTemplateList = templateArr;
                    btnItem.isImportBtn = true;
                  } else {
                    btnItem.importTemplateList = [];
                  }
                });
                observer.next(data);
                observer.complete();
              } else {
                observer.next(data);
                observer.complete();
              }
            });
          } else {
            this.FProgramId = this.programId;
            data.forEach(btnItem => {
              var templateArr = this.FTemplateData.filter(tmp => {
                return tmp.abx004 == btnItem.id && tmp.abx002 == this.programId;
              });
              if (templateArr.length) {// 如果有模板则为导入按钮
                btnItem.importTemplateList = templateArr;
                btnItem.isImportBtn = true;
              } else {
                btnItem.importTemplateList = [];
              }
            });
            observer.next(data);
            observer.complete();
          }
        } else {
          observer.next(data);
          observer.complete();
        }

        // observer.next(result.filter(item => item.authed));
        // observer.complete();
      });
    });
  }

  async getAuthedButtonList(programId: string): Promise<any> {
    try {
      if (this.FProgramId != this.programId || (!this.FShowbtnData && this.programId)) { //作业编号改变或返回模板为空
        const response = await this.http.post('A/IACommonService/showButtonDisplay', { param: { program_no: programId } }, { headers: { 'program-code': programId } }).toPromise();
        if (response.success && response.data) {
          this.FProgramId = this.programId;
          this.FShowbtnData = response.data;
          return response.data.map(item => {
            return item.icon
              ? Object.assign({}, item, { icon: 'anticon ' + item.icon })
              : item;
          });
        } else {
          return [];
        }
      } else {
        this.FProgramId = this.programId;
        return this.FShowbtnData.map(item => {
          return item.icon
            ? Object.assign({}, item, { icon: 'anticon ' + item.icon })
            : item;
        });
      }
    } catch (e) {
      return [];
    }
  }

  //宽度适应
  // reizesWidth() {
  //   let sum = 0;
  //   let allW = this.btnW.nativeElement.clientWidth;
  //   let btnChild = this.btnW.nativeElement.children;
  //   console.log(allW,btnChild)

  //   let btnsWidth = [];
  //   for (let i = 0; i < btnChild.length; i++) {
  //     let w = btnChild[i].clientWidth + 6;
  //     sum += w;
  //     btnsWidth.push(w);
  //   }
  //   sum += 50;
  //   if (sum > allW && allW != 0) {
  //     for (let index = this.btnData.length - 1; index >= 0; index--) {
  //       //console.log(index, this.btnData[index],'index')
  //       this.btnMore.splice(0, 0, this.btnData[index]);
  //       this.btnData.splice(index, 1);
  //       sum -= btnsWidth[index];
  //       if (sum < allW) {
  //         break;
  //       }
  //     }
  //     setTimeout(() => {
  //       this.showMoreBtn = true;
  //     }, 0);
  //   }

  //   //console.log(this.btnData)
  //   //console.log(this.btnMore)
  // }

  //对应的按钮操作
  clickFixedBtn(name: any, item?: any): void {
    this.visible = false;
    if (name === 'btnExport') {
      this.btnExportListShow = true; // 显示导出列表
    } else {
      this.onButton.emit(name);
    }
  }

  public checkActionDataAurhority(action: string, data: any, table?: string, programId?: string): Observable<boolean> {
    const postParam = {
      action_list: [action],
      primaryKey_value_map: data,
      program_no: programId ? programId : this.programId,
      table_no: table || '',
    };
    return Observable.create(observer => {
      this.http.post('A/IACommonService/chkActionDataAuthority', { param: postParam }).subscribe(
        response => {
          // if (response.success && response.data && response.data.find(item => item === action)) {
          observer.next(response.success && response.data && response.data.find(item => item === action));

          // } else
        },
        error => {
          observer.next(false);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  // 导入完成重新查询服务
  reloadData(response): void {
    this.onReload.emit(response);
  }

}
