import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2, Injector, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { SearchMoreComponent } from 'app/implementation/shared/search-condition/search-more.component';
import { TranslateService } from '@ngx-translate/core';
import { DwRoutingMessageService, DwNewRouteKeyService } from '@webdpt/framework';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { GridOptions, GridApi, ColumnApi, RowNode, RowNodeTransaction } from 'ag-grid-community';
import { FX_AG_GRID_FW_COMPONENTS } from '../../../../shared/fx-ag-grid-editors-module';
import { DwModalService } from 'ng-quicksilver';
import { FxMasterDetailBaseComponent } from '../../../../shared/pattern/masterdetail/fx-masterdetailbase.component';
import { ActivatedRoute } from '@angular/router';
import { FxAgGridEventHandlerService, IAgGridEditorEvent } from '../../../../shared/ag-grid/fx-ag-grid-event-handler.service';
// import { Fxdd002MasterService, Fxdd002MasterModel } from '../fxdd002.service';
import { FxGridOperationButtonRendererComponent } from 'app/implementation/shared/ag-grid/fx-grid-operation-button-renderer/fx-grid-operation-button-renderer.component';
import { FxGridOperationButtonEditorComponent } from 'app/implementation/shared/ag-grid/fx-grid-operation-button-editor/fx-grid-operation-button-editor.component';
import { Observable, ConnectableObservable } from 'rxjs';
import { DwNumberPipe } from 'app/implementation/shared/components/pipe/dw-number.pipe';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { FxMperCellRendererComponent } from 'app/implementation/shared/ag-grid/fx-mper-cell-renderer/fx-mper-cell-renderer.component';
@Component({
  selector: 'app-updateBPrice',
  templateUrl: './updateBPrice.component.html',
  styleUrls: ['./updateBPrice.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    FxAgGridEventHandlerService, // 注：建立新的实例，才不会造成作业间事件的错误
    // Fxdd002MasterService,  // 单头服务
    DwNumberPipe
  ]
})
export class updateBPriceComponent implements OnInit {
  @Input() type: string; // 类型-作业编号
  @Input() channel: string; // 渠道类型-渠道价格专用
  @Input() fxed006Data: any; // 资料-渠道订单专用
  @Input() fxdd002Data: any; // 资料-渠道价格专用
  @Input() refreshData: any; // 更新价格之后调用作业刷新服务
  public programId = 'fxdd002'; // 作业编号  
  public searchLoading = true; // 是否显示查询载入中
  public optionsdal001$: Observable<any>; // 属性类型
  public typeList: Array<any> = []; // 调价基准
  public methodList: Observable<any>; // 调价方式
  public eaadtypeList: Observable<any>; // 单据类型

  public masterGridOptions: GridOptions; // 列表ag-grid
  public masterColumnDefs = []; // 列表列定义
  public masterRowCount; // 列表总笔数
  public masterSelectedCount = 0; // 列表已选择笔数
  public masterList = [];
  private masterGridApi: GridApi; // 列表GridApi
  private masterGridColumnApi: ColumnApi; // 列表GridColumnApi 
  public gridMaxHeight: number = 300;

  public validateForm: FormGroup;   // 校验FormGroup
  public frameworkComponents = {   // Ag-grid 组件
    ...FX_AG_GRID_FW_COMPONENTS
  };


  // 构造函数
  constructor(
    // private injector: Injector,
    protected http: FxDapHttpClient,
    public dwModalService: DwModalService,
    private fb: FormBuilder,
    protected router: DwNewRouteKeyService,
    protected route: ActivatedRoute,
    public dwMessage: DwRoutingMessageService,
    private translateService: TranslateService,
    public fxModalService: FxModalService,
    private dwNumberPipe: DwNumberPipe,
    protected agGridEventHandler: FxAgGridEventHandlerService, // 注入事件服务
    // public MasterService: Fxdd002MasterService,
    private fxUserService: FxUserInfoService
  ) {
    // super(injector); // 调用FxMasterDetailBaseComponent基类构造传入注入

  }

  // OnInit
  ngOnInit(): void {
    this.pageInit();  // 页面初始化
    this.masterGridInit();
  }

  // 过滤条件多语系
  localTextFunc = (key: string): any => {
    // grid多语系
    return this.translateService.instant(key);
  }

  // 初始化页面
  pageInit(): void {
    this.optionsdal001$ = Observable.create(observer => {
      this.http.post<any>('A/IACommonService/pub_get_aaa', { param: { 'is_enum': 'Y', 'aaa001': '112' } }).subscribe(response => {
        if (response.success && response.data.length > 0) {
          const resultdata = response.data.filter(x => {
            return x.aaa002 != '0';
          }).map(item => {
            return {
              label: item.aaa002_n1,
              value: item.aaa002
            };
          });
          observer.next(resultdata);
        } else {
          observer.next([]);
        }
        observer.complete();
      }, error => {
        observer.next([]);
        observer.complete();
      });
    }); // 属性类型

    // 调价基准
    this.typeList = [
      { label: this.translateService.instant('comm-按订单价格'), value: 'fxed006' },
      { label: this.translateService.instant('comm-按渠道价格'), value: 'fxdd002' },
    ];

    // 调价方式
    this.methodList = this.fxUserService.getSysEnumById$('X01');
    // 单据类型
    this.eaadtypeList = this.fxUserService.getSysEnumById$('106');

    // 初始化FormGroup
    this.initFormGroup();



    // 设定 masterGridOptions  的context 方便做父子Component及主从Ag-Grid通信
    this.masterGridOptions = <GridOptions>{
      context: {
        componentParent: this,
        id: 'masterGrid'          // 给ag-Grid指定ID
      },
      rowHeight: 28, //行高
      columnTypes: {
        'masterTextColumn': {
          editable: false,
          cellEditor: 'fxAgTextCellEditor',
          cellEditorParams: {
            form: this.validateForm.get('masterValidateForm'),
            validators: [Validators.required]
          }
        }
      }
    };
    // this.searchData();
  }

  async setGridMaxSize(): Promise<any> {
    var gridMaxHeight = 300;
    const programParam = await this.fxUserService.getProgramParamById$('fxdd002').toPromise();
    const maxRowCount = (programParam && programParam.has('c_P002'))
      ? programParam.get('c_P002')
      : this.fxUserService.getEntParamById('c_E004');
    gridMaxHeight = 14 + 30 * 10;

    if (this.masterGridApi === undefined) {
      return;
    }
    const rownodes: RowNode[] = this.masterGridApi.getEditingRowNode(true);
    // let getGrid = document.getElementById('masterGrid');
    if (rownodes.length > 10) {
      this.masterGridApi.setDomLayout("normal");
      this.gridMaxHeight = gridMaxHeight;
    } else {
      this.masterGridApi.setDomLayout("autoHeight");
    }
  }

  // 初始化FormGroup
  initFormGroup() {
    this.validateForm = this.fb.group({
      // 渠道价格专属字段-start
      'dal001': new FormControl('2'),         // 属性类型
      'dal002': new FormControl(''),         // 属性编号
      'dal002_n1': new FormControl(''),      // 属性名称
      'dakdocno': new FormControl(this.fxdd002Data ? this.fxdd002Data.dakdocno : null),       // 申请单号
      'dakddate': new FormControl(this.fxdd002Data ? this.fxdd002Data.dakddate : null),       // 申请日期
      'dakbdate': new FormControl(this.fxdd002Data ? this.fxdd002Data.dakbdate : null),       // 生效日期
      'dakchnl': new FormControl(this.fxdd002Data ? this.fxdd002Data.dakchnl : null),       // 渠道
      'dakcorg': new FormControl(this.fxdd002Data ? this.fxdd002Data.dakcorg : null),       // 制定组织
      'daklock': new FormControl(this.fxdd002Data ? this.fxdd002Data.daklock : null),       // 乐观锁
      // 渠道价格专属字段-end
      // 渠道订单专属字段-start
      'eaadtype': new FormControl('2'),       // 单据类型-默认2.渠道订单
      'eaadocno': new FormControl(this.fxed006Data ? this.fxed006Data.eaadocno : null),       // 订单单号
      'eaatobj': new FormControl(this.fxed006Data ? this.fxed006Data.eaatobj : null),       // 渠道商编号
      'eaatobj_n1': new FormControl(this.fxed006Data ? this.fxed006Data.eaatobj_n1 : null),    // 渠道商名称
      'eaalock': new FormControl(this.fxed006Data ? this.fxed006Data.eaalock : null),    // 乐观锁
      // 渠道订单专属字段-end
      'type': new FormControl(this.type),    // 调价基准
      'caggoods': new FormControl(''),       // 商品编号
      'caggoods_n1': new FormControl(''),    // 商品名称
      'method': new FormControl('2'),         // 调价方式
      'cagclass': new FormControl(''),       // 品类编号
      'cagclass_n1': new FormControl(''),    // 品类名称
      'amount': new FormControl(0),         // 调价比例/金额
      'cag008': new FormControl(''),         // 系列
      'cag008_n1': new FormControl(''),      // 系列名称
      'masterValidateForm': new FormGroup({}), // 单身grid
    });
    this.validateForm.get('eaadocno').disable();
  }

  // 修改金额
  changeamount(event) {
    var oldPriceField, newPriceField; // 记录要调整的字段名称
    if (this.validateForm.get('type').value == 'fxdd002') {
      oldPriceField = 'price';
      newPriceField = 'price_new';
    } else if (this.validateForm.get('type').value == 'fxed006') {
      oldPriceField = 'price';
      newPriceField = 'price_new';
    }
    const amount = this.validateForm.get('amount').value ? Number(this.validateForm.get('amount').value) : 0;
    this.masterGridApi.forEachNode(item => {
      if (this.validateForm.get('method').value == '1') {// 按比例调价-新供价=原供价*调整比例/100
        item.data[newPriceField] = this.dwNumberPipe.transform((Number(item.data[oldPriceField]) * Number(amount)) / 100, 'priceDecimal', 'default', false);
      } else if (this.validateForm.get('method').value == '2') {// 上调金额-原供价+调整金额
        item.data[newPriceField] = this.dwNumberPipe.transform((Number(item.data[oldPriceField]) + amount), 'priceDecimal', 'default', false);
      } else if (this.validateForm.get('method').value == '3') {// 下调金额-原供价-下调金额
        item.data[newPriceField] = this.dwNumberPipe.transform((Number(item.data[oldPriceField]) - amount), 'priceDecimal', 'default', false);
      }
    });
    this.masterGridApi.refreshCells({ force: true }); // 更新值之后刷新栏位
  }

  // 将开窗的数组组合成字符串
  setArrToString(data, id, setId) {
    if (data && data.length) {
      var dal002 = '';
      data.forEach((element, index) => {
        dal002 += element[id] ? element[id] : '';
        if (index < (data.length - 1)) {
          dal002 += ',';
        }
      });
      this.validateForm.get(setId).setValue(dal002);
    } else {
      this.validateForm.get(setId).setValue(null);
    }
  }

  // 属性编号开窗
  public opendal002(xEvent): void {
    // 获取当前行的所有数据
    var switch_id = this.validateForm.get('dal001').value || '', openModelParam;

    if (switch_id == '1') {
      openModelParam = {
        modalID: 'qry_dahstore_1',   // (必传) 开窗的编号
        postParam: [{
          dahlorg: this.fxUserService.userInfo['c_org'],
          daachnl: this.channel
        }],                        // (必传) 查询参数，注意是数组
        selected: [],              // (必传) 查询参数，注意是数组,
        mutilSelected: true,
        searchType: 'a'
      };
    } else if (switch_id == '2') {
      openModelParam = {
        modalID: 'qry_daecust_1',   // (必传) 开窗的编号
        postParam: [{
          daflorg: this.fxUserService.userInfo['c_org'],
          daachnl: this.channel
        }],                        // (必传) 查询参数，注意是数组
        selected: [],              // (必传) 查询参数，注意是数组,
        mutilSelected: true,
        searchType: 'a'
      };
    } else if (switch_id == '3') {
      openModelParam = {
        modalID: 'qry_dac001_1',   // (必传) 开窗的编号
        postParam: [{
          daclorg: this.fxUserService.userInfo['c_org'],
          daachnl: this.channel
        }],                        // (必传) 查询参数，注意是数组
        selected: [],              // (必传) 查询参数，注意是数组,
        mutilSelected: true,
        searchType: 'a'
      };
    } else if (switch_id == '4') {
      openModelParam = {
        modalID: 'qry_daachnl_2',   // (必传) 开窗的编号
        postParam: [{
          daa004: this.channel
        }],                        // (必传) 查询参数，注意是数组
        selected: [],              // (必传) 查询参数，注意是数组,
        mutilSelected: true,
        searchType: 'a'
      };
    } else if (!switch_id) {
      this.dwMessage.warning(this.translateService.instant('comm-请选择属性类型'))
      return;
    }
    this.fxModalService.searchFx(openModelParam).subscribe((data) => {
      if (switch_id == '1') {
        this.setArrToString(data, 'dahstore', 'dal002');
      } else if (switch_id == '2') {
        this.setArrToString(data, 'daecust', 'dal002');
      } else if (switch_id == '3') {
        this.setArrToString(data, 'dac001', 'dal002');
      } else if (switch_id == '4') {
        this.setArrToString(data, 'daachnl', 'dal002');
      }
    });
  }

  // 商品开窗
  public opencaggoods() {
    var openModelParam = {
      modalID: 'qry_caigoods',     // (必传) 开窗的编号
      postParam: [{
        cailorg: this.fxUserService.userInfo['c_org'],
        // caggtype: theRowData[xEvent.rowIndex].data.dalgtype
        // caichnl: '01'
      }],             // (必传) 查询参数，注意是数组
      selected: [],              // (必传) 查询参数，注意是数组,
      searchType: 'a',
      mutilSelected: true
    };
    this.fxModalService.searchFx(openModelParam).subscribe((data) => {
      this.setArrToString(data, 'caggoods', 'caggoods');
    });
  }

  // 品类开窗
  public opencagclass() {
    var openModelParam = {
      modalID: 'qry_caaclass_1',     // (必传) 开窗的编号
      postParam: [{
      }],             // (必传) 查询参数，注意是数组
      selected: [],              // (必传) 查询参数，注意是数组,
      searchType: 'a',
      mutilSelected: true
    };
    this.fxModalService.searchFx(openModelParam).subscribe((data) => {
      this.setArrToString(data, 'caaclass', 'cagclass');
    });
  }

  // 系列开窗
  public opencag008() {
    var openModelParam = {
      modalID: 'qry_caf001_2',     // (必传) 开窗的编号
      postParam: [{
        caflorg: this.fxUserService.userInfo['c_org']
      }],             // (必传) 查询参数，注意是数组
      selected: [{
      }],              // (必传) 查询参数，注意是数组,
      searchType: 'a',
      mutilSelected: true
    };
    this.fxModalService.searchFx(openModelParam).subscribe((data) => {
      this.setArrToString(data, 'caf001', 'cag008');
    });
  }

  // 列表初始化
  public masterGridInit(): void {
    this.masterColumnDefs = [
      {
        headerName: '',
        width: 40,
        pinned: 'left',
        headerCheckboxSelection: true, //标题添加checkbox
        headerCheckboxSelectionFilteredOnly: true, //全选只勾选过滤部分
        checkboxSelection: true
      },
      {// 商品编号
        headerName: this.translateService.instant('comm-商品编号'),
        width: 120,
        field: 'caggoods',
        editable: false
      },
      {// 商品名称
        headerName: this.translateService.instant('comm-商品名称'),
        width: 150,
        field: 'caggoods_n1',
        editable: false
      },
      // {// 销售型号
      //   headerName: this.translateService.instant('caggoods_n2'),
      //   width: 120,
      //   field: 'caggoods_n2',
      //   editable: false
      // },
      // {// 特征名称
      //   headerName: this.translateService.instant('eabgchr_n1'),
      //   width: 120,
      //   field: 'eabgchr_n1',
      //   editable: false
      // },
      {// 规格
        headerName: this.translateService.instant('comm-规格'),
        width: 120,
        field: 'cag001_n1',
        editable: false
      },
      {// 品类
        headerName: this.translateService.instant('comm-品类'),
        width: 100,
        field: 'cagclass_n1',
        editable: false
      },
      {// 单位
        headerName: this.translateService.instant('comm-单位'),
        width: 100,
        field: 'unit_n1',
        editable: false
      },
      // 渠道价格作业字段-start
      {// 原供价
        headerName: this.translateService.instant('dalsprice_old'),
        width: 120,
        field: 'price',
        hide: this.type === 'fxed006' ? true : false,
        editable: false,
        cellRenderer: 'fxAgNumberFormatRendererNew',
        cellRendererParams: {
          dwType: 'priceDecimal',
          dwCode: 'default'
        },
      },
      {// 新供价
        headerName: this.translateService.instant('dalsprice_new'),
        width: 120,
        field: 'price_new',
        hide: this.type === 'fxed006' ? true : false,
        editable: true,
        cellRenderer: 'fxAgNumberFormatRendererNew',
        cellRendererParams: {
          dwType: 'priceDecimal',
          dwCode: 'default'
        },
        cellEditor: 'fxAgNumberFormatEditor',
        cellEditorParams: {
          validators: [Validators.required],
          dwType: 'priceDecimal',
          dwCode: 'default',
          dwMin: 0
        }
      },
      {// 属性类型
        headerName: this.translateService.instant('dal001'),
        width: 120,
        field: 'dal001',
        hide: this.type === 'fxed006' ? true : false,
        editable: false,
        cellRenderer: 'fxGridSelectRendererNew',
        cellRendererParams: {
          values: this.fxUserService.getSysEnumById$('112')
        },
      },
      {// 属性编号
        headerName: this.translateService.instant('dal002'),
        width: 120,
        field: 'dal002',
        hide: this.type === 'fxed006' ? true : false,
        editable: false
      },
      // 渠道价格作业字段-end
      // 渠道订单作业字段-start
      {// 原单价
        headerName: this.translateService.instant('eabprice_old'),
        width: 120,
        field: 'price',
        hide: this.type === 'fxdd002' ? true : false,
        editable: false,
        cellRenderer: 'fxAgNumberFormatRendererNew',
        cellRendererParams: {
          dwType: 'priceDecimal',
          dwCode: 'default'
        },
      },
      {// 新单价
        headerName: this.translateService.instant('eabprice_new'),
        width: 120,
        field: 'price_new',
        hide: this.type === 'fxdd002' ? true : false,
        editable: true,
        cellRenderer: 'fxAgNumberFormatRendererNew',
        cellRendererParams: {
          dwType: 'priceDecimal',
          dwCode: 'default'
        },
        cellEditor: 'fxAgNumberFormatEditor',
        cellEditorParams: {
          validators: [Validators.required],
          dwType: 'priceDecimal',
          dwCode: 'default',
          dwMin: 0
        }
      },
      // {// 折扣率
      //   headerName: this.translateService.instant('eab073'),
      //   width: 120,
      //   field: 'eab073',
      //   hide: this.type === 'fxdd002' ? true : false,
      //   editable: false,
      //   cellRenderer: 'fxAgNumberFormatRendererNew',
      //   cellRendererParams: {
      //     dwType: 'unit',
      //     dwCode: 'default'
      //   },
      // },
      // {// 下单数量
      //   headerName: this.translateService.instant('eabqty'),
      //   width: 120,
      //   field: 'eabqty',
      //   hide: this.type === 'fxdd002' ? true : false,
      //   editable: false,
      //   cellRenderer: 'fxAgNumberFormatRendererNew',
      //   cellRendererParams: {
      //     dwType: 'unit',
      //     dwCode: 'default'
      //   },
      // },
      // {// 含税金额
      //   headerName: this.translateService.instant('eabtamt'),
      //   width: 120,
      //   field: 'eabtamt',
      //   hide: this.type === 'fxdd002' ? true : false,
      //   editable: false,
      //   cellRenderer: 'fxAgNumberFormatRendererNew',
      //   cellRendererParams: {
      //     dwType: 'priceDecimal',
      //     dwCode: 'default'
      //   },
      // },
      // {// 未税金额
      //   headerName: this.translateService.instant('eabnamt'),
      //   width: 120,
      //   field: 'eabnamt',
      //   hide: this.type === 'fxdd002' ? true : false,
      //   editable: false,
      //   cellRenderer: 'fxAgNumberFormatRendererNew',
      //   cellRendererParams: {
      //     dwType: 'priceDecimal',
      //     dwCode: 'default'
      //   },
      // },
      // 渠道订单作业字段-end
    ];

    this.agGridEventHandler.changeField.subscribe((event: IAgGridEditorEvent) => {
      switch (event.colId) {
        case 'price_new':// 新供价 // 新单价
          this.agGridEventHandler.setValue('masterGrid', 'dalsprice', event.value, '', event.id);
          this.masterGridApi.getModel().getRowNode(event.id).setDataValue('dalsprice', event.value);
          break;
      }
    });


  }



  // 单头gridReady事件，处理表格显示控制
  onMasterGridReady(params: any): void {
    this.masterGridApi = params.api;
    this.masterGridColumnApi = params.columnApi;
    this.setGridMaxSize();
  }

  // 清除
  clearData() {
    // this.initFormGroup();
    this.validateForm.patchValue({
      'type': this.type,    // 调价基准
      'caggoods': '',       // 商品编号
      'caggoods_n1': '',    // 商品名称
      'method': '2',         // 调价方式
      'cagclass': '',       // 品类编号
      'cagclass_n1': '',    // 品类名称
      'amount': 0,         // 调价比例/金额
      'cag008': '',         // 系列
      'cag008_n1': '',      // 系列名称
    })
    if (this.type === 'fxdd002') {
      this.validateForm.patchValue({
        'dal002': ''
      });
    }
  }

  /**
   * 查詢資料
   * @param {boolean} [reset=false] 是否重新指定當前頁碼為第一頁
   */
  public searchData(): void {
    var param = this.validateForm.getRawValue();
    param.dal002 = param.dal002 ? param.dal002.split(',') : [];
    param.caggoods = param.caggoods ? param.caggoods.split(',') : [];
    param.cagclass = param.cagclass ? param.cagclass.split(',') : [];
    param.cag008 = param.cag008 ? param.cag008.split(',') : [];
    // 查询单头数据
    this.http.post('D/IDCommonService/getPriceList', { param: param }).subscribe(response => {
      if (response.success) {
        if (response.data && response.data.length) {
          this.masterList = response.data;
          const amount = this.validateForm.get('amount').value ? Number(this.validateForm.get('amount').value) : 0;
          this.masterList.map((item, index) => {
            item.id = index + 1;
            if (this.validateForm.get('method').value == '1') {// 按比例调价-新供价=原供价*调整比例/100
              item.price_new = this.dwNumberPipe.transform((Number(item.price) * Number(amount)) / 100, 'priceDecimal', 'default', false);
            } else if (this.validateForm.get('method').value == '2') {// 上调金额-原供价+调整金额
              item.price_new = this.dwNumberPipe.transform((Number(item.price) + amount), 'priceDecimal', 'default', false);
            } else if (this.validateForm.get('method').value == '3') {// 下调金额-原供价-下调金额
              item.price_new = this.dwNumberPipe.transform((Number(item.price) - amount), 'priceDecimal', 'default', false);
            }
          });
          setTimeout(() => {
            this.setGridMaxSize();
          }, 200);
        } else {
          this.dwMessage.warning(this.translateService.instant('comm-暂无数据！'));
        }
      } else {
        this.masterList = []
        this.dwMessage.warning(response.message);
      }
    }, error => {
      this.masterList = []
    });

    // 清空列表选择的行( 可能onMasterGridReady 未执行完成)
    if (this.masterGridApi !== undefined) {
      this.masterGridApi.deselectAll();
    }
  }

  // 执行更新服务
  confirmUpdate() {
    this.masterGridApi.stopEditing();
    const selected = this.masterGridApi.getSelectedNodes();
    if (!selected.length) {
      this.dwMessage.warning(this.translateService.instant('comm-请选择需要操作的资料'));
      return;
    }
    var selList = [], errorList = [];
    selected.forEach(sel => {
      if (Number(sel.data.price_new) <= 0) {
        errorList.push(sel.data)
      } else {
        selList.push(sel.data);
      }
    });
    if (errorList.length) {
      this.dwMessage.warning('新供价不可为小于等于0！');
      return;
    }
    var param = this.validateForm.getRawValue();
    param.dal002 = param.dal002 ? param.dal002.split(',') : [];
    param.caggoods = param.caggoods ? param.caggoods.split(',') : [];
    param.cagclass = param.cagclass ? param.cagclass.split(',') : [];
    param.cag008 = param.cag008 ? param.cag008.split(',') : [];
    param.price_list = selList;
    if (this.type === 'fxed006') {// 渠道订单
      this.http.post('D/IDCommonService/adjustPriceBatch', { param: param }).subscribe(response => {
        if (response.success) {
          this.dwMessage.success(this.translateService.instant('com-更新成功！'));
          this.dwModalService.closeAll();
          this.refreshData();
        } else {
          this.dwMessage.warning(response.message);
        }
      });
    } else if (this.type === 'fxdd002') {// 渠道价格
      this.dwModalService.closeAll();
      this.refreshData(selList);
    }
  }

  // 主表选择变更事件
  onMaserSelectionChanged(event: any): void {

  }


  // 设定RowNodeID
  getRowNodeId(data: any): number {
    return data.id;
  }


  onMasterRowClicked(event: any): void {

  }

}

